import React, { createContext, useReducer, useContext } from "react"
import Storage from "../library/Storage"
import { datasInitialState, datasActions } from "./datas"

const localStorage = new Storage('vyrall')

const initialState = {
    ...datasInitialState
}

const Actions = {
    ...datasActions
}

const reducer = (state, action, ls = false) => {
    const act = Actions[action.type]
    let datas = act ? { ...state, ...act(action, state) } : state
    if(ls) {
        localStorage.setData(datas)
    }

    return datas
}

const StoreContext = createContext(initialState)

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const thunkDispatch = action => {
        if (typeof action === "function") {
            return action(dispatch)
        }
        return dispatch(action)
    }

    return (
        <StoreContext.Provider value={{ state, dispatch: thunkDispatch }}>
            {children}
        </StoreContext.Provider>
    )
}

export const useStore = () => {
    return useContext(StoreContext)
}