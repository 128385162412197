const visit = require('unist-util-visit')

const helpMsg = `ping: expected configuration to be passed: {
    pingUsername: (username) => bool,\n  userURL: (username) => string\n}`

module.exports = function ping ({
    pingEntity,
    entityURL,
    transform = null,
    entityRegex = /(@)(?:\*\*([^*]+)\*\*|(\w+))/,
    mapping = {
        type: 1,
        label: 2
    }
}) {

    if (typeof pingEntity !== 'function' || typeof entityURL !== 'function') {
        throw new Error(helpMsg)
    }

    function inlineTokenizer (eat, value, silent) {
        const keep = entityRegex.exec(value)
        if (!keep || keep.index > 0) return

        const total = keep[0]
        const entity = keep[mapping.label]

        if (pingEntity(entity) === true) {
            const url = entityURL(entity, keep[mapping.type])
            const label = typeof transform === 'function' ? transform(entity) : entity

            return eat(total)(
                {
                    type: 'ping',
                    entity: label,
                    url: url,
                    data: {
                        hName: 'a',
                        hProperties: {
                            href: url,
                            rel: 'nofollow',
                            className: 'ping ping-link',
                            target: "_blank",
                        },
                    },
                    children: [
                        {
                            type: 'text',
                            value: keep[mapping.type],
                        },
                        {
                            type: 'emphasis',
                            data: {
                                hName: 'span',
                                hProperties: {
                                    className: 'ping-entity',
                                },
                            },
                            children: [
                                {
                                    type: 'text',
                                    value: label,
                                }
                            ],
                        }
                    ],
                }
            )
        }
        else {
            return eat(total[0])({
                type: 'text',
                value: total[0],
            })
        }
    }

    function locator (value, fromIndex) {
        const keep = entityRegex.exec(value, fromIndex)
        if (keep) {
            return value.indexOf('@', keep.index)
        }
        return -1
    }

    inlineTokenizer.locator = locator

    const Parser = this.Parser

    // Inject inlineTokenizer
    const inlineTokenizers = Parser.prototype.inlineTokenizers
    const inlineMethods = Parser.prototype.inlineMethods
    inlineTokenizers.ping = inlineTokenizer
    inlineMethods.splice(inlineMethods.indexOf('text'), 0, 'ping')

    const Compiler = this.Compiler

    // Stringify
    if (Compiler) {
        const visitors = Compiler.prototype.visitors
        visitors.ping = (node) => {
            if (!node.entity.includes(' ')) {
                return `@${node.entity}`
            }
            return `@**${node.entity}**`
        }
    }

    return (tree, file) => {

        // mark pings in blockquotes, later on we'll need that info to avoid pinging from quotes
        visit(tree, 'blockquote', markInBlockquotes)

        // remove ping links from pings already in links
        visit(tree, 'link', (node) => {
            visit(node, 'ping', (ping, index) => {
                ping.data.hName = 'span'
                ping.data.hProperties = {class: 'ping ping-in-link'}
            })
        })

        visit(tree, 'ping', (node) => {
            if (!node.__inBlockquote) {
                if (!file.data[node.type]) {
                    file.data[node.type] = []
                }
                // collect usernames to ping, they will be made available on the vfile
                // for some backend to act on
                file.data[node.type].push(node.entity)
            }
        })
    }
}

function markInBlockquotes (node) {
    mark(node)

    if (node.children) {
        node.children.map((n, i) => markInBlockquotes(n))
    }
}

function mark (node) {
    if (node.type === 'ping') node.__inBlockquote = true
}
