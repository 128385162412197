import React, { useCallback, useState } from 'react'
import './invitecode.scss'

import Toast from './Uxtoast'
import { shortUuid, isFunction } from '../../library/Core'

export default function Invitecode({
    value: __value,
    prefix: __prefix,
    refresh: __refresh,
    url: __url = null
}) {

    const [value, setValue] = useState(__value || shortUuid(17))
    const [prefix] = useState(__prefix || '')

    const copy = useCallback(() => {
        const code = (__url ? __url : '') + prefix + value
        navigator.clipboard.writeText(code).then(() => {
            Toast.add('Code copié', 3000, 'warn')
        }, () => {
            
        });
    }, [value])

    const refresh = useCallback(() => {
        const code = shortUuid(17)
        setValue(code)
        if(isFunction(__refresh)) {
            __refresh(code)
        }
    })

    return (
        <div className="ux-invite-code">
            <div className="ux-invite-code-value">{ prefix + value }</div>
            <div className="ux-invite-code-copy" onClick={ copy }>
                <svg viewBox="-61 0 512 512">
                    <path d="m180 210v-120h-180v422h300v-302zm30-111.300781v81.300781h81.300781zm0 0"/>
                    <path d="m381.300781 90h-81.300781v-81.300781zm0 0"/>
                    <path d="m90 0h180v116.398438l-56.398438-56.398438h-123.601562zm0 0"/>
                    <path d="m390 120v302h-60v-245.601562l-56.398438-56.398438zm0 0"/>
                </svg>
            </div>
            {
                __refresh && isFunction(__refresh) ?
                <div className="ux-invite-code-refresh" onClick={ refresh }>
                    <svg viewBox="0 0 512 512">
                        <path d="M463.702,162.655L442.491,14.164c-1.744-12.174-16.707-17.233-25.459-8.481l-30.894,30.894
                            C346.411,12.612,301.309,0,254.932,0C115.464,0,3.491,109.16,0.005,248.511c-0.19,7.617,5.347,14.15,12.876,15.234l59.941,8.569
                            c8.936,1.304,17.249-5.712,17.125-15.058C88.704,165.286,162.986,90,254.932,90c22.265,0,44.267,4.526,64.6,13.183l-29.78,29.78
                            c-8.697,8.697-3.761,23.706,8.481,25.459l148.491,21.211C456.508,181.108,465.105,172.599,463.702,162.655z"/>
                        <path d="M499.117,249.412l-59.897-8.555c-7.738-0.98-17.124,5.651-17.124,16.143c0,90.981-74.019,165-165,165
                            c-22.148,0-44.048-4.482-64.306-13.052l28.828-28.828c8.697-8.697,3.761-23.706-8.481-25.459L64.646,333.435
                            c-9.753-1.393-18.39,6.971-16.978,16.978l21.21,148.492c1.746,12.187,16.696,17.212,25.459,8.481l31.641-31.626
                            C165.514,499.505,210.587,512,257.096,512c138.794,0,250.752-108.618,254.897-247.28
                            C512.213,257.088,506.676,250.496,499.117,249.412z"/>
                    </svg>

                </div>
                : ''
            }
        </div>
    )

}