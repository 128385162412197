import React, { useRef, forwardRef, useImperativeHandle }  from 'react'
import './Uxvideo.scss'

const Uxvideo = forwardRef((props, ref) => {

    const videoRef = useRef()

    let attrs = {}
    if(props.autoplay) {
        attrs.autoplay = true
    }

    if(props.control) {
        attrs.control = true
    }

    if(props.loop) {
        attrs.loop = true
    }

    if(props.muted) {
        attrs.muted = true
    }

    if(props.preload) {
        attrs.preload = props.preload
    }

    if(props.poster) {
        attrs.poster = props.poster
    }

    let subtitles = ''
    if(props.subtitle) {
        props.subtitle.forEach(sub => {
            subtitles += <track kind="subtitles" src={ sub.src } srclang={ sub.srclang } label={ sub.label } />
        })
    }

    function _play() {
        videoRef.current.play()
    }

    function _pause() {
        videoRef.current.pause()
    }

    function _stop() {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
    }

    useImperativeHandle(ref, () => ({

        play() {
            _play()
        },
        pause() {
            _pause()
        },
        stop() {
            _stop()
        }

    }))

    return (
        <div className="ux-video">
            <video width={ props.width } height={ props.height } { ...attrs } ref={ videoRef }>
                { props.mp4 ? <source src={ props.mp4 } type="video/mp4" /> : '' }
                { props.ogg ? <source src={ props.ogg } type="video/ogg" /> : '' }
                { props.webm ? <source src={ props.webm } type="video/webm" /> : '' }
                { subtitles }
                <p>Your browser does not support the video tag.</p>
            </video>
        </div>
    )

})

export default Uxvideo

export const UxStream = (props) => {

    let twitch = props.url ? props.url.replace(/(https:\/\/)?(www\.)?twitch.tv\/(.+)/g, '$3') : null

    let children = null
    if(props.type === "twitch") {
        children = <UxTwitch value={ props.value } />
    }
    else if(twitch) {
        children = <UxTwitch value={ twitch } />
    }

    return (<>{children}</>)
}

export const UxYoutube = (props) => {

    let add = ''
    if(props.autoplay) {
        add += '&autoplay=1'
    }

    add = add ? '?' + add.substr(1) : ''

    let url = ''
    if(props.type === "live" && props.channel) {
        add += '&channel=' + props.channel
        url = "https://www.youtube.com/embed/live_stream/"
    }
    else {
        url = "https://www.youtube.com/embed/" + props.value
    }

    return (
        <div className="ux-video-responsive">
            <iframe title={ "Youtube " + url } width="560" height="315" src={ url  + add } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    )
}

export const UxTwitch = (props) => {

    let add = ''
    if(props.autoplay) {
        add += '&autoplay=true'
    }

    if(props.muted) {
        add += '&muted=true'
    }

    return (
        <div className="ux-video-responsive">
            <iframe title={ "Twitch " + props.value } width="1280" height="720" src={ "https://player.twitch.tv/?" + (props.type || 'channel') + "=" + props.value + "&parent=" + window.location.hostname + add } frameBorder="0" allowFullScreen scrolling="no"></iframe>
        </div>
    )
}

export const UxMixer = (props) => {

    let add = ''

    return (
        <div className="ux-video-responsive">
            <iframe title={ "Mixer " + props.value } width="1280" height="720" src={ "https://mixer.com/embed/player/" + props.value + "?disableLowLatency=1" + add } frameBorder="0" allowFullScreen scrolling="no"></iframe>
        </div>
    )
}

export const UxFacebook = (props) => {

    let add = ''

    return (
        <div className="ux-video-responsive">
            <iframe title={ "Mixer " + props.value } width="1280" height="720" src={ "https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/facebook/videos/" + props.value + "/&show_text=false&width=1280" + add } frameBorder="0" allowFullScreen scrolling="no" allow="encrypted-media"></iframe>
        </div>
    )
}