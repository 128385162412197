import React  from 'react'
import './Uxtable.scss'

export function Uxtablerow(props) {

    return (
        <div className="ux-table-row" { ...props }>
            { props.children }
        </div>
    )
}

export function Uxtableheader(props) {
    return (
        <div className={ "ux-table-header" }>
            { props.children }
        </div>
    )

}