import React from 'react'

import { numberFormat } from '../../library/Core';
import { useStore } from "../../store/useStore"

export default function Vicoin({

}) {

    const { state, dispatch } = useStore()

    return (
        <svg viewBox="0 0 250 50" className="el-coin">
            <rect className="el-coin-box" rx="5" ry="5" x="4" y="4" width="242" height="42" />
            <text className="el-coin-value" textAnchor="end" y="34" x="170">{ numberFormat(state.coins, 0, ',', ' ') }</text>
            <g className="el-coin-money">
                <path d="M655,69H767C619.018,358.276,596.4,404.3,409,769,93.632,150.356,74.294,111.813,53,70H164c34.266,66.784,34,66.415,72,141h28c90.971,177.488,145.7,283.845,146,284,65.291-128.729,57.572-114.263,145-285h28C629.843,119.611,586.724,203.207,655,69Z"/>
                <path d="M170,60h28q36,70.493,72,141H242M620,60h28c-46.8,89.168-32.6,57.7-73,141H548C585.6,127.267,584,130.467,620,60Z"/>
                <path d="M322,214H498c-50.4,99.067-18.933,36.7-88,173C376.2,318.455,347.068,262.51,322,214Z"/>
            </g>
            <g>
                <rect className="el-coin-add" rx="5" ry="5" x="208" y="4" width="42" height="42" />
                <text className="noselect cursor" fontSize="36" y="35" x="217">+</text>
            </g>
        </svg>
    )
}

