import React from 'react'
import sha256 from 'crypto-js/sha256'
import sendQuery from './socket'
import { isFunction } from '../library/Core'

export const fetchMySelf = () => {

    return sendQuery([
        {
            ressource: "user.myself",
            entry: "user",
            query: {}
        }
    ])
}

export const fetchGame = (gameId) => {

    return sendQuery([
        {
            ressource: "game.detail",
            entry: "game",
            query: {
                gameId: gameId
            },
            fields: {
                "name": "name",
                "icon": "icon",
                "ladder":"ladder",
            }
        },
        {
            ressource: "ladders.list",
            entry: "results",
            query: {
                game: "@game.id"
            },
            fields: {
                "id": "id",
                "name": "name",
                "type": "type",
                "game": "game"
            }
        }
    ])
}

export const fetchPlayer = (name, discriminator) => {

    return sendQuery([
        {
            ressource: "user.detail",
            entry: "user",
            query: {
                username: name,
                discriminator: discriminator
            },
            fields: {
                "nickname": "name",
                "icon": "icon",
                "setting.banner": "banner",
                "setting.avatar": "avatar",
                "social": "social",
                "video": "video",
                "streaming": "streaming",
                "game": "game",
                "achievements": "achievements"
            }
        }
    ]).then(datas => datas.user)
}

export const fetchGameList = (opts = {} ) => {

    const filters = {
        enable: true,
        ...opts.query,
    }

    if(opts.enable === false) {
        delete filters.enable
    }

    const fields = {
        "id": "id",
        "name": "name",
        "icon": "icon",
        "platform": "platform",
        "type": "type",
        "gameId": "gameId",
        "ladder": "ladder",
        ...opts.fields
    }

    const query =  {
        ressource: "game.list",
        entry: "games",
        query: filters,
        fields: fields,
        limit: opts.limit || 100,
        offset: opts.offset || 0
    }

    return sendQuery([
        query
    ]).then(response => response.games)
}

export const forgetPassword = (login) => {

    return sendQuery([
        {
            ressource: "user.forgot",
            entry: "user",
            query: {
                login: login
            }
        }
    ])
}

export const doLogin = (login, password) => {

    return sendQuery([
        {
            ressource: "user.login",
            entry: "token",
            query: {
                login: login,
                password: sha256(password).toString()
            }
        }
    ])
}

export const doLogout = () => {

    return sendQuery([
        {
            ressource: "user.logout",
            query: {}
        }
    ])
}

export const addAdvertisement = (datas) => {
    return sendQuery([
        {
            ressource: "advertisement.add",
            query: {
                owner: {
                    contact: datas.owner,
                    model: datas.model
                },
                content: datas.description,
                criteria: datas.criteria
            }
        }
    ])
}

export const fetchAdvertisements = (datas) => {
    return sendQuery([
        {
            ressource: "advertisement.list",
            entry: "advertisement",
            query: {},
            fields: {
                "id": "id",
                "content": "content",
                "criteria": "criteria",
                "owner.permalink": "permalink",
                "owner.name": "name",
                "owner.icon": "icon",
                "owner.type": "type",
                "dates.created": "date"
            }
        }
    ]).then(datas => datas.advertisement)
}

// todo : security must be improve server side
export const updateAccount = (id, datas) => {
    return sendQuery([
        {
            ressource: "user.update",
            entry: "detail",
            query: {
                id: id
            },
            fields: {
                "account.firstname": datas.firstname,
                "account.lastname": datas.lastname,
                "account.birthdate": datas.birthdate,
                "account.phone": datas.phone,
                "account.address": datas.address,
                "account.zipcode": datas.zipcode,
                "account.city": datas.city,
                "account.country": datas.country
            }
        }
    ])
}

// todo : security must be improve server side
export const fetchAccount = (id) => {
    return sendQuery([
        {
            ressource: "user.detail",
            entry: "detail",
            query: {
                id: id
            },
            fields: {
                "id": "id",
                "account.firstname": "firstname",
                "account.lastname": "lastname",
                "account.birthdate": "birthdate",
                "account.phone": "phone",
                "account.address": "address",
                "account.zipcode": "zipcode",
                "account.city": "city",
                "account.country": "country"
            }
        }
    ])
}

export const fetchUserGames = (id) => {
    return sendQuery([
        {
            ressource: "user.detail",
            entry: "user",
            query: {
                id: id
            },
            populate: ["games"],
            fields: {
                games: "games"
            }
        }
    ]).then(d => d.user.games )
}

export const updateUser = (id, fields) => {
    return sendQuery([
        {
            ressource: "user.update",
            entry: "detail",
            query: {
                id: id
            },
            fields: fields
        }
    ])
}

export const fetchUser = (id, fields) => {
    return sendQuery([
        {
            ressource: "user.detail",
            entry: "detail",
            query: {
                id: id
            },
            fields: fields
        }
    ]).then(datas => datas.detail)
}