export const ROLES = {
    frontline: "première ligne",
    hardbreach: "brèche lourde",
    crowscontrol: "contrôle des foules",
    intelgatherer: "renseignement",
    secure: "sécurisation",
    roam: "patrouille",
    softbreach: "brèche",
    inteldenier: "contre-renseignements",
    anchor: "ancre",
    backline: "arrière-garde",
    coveringfire: "tir de suppression",
    disable: "neutralisation",
    flank: "contournement",
    areadenial: "blocage d'accès",
    antiroam: "anti-patrouille",
    trap: "piège",
    antihardbreach: "anti-brèche lourde",
    shield: "bouclier",
    buff: "buff"
}

export const HEROS = {
    ace: { role: ["frontline", "hardbreach"], name: "ace" },
    alibi: { role: ["intelgatherer", "roam", "inteldenier", "trap"], name: "alibi" },
    amaru: { role: ["frontline", "flank"], name: "amaru" },
    ash: { role: ["frontline", "softbreach", "disable", "flank"], name: "ash" },
    bandit: { role: ["secure", "roam", "antihardbreach"], name: "bandit" },
    blackbeard: { role: ["backline", "coveringfire", "shield"], name: "blackbeard" },
    blitz: { role: ["frontline", "crowscontrol", "antiroam", "shield"], name: "blitz" },
    buck: { role: ["softbreach", "flank"], name: "buck" },
    caveira: { role: ["intelgatherer", "roam", "inteldenier"], name: "caveira" },
    capitao: { role: ["frontline", "flank", "areadenial"], name: "capitão" },
    castle: { role: ["secure", "anchor"], name: "castle" },
    clash: { role: ["crowscontrol", "intelgatherer", "secure", "shield"], name: "clash" },
    doc: { role: ["anchor", "buff"], name: "doc" },
    dokkaebi: { role: ["intelgatherer", "inteldenier", "flank", "antiroam"], name: "dokkaebi" },
    echo: { role: ["crowscontrol", "intelgatherer", "secure", "anchor"], name: "echo" },
    ela: { role: ["crowscontrol", "roam", "trap"], name: "ela" },
    finka: { role: ["backline", "buff"], name: "finka" },
    frost: { role: ["crowscontrol", "trap"], name: "frost" },
    fuze: { role: ["disable", "flank", "areadenial"], name: "fuze" },
    glaz: { role: ["softbreach", "backline", "coveringfire"], name: "glaz" },
    goyo: { role: ["secure", "areadenial"], name: "goyo" },
    gridlock: { role: ["crowscontrol", "areadenial", "antiroam"], name: "gridlock" },
    hibana: { role: ["frontline", "hardbreach", "backline"], name: "hibana" },
    iana: { role: ["intelgatherer", "inteldenier"], name: "iana" },
    iq: { role: ["frontline", "intelgatherer", "disable", "flank"], name: "iq" },
    jackal: { role: ["intelgatherer", "antiroam"], name: "jackal" },
    jager: { role: ["secure", "roam"], name: "jäger" },
    kaid: { role: ["secure", "anchor", "antihardbreach"], name: "kaid" },
    kali: { role: ["backline", "coveringfire", "disable"], name: "Kali" },
    kapkan: { role: ["trap"], name: "kapkan" },
    lesion: { role: ["crowscontrol", "intelgatherer", "roam", "anchor", "trap"], name: "lesion" },
    lion: { role: ["crowscontrol", "intelgatherer", "backline", "antiroam"], name: "lion" },
    maestro: { role: ["intelgatherer", "secure", "anchor", "areadenial"], name: "maestro" },
    maverick: { role: ["hardbreach", "backline", "disable", "flank"], name: "maverick" },
    melusi: { role: ["crowscontrol", "intelgatherer", "secure"], name: "melusi" },
    mira: { role: ["intelgatherer", "secure", "anchor"], name: "mira" },
    montagne: { role: ["shield"], name: "montagne" },
    mozzie: { role: ["intelgatherer", "secure", "inteldenier"], name: "mozzie" },
    mute: { role: ["secure", "inteldenier", "antihardbreach"], name: "mute" },
    nokk: { role: ["inteldenier", "flank"], name: "nokk" },
    nomad: { role: ["crowscontrol", "antiroam", "trap"], name: "nomad" },
    oryx: { role: ["roam", "softbreach"], name: "oryx" },
    pulse: { role: ["intelgatherer", "roam"], name: "pulse" },
    rook: { role: ["anchor", "buff"], name: "rook" },
    sledge: { role: ["softbreach", "flank"], name: "sledge" },
    smoke: { role: ["secure", "anchor", "areadenial"], name: "smoke" },
    tachanka: { role: ["anchor", "coveringfire", "shield"], name: "tachanka" },
    thatcher: { role: ["backline", "disable"], name: "thatcher" },
    thermite: { role: ["hardbreach", "backline"], name: "thermite" },
    twitch: { role: ["frontline", "intelgatherer", "backline", "disable"], name: "twitch" },
    valkyrie: { role: ["intelgatherer", "roam"], name: "valkyrie" },
    vigil: { role: ["roam", "inteldenier"], name: "vigil" },
    wamai: { role: ["secure", "anchor"], name: "wamai" },
    warden: { role: ["inteldenier", "anchor"], name: "warden" },
    ying: { role: ["frontline", "crowscontrol"], name: "ying" },
    zofia: { role: ["crowscontrol", "softbreach", "disable", "flank", "antiroam"], name: "zofia" },
}