import React, { useState, useCallback } from 'react'

import { Row, Column } from '../../components/ux/grid'
import { useStore } from "../../store/useStore"
import AvatarChosen from '../../components/ux/player/avatarChosen'
import BannerChosen from '../../components/ux/player/bannerChosen'
import Toast from "../../components/ux/Uxtoast"
import sendQuery from "../../socket/socket"
import { getUrl, toDate } from '../../library/Core'

export default function AdmiterPreferencePage(props) {

    const { state, dispatch } = useStore()

    const urls = getUrl()
    let _avatar = './assets/default-avatar.png'
    if(state.setting.avatar) {
        _avatar = urls.avatar + state.setting.avatar + '.png'
    }

    let _banner = './assets/default-banner.jpg'
    if(state.setting.banner) {
        _banner = urls.banner + state.setting.banner + '.jpg'
    }

    const [theme, setTheme] = useState(state.setting.theme)
    const [avatar, setAvatar] = useState(null)
    const [banner, setBanner] = useState(null)
    const [modified, setModified] = useState(false)

    const onSubmit = useCallback(() => {

        let fields = {
            "setting.theme": theme,
        }

        if(avatar) {
            fields["setting.avatar"] = avatar
        }

        if(banner) {
            fields["setting.banner"] = banner
        }

        sendQuery([
            {
                ressource: "user.update",
                entry: "user",
                query: {
                    id: state.id
                },
                fields: fields
            }
        ], datas => {
            Toast.add('Informations mise à jour', 3000, 'success')

            dispatch({
                type: "setTheme",
                payload: datas.user.setting.theme
            })

            if(datas.user.setting.avatar) {
                dispatch({
                    type: "setAvatar",
                    payload: datas.user.setting.avatar
                })
            }

            if(datas.user.setting.banner) {
                dispatch({
                    type: "setBanner",
                    payload: datas.user.setting.banner
                })
            }

            setModified(false)
        }, error => {
            Toast.add('Erreur', 3000, 'error')
        })
    }, [theme, avatar, banner])

    const toggleTheme = useCallback(value => {
        setTheme(value)
        if(value !== state.setting.theme) {
            setModified(true)
        }
    }, [])

    const changeAvatar = useCallback(value => {
        setAvatar(value)

        if(value !== state.setting.avatar) {
            setModified(true)
        }
    }, [])

    const changeBanner = useCallback(value => {
        setBanner(value)

        if(value !== state.setting.banner) {
            setModified(true)
        }
    }, [])

    return (
        <div className="admiter-preference-page">
            <Row className="-margin">
                <div className="admiter-submitter">
                    { modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null }
                </div>
                <Column>
                    <h2>Préférences</h2>
                    <hr className="space10" />
                </Column>
            </Row>
            <Row>
                <Column medium="12" large="5" xlarge="4" xxlarge="3">
                    <h3 className="spacer10">Avatar pour { state.nickname }</h3>
                    <AvatarChosen className="margin-auto" src={ _avatar } onChange={ changeAvatar } />

                    <div className="info-box">
                        <ul>
                            <li>A rejoint le { toDate(state.dates.created)}</li>
                            <li>A { state.games.length } jeux</li>
                            <li>A 20 amis</li>
                        </ul>
                    </div>
                </Column>
                <Column medium="12" large="7" xlarge="8" xxlarge="9">
                    <h3 className="spacer10">Choix de votre bannière</h3>
                    <BannerChosen src={ _banner } onChange={ changeBanner } />

                    <h3>Choix du thème</h3>
                    <div className="theming-list">
                        <div className={"theme1" + (theme === 'theme1' ? ' selected' : '')} onClick={ e => { toggleTheme('theme1') } }></div>
                        <div className={"theme2" + (theme === 'theme2' ? ' selected' : '')} onClick={ e => { toggleTheme('theme2') } }></div>
                        <div className={"theme3" + (theme === 'theme3' ? ' selected' : '')} onClick={ e => { toggleTheme('theme3') } }></div>
                        <div className={"theme4" + (theme === 'theme4' ? ' selected' : '')} onClick={ e => { toggleTheme('theme4') } }></div>
                        <div className={"theme5" + (theme === 'theme5' ? ' selected' : '')} onClick={ e => { toggleTheme('theme5') } }></div>
                        <div className={"theme6" + (theme === 'theme6' ? ' selected' : '')} onClick={ e => { toggleTheme('theme6') } }></div>
                    </div>
                </Column>
            </Row>
        </div>
    )
}