import React, { useState, useCallback, useMemo } from 'react'
import { markdown } from '../../library/Core'
import { useStore } from "../../store/useStore"
import sendQuery from "../../socket/socket"

import './advertisementPage.scss'

import { Row, Column } from '../../components/ux/grid'
import Input from '../../components/fields/Input'
import useForm from '../../components/fields/form'
import MercatoFilters from '../../project/mercatofilters'
import { addAdvertisement } from '../../socket/actions'

import Toast from "../../components/ux/Uxtoast"

export default function AdmiterAdvertisementPage(props) {

    const [menu, setMenu] = useState('add')
    const { state } = useStore()

    let schema = {
        description: {
            validator: [
                {
                    required: true,
                    message: "Votre annonce ne peut pas être vide",
                },
                {
                    min: 30,
                    message: "Votre annonce est trop courte",
                }
            ]
        },
        criteria: {
            validator: [
                {
                    test: (value) => {
                        return !value.staff && !value.game
                    },
                    message: "Vos critères de description ne sont pas assez nombreux"
                }
            ],
        },
        model: {},
        owner: {}
    }

    const { state:formState, binding, modified, updateInitial, onSubmit } = useForm(
        schema,
        datas => {

            //Todo : implement owner user / structure / team
            if(!datas.owner || !datas.model) {
                datas.owner = state.id
                datas.model = "user"
            }

            addAdvertisement(datas)
            .then(success => {
                console.log(success)
            })
            .catch(error => {
                console.log(error)
            })
        },
        errors => {
            console.log(errors)
        }
    )

    return (
        <div className="admiter-advertisement-page">
            <Row className="-margin">
                <div className="admiter-submitter">
                    { modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null }
                </div>
                <Column>
                    <h2>Annonces</h2>
                    <hr className="space10" />
                </Column>
                <Column>
                    <div className="admiter-sub-menu">
                        <ul>
                            <li className={ menu === "add" ? "selected" : "" } onClick={ (e) => setMenu("add") }>Mettre une annonce</li>
                            <li className={ menu === "my" ? "selected" : "" } onClick={ (e) => setMenu("my") }>Mes annonces</li>
                            <li className={ menu === "alert" ? "selected" : "" } onClick={ (e) => setMenu("alert") }>Mes alertes</li>
                        </ul>
                    </div>
                </Column>
                {
                    menu === 'add' &&
                    <>
                        <Column>
                            <label>
                                Description
                                <div className="admiter-mercato-filters">
                                    <MercatoFilters name="criteria" value={ formState.criteria.value } { ...binding } />
                                </div>
                            </label>
                        </Column>
                        {
                            formState.criteria.value.what &&
                            <>
                            <Column large="6">
                                {
                                    formState.criteria.value.what === 'player' &&
                                    <label>
                                        Pour quelle équipe ?
                                    </label>
                                }
                                {
                                    formState.criteria.value.what === 'staff' &&
                                    <label>
                                        Pour quelle structure ?
                                    </label>
                                }
                                <label>
                                    Rédaction de l'annonce
                                    <Input className="advertisement-textarea" name="description" value={ formState.description.value } type="textarea" { ...binding } isModified={ formState.description.isModified } />
                                </label>
                            </Column>
                            <Column large="6">
                                <div className="advertisement-html">
                                    { markdown(formState.description.value) }
                                </div>
                            </Column>
                            </>
                        }
                    </>
                }
            </Row>
        </div>
    )
}