import React, { useState, useCallback, useEffect } from 'react'

import "./gamePage.scss"

import { Row, Column } from '../../components/ux/grid'
import Gamecard from '../../components/ux/game/gamecard'
import Checkbox from '../../components/ux/checkbox'
import { useStore } from "../../store/useStore"
import sendQuery from "../../socket/socket"
import Icon from "../../project/icon"
import { fetchUserGames, fetchGameList } from "../../socket/actions"
import Uxmodal from '../../components/ux/Uxmodal'
import Toast from '../../components/ux/Uxtoast'
import { inArray, toggleValueInArray } from '../../library/Core'

const GetGames = (props) => {

    const { state, dispatch } = useStore()
    const [gameList, setGameList] = useState([])
    const [selected, setSelected] = useState([])
    const [name, setName] = useState('')

    useEffect(() => {
        fetchGameList({
            limit: 100,
        })
        .then(games => {
            setGameList(games)
        })

        setSelected(state.games)
    }, [])

    const toggle = useCallback(id => {
        const sel = toggleValueInArray(id, selected)
        setSelected(sel)
    }, [selected])

    const updateGames = useCallback(() => {
        props.close([ ...selected ])
    }, [selected])

    const filterName = (e) => {
        const value = e.target.value
        setName(value)
    }

    return (
        <div className="admiter-game-page-chooser">
            <div className="admiter-game-page-chooser-input">
                <input value={ name } type="text" onChange={ filterName } onInput={ filterName } placeholder="Nom du jeu" />
            </div>
            <div className="admiter-game-page-chooser-list">
                {
                gameList
                    .filter(game => {
                        return game.name.match(new RegExp(name, 'gi')) !== null
                    })
                    .map(game => {
                    return <div className="admiter-game-page-chooser-game">
                        <Checkbox checked={ inArray(game.id, selected) } onChange={ () => { toggle(game.id) } }>
                            <Gamecard selected={ inArray(game.id, selected) } datas={ game } type="tiny" />
                        </Checkbox>
                    </div>
                })
            }
            </div>
            <button onClick={ updateGames }>Ajouter</button>
        </div>
    )
}

export default function AdmiterGamePage(props) {

    const { state, dispatch } = useStore()

    let socketUri = "https://ws.vyrall.net"
    if(process.env.NODE_ENV === "development") {
        socketUri = "http://localhost:1331"
    }

    const [menu, setMenu] = useState('mygames')
    const [gameList, setGameList] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    const linker = useCallback((url, name) => {
        window.open(
            socketUri + url,
            name,
            "resizable,scrollbars,status"
        )
    }, [socketUri])

    useEffect(() => {

        fetchUserGames()
        .then(games => {
            setGameList(games)
        })

        const callback = (event) => {

            if(typeof event.data === 'string') {
                const info = JSON.parse(event.data)

                sendQuery([
                    {
                        ressource: "provider.link",
                        entry: "user",
                        query: {
                            id: state.id
                        },
                        fields: {
                            id: info.id,
                            provider: info.provider
                        }
                    }
                ], datas => {
                    setGameList(datas.user.games)
                    Toast.add('Voter liste de jeux a été mise à jour', 3000, 'success')
                })
            }
        }

        window.addEventListener("message", callback, false)

        return () => {
            window.removeEventListener("message", callback)
        }

    }, [])

    const onClose = useCallback((data) => {
        console.log(data)
        setIsOpen(false)
    })

    return (
        <div className="admiter-game-page">
            <Row className="-margin">
                <div className="admiter-submitter">
                    { /*modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null*/ }
                </div>
                <Column>
                    <h2>Jeux</h2>
                    <hr className="space10" />
                </Column>
                <Column>
                    <div className="admiter-sub-menu">
                        <ul>
                            <li className={ menu === "mygames" ? "selected" : "" } onClick={ (e) => setMenu("mygames") }>Mes jeux</li>
                            <li className={ menu === "account" ? "selected" : "" } onClick={ (e) => setMenu("account") }>Mes comptes</li>
                        </ul>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column className="cursor" large="3">
                    <div className="admiter-bordered clickable" onClick={ () => linker('/auth/steam', "steam")}>
                        <div className="admiter-bordered-header text-left no-margin"><Icon gameId="provider" icon="steam" /> Importer mes jeux</div>
                    </div>
                </Column>
                <Column className="cursor" large="3">
                    <div className="admiter-bordered clickable" onClick={ () => { setIsOpen(true) } }>
                        <div className="admiter-bordered-header text-center no-margin">Ajouter un jeu</div>
                    </div>
                </Column>
            </Row>
            <div className="game-list-wrapper">
                <Row>
                {
                    gameList.map(game => {
                        return <Column key={ "listing-" + game._id } small="12" medium="6" large="3" xxlarge="2">
                            <Gamecard datas={ game } />
                        </Column>
                    })
                }
                </Row>
            </div>
            { isOpen && <Uxmodal header="Choisissez vos jeux" render={ GetGames } isOpen={ isOpen } onClose={ onClose } /> }
        </div>
    )
}