import React, { useCallback } from 'react'
import { Switch, Route } from 'react-router'

import './assets/App.scss'

import Layout from './Layout'
import Admiter, { AdmiterItem } from './components/ux/admiter'
import Uxtoast from './components/ux/Uxtoast'
import { StoreProvider, useStore } from './store/useStore'
import { SocketProvider, initSocket } from './socket/socket'
import SocketPush from './socket/socketPush'

import HomePage from './pages/homePage'
import MercatoPage from './pages/mercatoPage'
import GamePage from './pages/gamePage'
import GameListPage from './pages/gameListPage'
import PlayerPage from './pages/playerPage'
import TeamPage from './pages/teamPage'
import InvitePage from './pages/invitePage'
import EventPage from './pages/eventPage'

import AdmiterAccountPage from './pages/admiter/accountPage'
import AdmiterStructurePage from './pages/admiter/structurePage'
import AdmiterWalletPage from './pages/admiter/walletPage'
import AdmiterSocialPage from './pages/admiter/socialPage'
import AdmiterGamePage from './pages/admiter/gamePage'
import AdmiterDevPage from './pages/admiter/devPage'
import AdmiterPreferencePage from './pages/admiter/preferencePage'
import AdmiterAchievementPage from './pages/admiter/achievementPage'
import AdmiterAdvertisementPage from './pages/admiter/advertisementPage'
import AdmiterAdminPage from './pages/admiter/adminPage'

let socketUri = "https://ws.vyrall.net"
if(process.env.NODE_ENV === "development") {
  socketUri = "http://localhost:1331"
}

initSocket(socketUri)

function App() {

  const ref = React.createRef('uxtoast')

  const admiterFilter = useCallback(datas => {
    return datas.group === 'admin'
  })

  return (
      <StoreProvider>
        <SocketProvider>
          <Uxtoast ref={ ref } />
          <SocketPush />
          <Layout>
            <Admiter store={ useStore }>
              <AdmiterItem component={ AdmiterAccountPage }>Compte</AdmiterItem>
              <AdmiterItem component={ AdmiterPreferencePage }>Préférence</AdmiterItem>
              <AdmiterItem component={ AdmiterSocialPage }>Social</AdmiterItem>
              <AdmiterItem component={ AdmiterAchievementPage }>Hauts faits</AdmiterItem>
              <AdmiterItem component={ AdmiterGamePage }>Jeux</AdmiterItem>
              <AdmiterItem component={ AdmiterAdvertisementPage }>Annonces</AdmiterItem>
              <AdmiterItem component={ AdmiterStructurePage }>Organisation équipe</AdmiterItem>
              <AdmiterItem component={ AdmiterWalletPage }>Portefeuille</AdmiterItem>
              <AdmiterItem component={ AdmiterDevPage }>Développeur</AdmiterItem>
              <AdmiterItem component={ AdmiterAdminPage } filter={ admiterFilter }>Administration</AdmiterItem>
            </Admiter>
            <Switch>
              <Route exact path="/" component={ HomePage } />
              <Route path="/mercato" component={ MercatoPage } />
              <Route path="/settings" component={ MercatoPage } />
              <Route path="/game/:gameId/:section" component={ GamePage } />
              <Route path="/game/:gameId" component={ GamePage } />
              <Route path="/game" component={ GameListPage } />
              <Route path="/team/:teamId" component={ TeamPage } />
              <Route path="/player/:playerId" component={ PlayerPage } />
              <Route path="/player" component={ PlayerPage } />
              <Route path="/invite/:id" component={ InvitePage } />
              <Route path="/event" component={ EventPage } />
            </Switch>
          </Layout>
        </SocketProvider>
      </StoreProvider>
  );
}

export default App;

// https://www.behance.net/gallery/53803315/LCV-E-Sports-Web-Platform-Design
// https://www.behance.net/gallery/41480313/League-of-Legends-Analytics-Dashboard
// https://cdn.dribbble.com/users/1381088/screenshots/5608968/streams_preview.png