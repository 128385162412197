import React, { createContext, useContext } from 'react'
import io from "socket.io-client"
import Storage from "../library/Storage"
import Toast from '../components/ux/Uxtoast'
import { isFunction } from '../library/Core'
const localStorage = new Storage('vyrall')

let _socket = null
export const initSocket = (url = "http://localhost:4545", options = {}) => {

    const datas = localStorage.getData()
    let  token = null
    if(datas && datas.token) {
        token = datas.token
    }
    options = { ...options, query: {...options.query, token: token } }

    _socket = io(url, options)
    return _socket
}

const SocketContext = createContext(null)

export const SocketProvider = ({ children }) => {
    return (
        <SocketContext.Provider value={ _socket }>
            { children }
        </SocketContext.Provider>
    )
}

export const useSocket = () => {
    return useContext(SocketContext)
}

const errors = {
    0: "Erreur inconnu",
    1: "Mot de passe invalide",
    2: "Ce compte existe déjà",
    3: "Le joueur n'existe pas"
}

export const updateAccessToken = accessToken => {
    _socket.close()
    _socket.io.opts.query.token = accessToken
    _socket.connect()
}

export const sendQuery = (query, fn, err) => {

    return new Promise((resolve, reject) => {
        if(typeof query === 'object') {
            console.log("QUERY", query)

            _socket.emit('query', query, data => {
                if(data.error) {
                    console.log("ERROR", data)
                    Toast.add(errors[data.error.code], 5000, 'error')
                    if(isFunction(err)) {
                        err(data.error)
                    }

                    reject(data.error)
                }
                else if(isFunction(fn)) {
                    console.log("RESPONSE", data)
                    fn(data)
                }
                else {
                    console.log("RESPONSE", data)
                    resolve(data)
                }
            })
        }
        else {
            reject({error: true, message: "query is not a object"})
        }
    })
}

export default sendQuery