import React, { useState, useCallback } from 'react'

import "./plusmoins.scss"

import Checkbox from '../../components/ux/checkbox'

export default function Plusmoins({
    value: __value,
    onChange: __onChange
}) {

    const [checked, setChecked] = useState(__value || false)

    const toggle = useCallback((value) => {
        setChecked(value)
        __onChange(value)
    }, [__onChange])

    return (
        <div className="ux-plusmoins">
            <Checkbox checked={ checked } onChange={ toggle }>
                <svg viewBox="0 0 195 195">
                    <path d="M25,65.46H0V0h65.46v25H25V65.46z M170,170h-40.46v25H195v-65.46h-25V170z M129.54,25H170v40.46h25V0h-65.46V25z M25,129.54
                        H0V195h65.46v-25H25V129.54z"/>
                        <rect width="90" height="25" x="55" y="86" />
                        { checked ? '' : <rect width="25" height="90" x="86" y="55" /> }
                </svg>
            </Checkbox>
        </div>
    )
}