import React, { useState, useCallback, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { isFunction, getUrl } from '../../library/Core'
import './game.ladderPage.scss'

import { useStore } from "../../store/useStore"

import { Row, Column } from '../../components/ux/grid'
import UxMatch from '../../components/ux/match/UxMatch'
import Favorite from '../../project/ux/favorite'
import Plusmoins from '../../project/ux/plusmoins'
import { fetchGame } from '../../socket/actions'

import { Uxtablerow, Uxtableheader } from '../../components/ux/Uxtable'


function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min +1)) + min;
}

function LadderLine({
    name: __name,
    season: __season,
    team: __team,
    gameId: __gameId,
    open: __open,
    onOpen: __onOpen,
    id: __id
}) {

    const { state } = useStore()
    const urls = getUrl()

    let results = []

    for(let x = 0; x <= __team ; x++) {

        let nb = getRandomIntInclusive(100, 120)
        let ratio = getRandomIntInclusive(10, 12)
        results.push({
            "name": "Tribe aMaze",
            "permalink": "amaze-356",
            "rating": getRandomIntInclusive(1000, 1200),
            "games": nb + ratio,
            "wins": nb,
            "loses": nb - ratio,
            "level": getRandomIntInclusive(23, 24),
            "id": x + 1,
        })
    }

    const [open, setOpen] = useState(__open)
    const isFav = state.setting.ladderList[__gameId] && state.setting.ladderList[__gameId].enable ? state.setting.ladderList[__gameId].enable : false

    const fullList = useCallback((value) => {
        setOpen(value)

        if(isFunction(__onOpen)) {
            __onOpen(value)
        }
    }, [])

    const filterList = useCallback(() => {
        return results.slice(0, open ? __team : 5)
    }, [open, results])

    const makeFav =  useCallback(() => {
        /*
        dispatch({
            type: "setFavLadder",
            payload: {
                gameId: __gameId,
                value: value,
                name: game.name
            }
        })
        */
    }, [isFav])

    return (
        <>
        <div className="ladder-wrapper">
            <div>
                <div className="ladder-info box">
                    <div className="ladder-info-image">
                        <img src={ urls.ladder + __gameId + '/' + __id + '.jpg' } alt="" />
                    </div>
                    <div className="ladder-info-content">
                        <div className="ladder-info-icon">
                            <img src={ urls.icon + __gameId + ".png" } alt="" />
                        </div>
                        <div className="ladder-info-title-wrapper">
                            <div className="ladder-info-title">{ __name }</div>
                            <div className="ladder-info-season">
                                <div className="ladder-info-season-date">Du 01/05/2020 au 31/05/2020</div>
                                <div className="ladder-info-season-number"><span className="stamp">Saison { __season }</span></div>
                            </div>
                            <div className="ladder-info-datas">
                                <div className="ladder-info-datas-team">{ __team } équipes</div>
                                <div className="ladder-info-datas-more">
                                    <Favorite checked={ isFav } onChange={ makeFav } />
                                    <Plusmoins checked={ open } onChange={ fullList } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ladder-list box">
                <Uxtableheader>
                    <div className="table-rank">
                        Rang
                    </div>
                    <div className="table-team">
                        Equipe
                    </div>
                    <div className="table-rating">
                        Points
                    </div>
                    <div className="table-games">
                        Parties
                    </div>
                    <div className="table-wins">
                        Victoire
                    </div>
                    <div className="table-loses">
                        Défaite
                    </div>
                    <div className="table-level">
                        Niveau
                    </div>
                </Uxtableheader>
                {

                    filterList().map((r, i) => {
                        return (
                        <Uxtablerow key={ r.id }>
                            <div className="table-rank">
                                { r.rating > 0 ? i + 1 : '-' }
                            </div>
                            <div className="table-team">
                                <img src="/assets/teams/tribe.png" alt="Tribe Amaze"/>
                                <NavLink to={ "/team/" + r.permalink }>
                                    { r.name }
                                </NavLink>
                            </div>
                            <div className="table-rating">
                                { r.rating }
                            </div>
                            <div className="table-games">
                                { r.games }
                            </div>
                            <div className="table-wins">
                                { r.wins }
                            </div>
                            <div className="table-loses">
                                { r.loses }
                            </div>
                            <div className="table-level">
                                { r.level }
                            </div>
                        </Uxtablerow>
                        )
                    })
                }
            </div>
        </div>
        </>
    )
}

export default function GameLadderPage({
    gameId: __gameId
}) {

    const { gameId, section } = useParams()
    const { state, dispatch } = useStore()

    const isFav = state.setting.gameList[__gameId] && state.setting.gameList[__gameId].enable ? state.setting.gameList[__gameId].enable : false

    const [game, setGame] = useState({})
    const [ladders, setLadders] = useState([])

    useEffect(() => {
        fetchGame(gameId)
        .then(datas => {
            setGame(datas.game)
            setLadders(datas.results)
        })
        .catch(err => { console.log(err) })
    }, [gameId])

    const [ladderOpen, setLadderOpen] = useState([false,false,false])

    const onOpen = (id, value) => {
        let tmp = [false,false,false]
        tmp[id] = value
        setLadderOpen(tmp)
    }

    const ladderByGame = {
        overwatch: [
            { season: 3, name: "Ladder 6VS6", id: "6vs6", team: 23 },
            { season: 1, name: "Ladder 3VS3", id: "3vs3", team: 14 },
            { season: 1, name: "Ladder lucio surf", id: "luciosurf", team: 5 },
        ],
        apex: [
            { season: 1, name: "Ladder 6VS6", id: "6vs6", team: 23 },
            { season: 1, name: "Ladder 3VS3", id: "3vs3", team: 14 },
        ],
        leagueoflegends: [
            { season: 1, name: "Ladder 6VS6", id: "6vs6", team: 23 },
            { season: 1, name: "Ladder 3VS3", id: "3vs3", team: 14 },
        ]
    }

    return (
        <div className="background-stellar">
            <Row>
                {
                /*
                <Column large="4">
                    <div className="box">
                        <header>Ladder 6VS6</header>
                        {
                            results.length > 0 ?
                            results.filter(r => {
                                if(!gameId) return true
                                return gameId === r.gameId
                            }).map(r => {
                                return <UxMatch
                                    key={ r.id }
                                    team1={ r.team1 }
                                    team2={ r.team2 }
                                    result={ r.result }
                                    icon1={ r.icon1 }
                                    icon2={ r.icon2 }
                                />
                            })
                            : "Chargement ..."
                        }
                    </div>
                </Column>
                */
                }
                {
                    ladderByGame[gameId].map((l, i) => {
                        return (
                        <Column key={i}>
                            <LadderLine season={ l.season } name={ l.name } id={ l.id } team={ l.team } gameId={ gameId } open={ ladderOpen[i] } onOpen={ (v) => onOpen(i, v) } />
                        </Column>
                        )
                    })
                }
            </Row>
        </div>
    )
}