import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import './playerPage.scss'

import { Row, Column } from '../components/ux/grid'
import { Box, BoxHeader } from '../components/ux/box'
import { UxStream } from '../components/ux/Uxvideo'
import Donation from '../components/ux/player/donation'
import { useStore } from "../store/useStore"
import { fetchPlayer } from "../socket/actions"
import { getUrl, parseNameDiscriminator } from "../library/Core"

export default function PlayerPage(props) {

    const { state, dispatch } = useStore()
    const urls = getUrl()
    const { playerId } = useParams()
    const {name, discriminator} = parseNameDiscriminator(playerId)

    const defaultBanner = '/assets/default-banner'
    const [player, setPlayer] = useState({
        name: "Chargement",
        banner: defaultBanner
    })

    const subcribeToggle = useCallback(() => {
        dispatch({
            "type": "setSubscribe",
            "payload": !state.player.subcribe
        })
    }, [state.player.subcribe])

    useEffect(() => {

        if(playerId) {
            fetchPlayer(name, discriminator)
            .then(user => {

                let stream = null
                let streamType = null
                if(user.streaming.twitch) {
                    stream = user.streaming.twitch
                    streamType = "twitch"
                }
                else if(user.streaming.mixer) {
                    stream = user.streaming.mixer
                    streamType = "mixer"
                }


                setPlayer({
                    ...user,
                    banner: user.banner ? urls.banner + user.banner : defaultBanner,
                    stream: stream,
                    streamType: streamType
                })
            })
        }

    }, [playerId])

    const hero = {
        backgroundImage: `url("${player.banner}.jpg")`
    }

    return (
        <>
        <div className="player-page">
            <div className="overflow-mover">
                <div className="player-hero hero-animation" style={ hero }>
                    <Row>
                        <Column>
                            <div className="player-hero-title">
                                <h1>{ player.name }</h1>
                            </div>
                        </Column>
                    </Row>
                </div>
            </div>
            <Row>
                <Column className="text-right spacer15">
                    <Donation value={ state.coins } />
                    <button className={"favoris pointer" + ( state.player.subcribe ? " checked" : "" )} onClick={ subcribeToggle }>
                        <svg viewBox="0 0 540 540">
                            <path style={ {"transform": "translate(15px, 15px)"} } d="M255,489.6l-35.7-35.7C86.7,336.6,0,257.55,0,160.65C0,81.6,61.2,20.4,140.25,20.4c43.35,0,86.7,20.4,114.75,53.55 C283.05,40.8,326.4,20.4,369.75,20.4C448.8,20.4,510,81.6,510,160.65c0,96.9-86.7,175.95-219.3,293.25L255,489.6z" />
                        </svg>
                    </button>
                </Column>
                <Column medium="6">
                    <Box>
                        <BoxHeader>Informations</BoxHeader>
                    </Box>
                </Column>
                <Column medium="6">
                    { player.stream && player.streamType && <div className="stream"><UxStream type={ player.streamType } value={ player.stream } /></div> }
                </Column>
            </Row>
        </div>
        </>
    )
}