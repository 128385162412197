import React, { useState, useCallback } from 'react'

import "./contactlist.scss"


export default function Contactlist({

}) {



    return (
        <>
            <header>Structure / teams</header>
            <header>Amis</header>
        </>
    )
}

