import React, { useState, useCallback } from 'react'
import { Sentence, SentenceFilter, SentenceFilterValue, notThis, isThis } from '../components/fields/sentence'
import { notNull, oneOfMany, isFunction } from '../library/Core'
import { HEROS as HERO_LOL, ROLES as ROLES_LOL } from '../project/leagueoflegends'
import { HEROS as HERO_OW, ROLES as ROLES_OW } from '../project/overwatch'
import { HEROS as HERO_APEX, ROLES as ROLES_APEX  } from '../project/apex'
import { HEROS as HERO_R6, ROLES as ROLES_R6  } from '../project/rainbowsixsiege'
import { HEROS as HERO_VALORANT, ROLES as ROLES_VALORANT } from '../project/valorant'
import HeroList from '../project/characterChooser'
import Uxmodal from '../components/ux/Uxmodal'

const HEROS = {
    leagueoflegends: HERO_LOL,
    overwatch: HERO_OW,
    apex: HERO_APEX,
    rainbowsixsiege: HERO_R6,
    valorant: HERO_VALORANT
}

const ROLES = {
    leagueoflegends: ROLES_LOL,
    overwatch: ROLES_OW,
    apex: ROLES_APEX,
    rainbowsixsiege: ROLES_R6,
    valorant: ROLES_VALORANT
}

function GetHero(props) {

    const [heroes, setHeroes] = useState([])

    const change = (data) => {
        setHeroes(data)
    }

    const updateFilter = () => {
        props.close([ ...heroes ])
    }

    const gameId = props.datas.game

    return (
        <>
            <HeroList values={ [...heroes] } gameId={ gameId } heroes={ HEROS[gameId] } roles={ ROLES[gameId] } onChange={ change } />
            <button onClick={ updateFilter }>Ajouter</button>
        </>
    )
}


export default function MercatoFilters(props) {

    const onChange = useCallback((values) => {
        if(props.name) {
            values = {
                target: {
                    name: props.name,
                    value: values
                }
            }
        }

        if(isFunction(props.onChange)) {
            props.onChange(values)
        }

    }, [props.name, props.onChange])

    const delegated = (onChange, isOpen, datas) => {

        const onClose = (characters) => {
            if(characters) {

                onChange(characters, characters.map(k => HEROS[datas.game][k].name))
            }
        }

        return <Uxmodal header="Choisissez vos héros" datas={ datas } render={ GetHero } isOpen={ isOpen } onClose={ onClose } />
    }

    const rules = {
        what: {},
        game: {
            condition: d => notNull(d.what) && notThis(d.what, 'staff'),
        },
        availables: {
            condition: d => notNull(d.what),
            optional: {
                label: "Disponibilité"
            },
            multiple: true
        },
        age: {
            condition: d => notNull(d.what) && notThis(d.what, 'staff'),
            optional: {
                label: "Age"
            }
        },
        objectif: {
            condition: d => notNull(d.what) && notThis(d.what, 'staff'),
            optional: {
                label: "Objectif"
            },
            multiple: true
        },
        options: {
            condition: d => notNull(d.game) && notThis(d.what, 'staff'),
            optional: {
                label: "Option"
            },
            multiple: true
        },
        rank: {
            optional: {
                label: "Rang"
            },
            condition: d => oneOfMany(['overwatch', 'apex', 'leagueoflegends', 'valorant'], d.game) && notThis(d.what, 'staff')
        },
        tier: {
            optional: {
                label: "Tier"
            },
            condition: d => oneOfMany(['apex', 'leagueoflegends', 'valorant', 'rainbowsixsiege'], d.game) && notNull(d.rank) && notThis(d.what, 'staff')
        },
        characters: {
            condition: d => oneOfMany(['overwatch', 'apex', 'leagueoflegends', 'valorant', 'rainbowsixsiege'], d.game) && notThis(d.what, 'staff'),
            multiple: true
        },
        staff: {
            condition: d => notNull(d.what) && isThis(d.what, 'staff'),
        }
    }

    return(
        <>
        <Sentence onChange={ onChange } rules={ rules }>
            <SentenceFilter field="what" label="Je cherche %filter%" default="quoi ?">
                <SentenceFilterValue value="structure">une structure</SentenceFilterValue>
                <SentenceFilterValue value="team">une équipe</SentenceFilterValue>
                <SentenceFilterValue value="player">un joueur</SentenceFilterValue>
                <SentenceFilterValue value="staff">du staff</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter
                field="game"
                label={
                    d => {
                        if(isThis(d.what, 'player')) return "qui joue à %filter%"
                        else if(oneOfMany(['structure', 'team'], d.what)) return "pour jouer à %filter%"
                    }
                }
                default="quel jeu ?"
            >
                <SentenceFilterValue value="apex">apex</SentenceFilterValue>
                <SentenceFilterValue value="counterstrikego">Counter-Strike: Global Offensive</SentenceFilterValue>
                <SentenceFilterValue value="dota2">dota 2</SentenceFilterValue>
                <SentenceFilterValue value="fortnite">fortnite</SentenceFilterValue>
                <SentenceFilterValue value="leagueoflegends">league of legends</SentenceFilterValue>
                <SentenceFilterValue value="legendofruneterra">legends of runeterra</SentenceFilterValue>
                <SentenceFilterValue value="overwatch">overwatch</SentenceFilterValue>
                <SentenceFilterValue value="rainbowsixsiege">Tom Clancy's Rainbow Six: Siege</SentenceFilterValue>
                <SentenceFilterValue value="valorant">valorant</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter field="characters" label="avec %filter%" default="quel personnage ?" delegate={ delegated } />

            <SentenceFilter field="rank" label={
                d => {
                    if(isThis(d.what, 'player')) return "qui est %filter%"
                    else if(isThis(d.what, 'structure') || isThis(d.what, 'team')) return "Je suis %filter%"
                }
            } default=" de quel rang ?">
                <SentenceFilterValue value="iron" condition={ d => oneOfMany(['leagueoflegends', 'valorant'], d.game) }>fer</SentenceFilterValue>
                <SentenceFilterValue value="bronze" condition={ d => oneOfMany(['overwatch', 'apex'], d.game) }>bronze</SentenceFilterValue>
                <SentenceFilterValue value="silver">argent</SentenceFilterValue>
                <SentenceFilterValue value="gold">or</SentenceFilterValue>
                <SentenceFilterValue value="platinum">platine</SentenceFilterValue>
                <SentenceFilterValue value="diamond">diamant</SentenceFilterValue>
                <SentenceFilterValue value="master" condition={ d => oneOfMany(['overwatch', 'apex', 'leagueoflegends'], d.game) }>master</SentenceFilterValue>
                <SentenceFilterValue value="grandmaster" condition={ d => oneOfMany(['overwatch', 'leagueoflegends'], d.game) }>grandmaster</SentenceFilterValue>
                <SentenceFilterValue value="top500" condition={ d => isThis(d.game, 'overwatch') }>top500</SentenceFilterValue>
                <SentenceFilterValue value="predator" condition={ d => isThis(d.game, 'apex') }>predator</SentenceFilterValue>
                <SentenceFilterValue value="challenger" condition={ d => isThis(d.game, 'leagueoflegends') }>challenger</SentenceFilterValue>
                <SentenceFilterValue value="immortal" condition={ d => isThis(d.game, 'valorant')}>immortal</SentenceFilterValue>
                <SentenceFilterValue value="valorant" condition={ d => isThis(d.game, 'valorant')}>valorant</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter field="tier" label="pour le tier %filter%" default="?">
                <SentenceFilterValue value={1}>1</SentenceFilterValue>
                <SentenceFilterValue value={2}>2</SentenceFilterValue>
                <SentenceFilterValue value={3}>3</SentenceFilterValue>
                <SentenceFilterValue value={4} condition={ (d) => oneOfMany(['apex', 'leagueoflegends', 'rainbowsixsiege'], d.game) }>4</SentenceFilterValue>
                <SentenceFilterValue value={5} condition={ (d) => isThis(d.game, 'rainbowsixsiege') }>5</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter field="staff" label="pour un poste de %filter%" default="quoi ?">
                <SentenceFilterValue value="coach">coach</SentenceFilterValue>
                <SentenceFilterValue value="manager">manager</SentenceFilterValue>
                <SentenceFilterValue value="videoeditor">monteur</SentenceFilterValue>
                <SentenceFilterValue value="designer">designer</SentenceFilterValue>
                <SentenceFilterValue value="developer">développeur</SentenceFilterValue>
                <SentenceFilterValue value="community manager">community manager</SentenceFilterValue>
                <SentenceFilterValue value="other">autre</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter field="availables" label="disponible %filter%" default="quel jour ?">
                <SentenceFilterValue value={1}>lundi</SentenceFilterValue>
                <SentenceFilterValue value={2}>mardi</SentenceFilterValue>
                <SentenceFilterValue value={3}>mercredi</SentenceFilterValue>
                <SentenceFilterValue value={4}>jeudi</SentenceFilterValue>
                <SentenceFilterValue value={5}>vendredi</SentenceFilterValue>
                <SentenceFilterValue value={6}>samedi</SentenceFilterValue>
                <SentenceFilterValue value={7}>dimanche</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter field="options" label={
                d => {
                    if(isThis(d.what, 'player')) return "qui peut %filter%"
                    else if(oneOfMany(['structure', 'team'], d.what)) return "Je peux %filter%"
                }
            } default=" ?">
                <SentenceFilterValue value="shotcall">shotcall</SentenceFilterValue>
                <SentenceFilterValue value="coach">être coaché</SentenceFilterValue>
                <SentenceFilterValue value="ultrack" condition={ d => isThis(d.game, 'overwatch') }>ultrack</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter
                field="age"
                label={
                    d => {
                        if(isThis(d.what, 'player')) return "qui a %filter%"
                        else if(oneOfMany(['structure', 'team'], d.what)) return "j'ai %filter%"
                    }
                }
                default="quel age ?">
                <SentenceFilterValue value={14}>entre 14-15ans</SentenceFilterValue>
                <SentenceFilterValue value={16}>entre 16-17ans</SentenceFilterValue>
                <SentenceFilterValue value={18}>entre 18-20ans</SentenceFilterValue>
                <SentenceFilterValue value={20}>entre 20-25ans</SentenceFilterValue>
                <SentenceFilterValue value={26}>entre 26-30ans</SentenceFilterValue>
                <SentenceFilterValue value={30}>entre 30-39ans</SentenceFilterValue>
                <SentenceFilterValue value={40}>entre 40ans et +</SentenceFilterValue>
            </SentenceFilter>

            <SentenceFilter
                field="objectif"
                label={
                    d => {
                        if(isThis(d.what, 'player')) return "qui a pour objectif %filter%"
                        else if(oneOfMany(['structure', 'team'], d.what)) return "je veux %filter%"
                    }
                }
                default="faire">
                <SentenceFilterValue value="chill">chill en team</SentenceFilterValue>
                <SentenceFilterValue value="tryhard">try hard en ranked</SentenceFilterValue>
                <SentenceFilterValue value="scrim">scrim</SentenceFilterValue>
            </SentenceFilter>
        </Sentence>

        </>
    )

}