import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../Layout'

import './eventPage.scss'

import { Row, Column } from '../components/ux/grid'
import { useStore } from "../store/useStore"
import sendQuery from "../socket/socket"
import { getUrl } from "../library/Core"

export default function EventPage(props) {

    const { state, dispatch } = useStore()
    const urls = getUrl()

    return (
        <>

        </>
    )
}