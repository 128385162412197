import React, { useState, useCallback, useEffect, memo, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { oneOfMany, toggleValueInArray } from '../library/Core'
import './gameListPage.scss'

import { Row, Column } from '../components/ux/grid'
import Gamecard from '../components/ux/game/gamecard'
import { fetchGameList } from '../socket/actions'

const Filter = props => {
    const {
        onChange,
        value,
        what,
        label,
        checked: __checked
    } = props

    const [checked, setChecked] = useState(__checked || false)

    const toggle = () => {
        onChange(what, value)
        setChecked(!checked)
    }

    return (
        <li className={ checked ? 'checked' : '' } onClick={ toggle }>{ label }</li>
    )
}

const Filters = memo(props => {

    const {
        applyFilter,
        filters
    } = props

    return (
        <div className="game-list-filter list-filters">
            <Row>
                <Column>
                    <ul>
                        <li>Genre ({ filters.type.length }/5)
                            <ul>
                                <Filter onChange={ applyFilter } value="fps" what="type" label="FPS" />
                                <Filter onChange={ applyFilter } value="br" what="type" label="BATTLEROYAL" />
                                <Filter onChange={ applyFilter } value="moba" what="type" label="MOBA" />
                                <Filter onChange={ applyFilter } value="card" what="type" label="CARTE" />
                                <Filter onChange={ applyFilter } value="other" what="type" label="AUTRE" />
                            </ul>
                        </li>
                        <li>Plateforme ({ filters.platform.length }/5)
                            <ul>
                                <Filter onChange={ applyFilter } value="XBOX" what="platform" label="xbox" />
                                <Filter onChange={ applyFilter } value="PS" what="platform" label="PS4" />
                                <Filter onChange={ applyFilter } value="PC" what="platform" label="PC" />
                                <Filter onChange={ applyFilter } value="APPLE" what="platform" label="Apple" />
                                <Filter onChange={ applyFilter } value="MOBILE" what="platform" label="Mobile" />
                            </ul>
                        </li>
                        <li>Filtres ({ filters.multi.length }/1)
                            <ul>
                                <Filter onChange={ applyFilter } value="ladder" what="multi" label="Ladder" />
                            </ul>
                        </li>
                    </ul>
                </Column>
            </Row>
        </div>
    )
})

const filtred = (game, filters) => {
    let typel = filters.type.length
    let typep = filters.platform.length
    let typem = filters.multi.length

    if(typel > 0 || typep > 0 || typem > 0) {

        let types = oneOfMany(filters.type, game.type)
        let platforms = filters.platform.filter(value => oneOfMany(game.platform, value)).length > 0
        let multi = game.ladder
        return (types || typel === 0) &&
            (platforms || typep === 0) &&
            (multi || typem === 0)
    }

    return true
}

export default function GamePage({

}) {

    const [gameList, setGameList] = useState([])
    const [filters, setFilters] = useState({
        type: [],
        platform: [],
        multi: []
    })

    useEffect(() => {
        fetchGameList()
        .then(games => {
            setGameList(games)
        })
        .catch(err => { console.log(err) })
    }, [])

    const applyFilter = useCallback((key, value) => {
        let tmp = { ...filters }
        tmp[key] = toggleValueInArray(value, tmp[key])
        setFilters(tmp)
    }, [filters])

    return (
        <>
            <div className="overflow-mover game-list">
                <div className="game-list-hero">
                    <Row>
                        <Column>
                            <h1>Liste des jeux disponibles</h1>
                            <div className="game-list-hero-available">{ gameList.length } jeux disponibles actuellement</div>
                        </Column>
                    </Row>
                </div>

                <Filters filters={ filters } applyFilter={ applyFilter } />

                <div className="game-list-wrapper">
                    <Row>
                    {
                        gameList.filter(game => filtred(game, filters)).map(game => {
                            return <Column key={ "listing-" + game.gameId } small="12" medium="6" large="3" xlarge="2">
                                <NavLink className="menu-entry" to={ "/game/" +  game.gameId }>
                                    <Gamecard datas={ game } />
                                </NavLink>
                            </Column>
                        })
                    }
                    </Row>
                </div>
            </div>
        </>
    )
}