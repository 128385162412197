import React from 'react'
import './achievementPage.scss'

import { Row, Column } from '../../components/ux/grid'
import { useStore } from "../../store/useStore"
import { experienceToLevel } from "../../library/Core"

export default function AdmiterAchievementPage(props) {

    const { level, percentage } = experienceToLevel(1478)

    return (
        <div className="admiter-achievement-page">
            <Row className="-margin">
                <div className="admiter-submitter">
                    { /*modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null*/ }
                </div>
                <Column>
                    <h2>Hauts-faits</h2>
                    <hr className="space10" />
                    <div className="achievement-xp">
                        Niveau { level } - 1478 XP
                    </div>
                    <div className="achievement-xp-bar-wrapper">
                        <div className="achievement-xp-bar" style={ { width: percentage + "%" } } />
                    </div>
                </Column>
            </Row>
        </div>
    )
}