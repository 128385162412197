import React from 'react'

import { Row, Column } from '../../components/ux/grid'
import { useStore } from "../../store/useStore"

export default function AdmiterWalletPage(props) {


    return (
        <div className="admiter-wallet-page">
            <div className="admiter-submitter">
                { /*modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null*/ }
            </div>
            <Column>
                <h2>Portefeuille</h2>
                <hr className="space10" />
            </Column>
        </div>
    )
}