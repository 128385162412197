import React, { useState } from 'react'


import './structurePage.scss'

import { Row, Column } from '../../components/ux/grid'

import useForm from '../../components/fields/form'
import { useStore } from "../../store/useStore"
import sendQuery from "../../socket/socket"
import Toast from "../../components/ux/Uxtoast"

import AdmiterSubStructurePage from './sub/structurePage'
import AdmiterSubTeamPage from './sub/teamPage'

export default function AdmiterStructurePage(props) {

    const [menu, setMenu] = useState('structure')
    const [submenu, setSubMenu] = useState('manage')

    const { state } = useStore()
    let schema = {
        structurename: { value: '', error: '' },
        structurecreation: { value: '', error: '' },
        structuredescription: { value: '', error: '' },
        structuretrigram: { value: '', error: '', validator: { regExp: /^([\w\d]{3})$/g, message:"Format invalide (3 lettres)" } },
    }

    const { state:formState, binding, modified, updateInitial, onSubmit } = useForm(
        schema,
        (datas) => {

        }
    )


    return (
        <div className="admiter-structure-page">
            <div className="admiter-submitter">
                { modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null }
            </div>
            <Row className="-margin">
                <Column>
                    <h2>Organisation équipe</h2>
                    <hr className="space10" />
                </Column>
                <Column>
                    <div className="admiter-sub-menu">
                        <ul>
                            <li className={ menu === "structure" ? "selected" : "" } onClick={ (e) => { setMenu("structure"); } }>Structure
                            {
                                menu === "structure" &&
                                <ul>
                                    <li className={ submenu === "manage" ? "selected" : "" } onClick={ (e) => setSubMenu("manage") }>Gérer</li>
                                    <li className={ submenu === "creation" ? "selected" : "" } onClick={ (e) => setSubMenu("creation") }>Création</li>
                                    <li className={ submenu === "join" ? "selected" : "" } onClick={ (e) => setSubMenu("join") }>Rejoindre</li>
                                </ul>
                            }
                            </li>
                            <li className={ menu === "team" ? "selected" : "" } onClick={ (e) => { setMenu("team"); } }>Equipes
                            {
                                menu === "team" &&
                                <ul>
                                    <li className={ submenu === "manage" ? "selected" : "" } onClick={ (e) => setSubMenu("manage") }>Gérer</li>
                                    <li className={ submenu === "creation" ? "selected" : "" } onClick={ (e) => setSubMenu("creation") }>Création</li>
                                    <li className={ submenu === "join" ? "selected" : "" } onClick={ (e) => setSubMenu("join") }>Rejoindre</li>
                                </ul>
                            }
                            </li>
                            <li className={ menu === "match" ? "selected" : "" } onClick={ (e) => { setMenu("match"); } }>Matchs
                            {
                                menu === "match" &&
                                <ul>
                                    <li className={ submenu === "manage" ? "selected" : "" } onClick={ (e) => setSubMenu("manage") }>Planning</li>
                                    <li className={ submenu === "creation" ? "selected" : "" } onClick={ (e) => setSubMenu("creation") }>Organiser</li>
                                </ul>
                            }
                            </li>
                        </ul>
                    </div>
                </Column>
                {
                    menu === 'structure' && <AdmiterSubStructurePage section={ submenu } />
                }
                {
                    menu === 'team'  && <AdmiterSubTeamPage section={ submenu } />
                }
            </Row>
        </div>
    )
}