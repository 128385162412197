import React, { useCallback, memo } from 'react'
import { NavLink } from 'react-router-dom'

import { getUrl } from '../../library/Core'
import { useStore } from "../../store/useStore"

export default memo(function Sidemenu() {

    const { state, dispatch } = useStore()
    const urls = getUrl()
    const gameList = state.setting.gameList

    const isActiveNav = useCallback((location, gameId) => {
        if(location.pathname && location.pathname.match(new RegExp(gameId))) {
            return true
        }

        return false
    }, [])


    return (
        <>
            <NavLink className="menu-entry" activeClassName="selected" exact to="/">
                <svg viewBox="0 0 512 512" className="svg-tiny">
                    <path d="M505.437,234.777L267.872,4.805c-6.617-6.407-17.124-6.407-23.741,0L6.565,234.777
                    c-11.002,10.65-3.425,29.329,11.87,29.329H78.04v230.828c0,9.425,7.641,17.067,17.067,17.067h88.171V336.35
                    c0-9.425,7.641-17.067,17.067-17.067h111.312c9.425,0,17.067,7.641,17.067,17.067V512h88.171c9.425,0,17.067-7.641,17.067-17.067
                    V264.107h59.605C508.876,264.107,516.428,245.417,505.437,234.777z"/>
                </svg>
            </NavLink>
            <NavLink className="menu-entry" activeClassName="selected" exact to="/game">
                <svg viewBox="0 0 566.873 566.873" className="svg-tiny">
                    <path d="M557.876,442.776H8.998c-4.965,0-8.997,4.032-8.997,8.996v70.979c0,24.367,19.753,44.121,44.122,44.121h478.628
                        c24.367,0,44.121-19.754,44.121-44.121v-70.979C566.87,446.81,562.839,442.776,557.876,442.776z"/>
                    <path d="M18.537,403.132h529.798c3.451,0,6.598-1.965,8.104-5.065c1.5-3.101,1.105-6.791-1.024-9.503L441.991,244.448
                        c-8.369-10.628-21.146-16.838-34.672-16.838h-45.938v76.034c-0.076,31.859-30.668,52.443-77.945,52.443
                        s-77.871-20.582-77.944-52.525v-75.954h-45.949c-13.527,0-26.306,6.21-34.674,16.838L11.458,388.563
                        c-2.13,2.714-2.526,6.404-1.024,9.503C11.937,401.169,15.083,403.132,18.537,403.132z"/>
                    <path d="M243.281,178.681V303.56c0.019,8.129,17.983,14.734,40.153,14.734c22.169,0,40.135-6.605,40.154-14.734V178.672
                        c31.74-15.096,53.846-47.193,53.846-84.672c0-51.916-42.09-94-94.018-94c-51.89,0-93.979,42.083-93.979,94
                        C189.438,131.487,211.543,163.586,243.281,178.681z"/>
                </svg>
            </NavLink>
            <NavLink className="menu-entry" activeClassName="selected" exact to="/mercato">
                <svg viewBox="-1 0 136 136.21852" className="svg-tiny">
                    <path d="M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031" />
                </svg>
            </NavLink>
            <NavLink className="menu-entry" activeClassName="selected" exact to="/event">
                <svg viewBox="0 0 512 512" className="svg-tiny">
                    <path d="m406 302h78.45l26.638-127.764c2.153-13.052-1.523-26.309-10.063-36.357-8.57-10.093-21.124-15.879-34.424-15.879h-421.202c-13.301 0-25.854 5.786-34.424 15.879-8.54 10.049-12.217 23.306-9.946 36.987l26.519 127.134h78.452c8.291 0 15 6.709 15 15s-6.709 15-15 15h-78.45l-26.638 127.764c-2.153 13.052 1.523 26.309 10.063 36.357 8.57 10.093 21.124 15.879 34.424 15.879h421.201c13.301 0 25.854-5.786 34.424-15.879 8.54-10.049 12.217-23.306 9.946-36.987l-26.518-127.134h-78.452c-8.291 0-15-6.709-15-15s6.709-15 15-15zm-150 60c0 33.091-26.909 60-60 60-25.371 0-48.12-16.069-56.572-40.005-2.769-7.808 1.318-16.377 9.141-19.131 7.778-2.783 16.377 1.333 19.131 9.141 4.233 11.953 15.614 19.995 28.3 19.995 16.538 0 30-13.462 30-30s-13.462-30-30-30h-18.647c-8.276 0-15-6.709-15-15s6.724-15 15-15h18.647c16.538 0 30-13.462 30-30s-13.462-30-30-30c-12.686 0-24.067 8.042-28.301 19.995-2.754 7.822-11.367 11.924-19.131 9.141-7.822-2.754-11.909-11.323-9.141-19.131 8.453-23.936 31.202-40.005 56.573-40.005 33.091 0 60 26.909 60 60 0 17.9-7.881 33.999-20.361 45 12.48 11.001 20.361 27.1 20.361 45zm105 45c0 8.291-6.709 15-15 15s-15-6.709-15-15v-151.978l-21.68 14.458c-6.914 4.57-16.216 2.725-20.801-4.16-4.6-6.899-2.739-16.201 4.16-20.801l45-30c4.6-3.062 10.532-3.354 15.396-.747 4.878 2.608 7.925 7.691 7.925 13.228z"/>
                    <path d="m32.691 45v45h450v-45c0-24.814-20.186-45-45-45h-360c-24.815 0-45 20.186-45 45zm375-15c8.284 0 15 6.714 15 15 0 8.284-6.716 15-15 15s-15-6.716-15-15c0-8.286 6.716-15 15-15zm-300 0c8.284 0 15 6.714 15 15 0 8.284-6.716 15-15 15s-15-6.716-15-15c0-8.286 6.716-15 15-15z"/>
                </svg>
            </NavLink>

            <div className="menu-separator" />
            {
                Object
                    .keys(gameList)
                    .filter(gameId => gameList[gameId].enable )
                    .map(gameId => {
                    return  <NavLink key={"menu-" + gameId} className="menu-entry" activeClassName="selected" to={ "/game/" + gameId } isActive={ (a,b) => isActiveNav(b, gameId) }>
                                <img src={ urls.icon + gameId + ".png" } alt={ gameList[gameId].name } />
                            </NavLink>
                    })
            }

            <div className="menu-footer">
                <div className="menu-entry">
                    <svg viewBox="0 0 426.667 426.667" className="svg-micro">
                        <rect x="192" y="192" width="42.667" height="128"/>
                        <path d="M213.333,0C95.467,0,0,95.467,0,213.333s95.467,213.333,213.333,213.333S426.667,331.2,426.667,213.333
                            S331.2,0,213.333,0z M213.333,384c-94.08,0-170.667-76.587-170.667-170.667S119.253,42.667,213.333,42.667
                            S384,119.253,384,213.333S307.413,384,213.333,384z"/>
                        <rect x="192" y="106.667" width="42.667" height="42.667"/>
                    </svg>
                </div>
            </div>
        </>
    )
})

