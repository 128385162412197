export const ROLES = {
    "assassin" : "Assassin",
    "fighter": "Combattant",
    "mage": "Mage",
    "shooter": "Tireur",
    "support": "Support",
    "tank": "Tank"
}

export const HEROS = {
    "aatrox": {
        "name": "Aatrox",
        "role": ["fighter", "tank"]
    },
    "ahri": {
        "name": "Ahri",
        "role": ["assassin", "mage"]
    },
    "akali": {
        "name": "Akali",
        "role": ["assassin"]
    },
    "alistar": {
        "name": "Alistar",
        "role": ["support", "tank"]
    },
    "amumu": {
        "name": "Amumu",
        "role": ["mage", "tank"]
    },
    "anivia": {
        "name": "Anivia",
        "role": ["mage", "support"]
    },
    "annie": {
        "name": "Annie",
        "role": ["mage"]
    },
    "aphelios": {
        "name": "Aphelios",
        "role": ["shooter"]
    },
    "ashe": {
        "name": "Ashe",
        "role": ["shooter", "support"]
    },
    "aurelionsol": {
        "name": "Aurelion Sol",
        "role": ["mage"]
    },
    "azir": {
        "name": "Azir",
        "role": ["mage", "shooter"]
    },
    "bard": {
        "name": "Bard",
        "role": ["mage", "support"]
    },
    "blitzcrank": {
        "name": "Blitzcrank",
        "role": ["fighter", "tank"]
    },
    "brand": {
        "name": "Brand",
        "role": ["mage"]
    },
    "braum": {
        "name": "Braum",
        "role": ["support", "tank"]
    },
    "caitlyn": {
        "name": "Caitlyn",
        "role": ["shooter"]
    },
    "camille": {
        "name": "Camille",
        "role": ["fighter", "tank"]
    },
    "cassiopeia": {
        "name": "Cassiopeia",
        "role": ["mage"]
    },
    "chogath": {
        "name": "Cho'Gath",
        "role": ["mage", "tank"]
    },
    "corki": {
        "name": "Corki",
        "role": ["shooter"]
    },
    "darius": {
        "name": "Darius",
        "role": ["fighter", "tank"]
    },
    "diana": {
        "name": "Diana",
        "role": ["fighter", "mage"]
    },
    "drmundo": {
        "name": "Dr. Mundo",
        "role": ["fighter", "tank"]
    },
    "draven": {
        "name": "Draven",
        "role": ["shooter"]
    },
    "ekko": {
        "name": "Ekko",
        "role": ["assassin", "fighter"]
    },
    "elise": {
        "name": "Elise",
        "role": ["fighter", "mage"]
    },
    "evelynn": {
        "name": "Evelynn",
        "role": ["assassins", "mage"]
    },
    "ezreal": {
        "name": "Ezreal",
        "role": ["mage", "shooter"]
    },
    "fiddlesticks": {
        "name": "Fiddlesticks",
        "role": ["mage", "support"]
    },
    "fiora": {
        "name": "Fiora",
        "role": ["assassin", "fighter"]
    },
    "fizz": {
        "name": "Fizz",
        "role": ["assassin", "fighter"]
    },
    "galio": {
        "name": "Galio",
        "role": ["mage", "tank"]
    },
    "gangplank": {
        "name": "Gangplank",
        "role": ["fighter"]
    },
    "garen": {
        "name": "Garen",
        "role": ["fighter", "tank"]
    },
    "gnar": {
        "name": "Gnar",
        "role": ["fighter", "tank"]
    },
    "gragas": {
        "name": "Gragas",
        "role": ["fighter", "mage"]
    },
    "graves": {
        "name": "Graves",
        "role": ["shooter"]
    },
    "hecarim": {
        "name": "Hecarim",
        "role": ["fighter", "tank"]
    },
    "heimerdinger": {
        "name": "Heimerdinger",
        "role": ["mage", "support"]
    },
    "illaoi": {
        "name": "Illaoi",
        "role": ["fighter", "tank"]
    },
    "irelia": {
        "name": "Irelia",
        "role": ["assassin", "fighter"]
    },
    "ivern": {
        "name": "Ivern",
        "role": ["mage", "support"]
    },
    "janna": {
        "name": "Janna",
        "role": ["mage", "support"]
    },
    "jarvanIV": {
        "name": "Jarvan IV",
        "role": ["fighter", "tank"]
    },
    "jax": {
        "name": "Jax",
        "role": ["assassin", "fighter"]
    },
    "jayce": {
        "name": "Jayce",
        "role": ["fighter", "shooter"]
    },
    "jhin": {
        "name": "Jhin",
        "role": ["mage", "shooter"]
    },
    "jinx": {
        "name": "Jinx",
        "role": ["shooter"]
    },
    "kaisa": {
        "name": "Kai'Sa",
        "role": ["shooter"]
    },
    "kalista": {
        "name": "Kalista",
        "role": ["shooter"]
    },
    "karma": {
        "name": "Karma",
        "role": ["mage", "support"]
    },
    "karthus": {
        "name": "Karthus",
        "role": ["mage"]
    },
    "kassadin": {
        "name": "Kassadin",
        "role": ["mage"]
    },
    "katarina": {
        "name": "Katarina",
        "role": ["assassin", "mage"]
    },
    "kayle": {
        "name": "Kayle",
        "role": ["fighter"]
    },
    "kayn": {
        "name": "Kayn",
        "role": ["fighter"]
    },
    "kennen": {
        "name": "Kennen",
        "role": ["mage"]
    },
    "khazix": {
        "name": "Kha'Zix",
        "role": ["assassin"]
    },
    "kindred": {
        "name": "Kindred",
        "role": ["shooter"]
    },
    "kled": {
        "name": "Kled",
        "role": ["fighter"]
    },
    "kogmaw": {
        "name": "Kog'Maw",
        "role": ["shooter"]
    },
    "leblanc": {
        "name": "Leblanc",
        "role": ["assassin"]
    },
    "leesin": {
        "name": "Lee Sin",
        "role": ["fighter"]
    },
    "leona": {
        "name": "Leona",
        "role": ["tank"]
    },
    "lissandra": {
        "name": "Lissandra",
        "role": ["mage"]
    },
    "lucian": {
        "name": "Lucian",
        "role": ["shooter"]
    },
    "lulu": {
        "name": "Lulu",
        "role": ["support"]
    },
    "lux": {
        "name": "Lux",
        "role": ["mage"]
    },
    "masteryi": {
        "name": "Maître Yi",
        "role": ["assassin"]
    },
    "malphite": {
        "name": "Malphite",
        "role": ["tank"]
    },
    "malzahar": {
        "name": "Malzahar",
        "role": ["mage"]
    },
    "maokai": {
        "name": "Maokai",
        "role": ["tank"]
    },
    "missfortune": {
        "name": "Miss Fortune",
        "role": ["shooter"]
    },
    "mordekaiser": {
        "name": "Mordekaiser",
        "role": ["fighter"]
    },
    "morgana": {
        "name": "Morgana",
        "role": ["mage"]
    },
    "nami": {
        "name": "Nami",
        "role": ["support"]
    },
    "nasus": {
        "name": "Nasus",
        "role": ["fighter"]
    },
    "nautilus": {
        "name": "Nautilus",
        "role": ["tank"]
    },
    "neeko": {
        "name": "Neeko",
        "role": ["mage"]
    },
    "nidalee": {
        "name": "Nidalee",
        "role": ["assassin"]
    },
    "nocturne": {
        "name": "Nocturne",
        "role": ["assassin"]
    },
    "nunu": {
        "name": "Nunu et Willump",
        "role": ["tank"]
    },
    "olaf": {
        "name": "Olaf",
        "role": ["fighter"]
    },
    "orianna": {
        "name": "Orianna",
        "role": ["mage"]
    },
    "ornn": {
        "name": "Ornn",
        "role": ["tank"]
    },
    "pantheon": {
        "name": "Pantheon",
        "role": ["fighter"]
    },
    "poppy": {
        "name": "Poppy",
        "role": ["tank"]
    },
    "pyke": {
        "name": "Pyke",
        "role": ["support"]
    },
    "qiyana": {
        "name": "Qiyana",
        "role": ["support"]
    },
    "quinn": {
        "name": "Quinn",
        "role": ["shooter"]
    },
    "rakan": {
        "name": "Rakan",
        "role": ["support"]
    },
    "rammus": {
        "name": "Rammus",
        "role": ["tank"]
    },
    "reksai": {
        "name": "Rek'Sai",
        "role": ["fighter"]
    },
    "renekton": {
        "name": "Renekton",
        "role": ["fighter"]
    },
    "rengar": {
        "name": "Rengar",
        "role": ["assassin"]
    },
    "riven": {
        "name": "Riven",
        "role": ["fighter"]
    },
    "rumble": {
        "name": "Rumble",
        "role": ["fighter"]
    },
    "ryze": {
        "name": "Ryze",
        "role": ["mage"]
    },
    "sejuani": {
        "name": "Sejuani",
        "role": ["tank"]
    },
    "senna": {
        "name": "Senna",
        "role": ["shooter"]
    },
    "sett": {
        "name": "Sett",
        "role": ["fighter"]
    },
    "shaco": {
        "name": "Shaco",
        "role": ["assassin"]
    },
    "shen": {
        "name": "Shen",
        "role": ["tank"]
    },
    "shyvana": {
        "name": "Shyvana",
        "role": ["fighter"]
    },
    "singed": {
        "name": "Singed",
        "role": ["tank"]
    },
    "sion": {
        "name": "Sion",
        "role": ["tank"]
    },
    "sivir": {
        "name": "Sivir",
        "role": ["shooter"]
    },
    "skarner": {
        "name": "Skarner",
        "role": ["fighter"]
    },
    "sona": {
        "name": "Sona",
        "role": ["support"]
    },
    "soraka": {
        "name": "Soraka",
        "role": ["support"]
    },
    "swain": {
        "name": "Swain",
        "role": ["mage"]
    },
    "sylas": {
        "name": "sylas",
        "role": ["mage"]
    },


    "syndra": {
	"name": "Syndra",
	"role": ["mage"]
    },
    "tahmkench": {
	"name": "Tahm Kench",
	"role": ["support"]
    },
    "taliyah": {
	"name": "Taliyah",
	"role": ["mage"]
    },
    "talon": {
	"name": "Talon",
	"role": ["assassin"]
    },
    "taric": {
	"name": "Taric",
	"role": ["support"]
    },
    "teemo": {
	"name": "Teemo",
	"role": ["shooter"]
    },
    "thresh": {
	"name": "Thresh",
	"role": ["support"]
    },
    "tristana": {
	"name": "Tristana",
	"role": ["shooter"]
    },
    "trundle": {
	"name": "Trundle",
	"role": ["fighter"]
    },
    "tryndamere": {
	"name": "Tryndamere",
	"role": ["fighter"]
    },
    "twistedfate": {
	"name": "Twisted Fate",
	"role": ["mage"]
    },
    "twitch": {
	"name": "Twitch",
	"role": ["shooter"]
    },
    "udyr": {
	"name": "Udyr",
	"role": ["fighter"]
    },
    "urgot": {
	"name": "Urgot",
	"role": ["fighter"]
    },
    "varus": {
	"name": "Varus",
	"role": ["shooter"]
    },
    "vayne": {
	"name": "Vayne",
	"role": ["shooter"]
    },
    "veigar": {
	"name": "Veigar",
	"role": ["mage"]
    },
    "velkoz": {
	"name": "Vel'Koz",
	"role": ["mage"]
    },
    "vi": {
	"name": "Vi",
	"role": ["fighter"]
    },
    "viktor": {
	"name": "Viktor",
	"role": ["mage"]
    },
    "volibear": {
	"name": "Volibear",
	"role": ["fighter"]
    },
    "warwick": {
	"name": "Warwick",
	"role": ["support"]
    },
    "wukong": {
	"name": "Wukong",
	"role": ["fighter"]
    },
    "xayah": {
	"name": "Xayah",
	"role": ["shooter"]
    },
    "xerath": {
	"name": "Xerath",
	"role": ["mage"]
    },
    "xinzhao": {
	"name": "Xin Zhao",
	"role": ["fighter"]
    },
    "yasuo": {
	"name": "Yasuo",
	"role": ["fighter"]
    },
    "yorick": {
	"name": "Yorick",
	"role": ["fighter"]
    },
    "yuumi": {
	"name": "Yuumi",
	"role": ["support"]
    },
    "zac": {
	"name": "Zac",
	"role": ["tank"]
    },
    "zed": {
	"name": "Zed",
	"role": ["assassin"]
    },
    "ziggs": {
	"name": "Ziggs",
	"role": ["mage"]
    },
    "zilean": {
	"name": "Zilean",
	"role": ["support"]
    },
    "zoe": {
	"name": "Zoé",
	"role": ["mage"]
    },
    "zyra": {
	"name": "Zyra",
	"role": ["mage"]
    },
}