import React, { useState, useEffect }  from 'react'
import './UxMatch.scss'

export default function UxMatch(props) {

    const size = props.size || "small"
    let color1 = 'egal'
    let color2 = 'egal'

    if(props.result && props.result[0] > props.result[1]) {
        color1 = 'win'
        color2 = 'loose'
    }
    else if(props.result && props.result[0] < props.result[1]) {
        color1 = 'loose'
        color2 = 'win'
    }

    return (
        <div className={ "ux-match ux-match-" + size }>
            <div className="ux-match-team1">
                { props.icon1 ? <div className="ux-match-icon"><img src={ props.icon1 } alt={ props.team1 } /></div> : '' }
                <p>{ props.team1 }</p>
            </div>
            {
                props.result ?
                <div className="ux-match-result">
                    <span className={"ux-match-" + color1 }>{ props.result[0] }</span>&nbsp;:&nbsp;
                    <span className={"ux-match-" + color2 }>{ props.result[1] }</span>
                </div>
                :
                <div className="ux-match-result"> VS </div>
            }
            <div className="ux-match-team2">
                { props.icon2 && props.size === 'big' ? <div className="ux-match-icon"><img src={ props.icon2 } alt={ props.team2 } /></div> : '' }
                <p>{ props.team2 }</p>
                { props.icon2 && props.size !== 'big' ? <div className="ux-match-icon"><img src={ props.icon2 } alt={ props.team2 } /></div> : '' }
            </div>
        </div>
    )
}