import React, { useState } from 'react'

import { Row, Column } from '../components/ux/grid'
import UxMatch from '../components/ux/match/UxMatch'
import UxStructure from '../components/ux/structure/UxStructure'
import UxTeam from '../components/ux/structure/UxTeam'
import { UxYoutube, UxTwich } from '../components/ux/Uxvideo'

import { useStore } from "../store/useStore"

export default function HomePage(props) {

    const { state, dispatch } = useStore()

    let players = [
        {
            "name": "Khraine",
            "discriminator": "0001",
            "role": "tank",
            "sr": 2200
        },
        {
            "name": "Pipoune",
            "role": "tank",
            "sr": 2300
        },
        {
            "name": "Nearts",
            "role": "dps",
            "sr": 2150
        },
        {
            "name": "Rykos",
            "discriminator": "0002",
            "role": "dps",
            "sr": 2150
        },
        {
            "name": "Richnews",
            "role": "support",
            "sr": 2250
        },
        {
            "name": "Kaielia",
            "role": "support",
            "sr": 1850
        },
        {
            "name": "Darkkk",
            "role": "dps",
            "sr": 1950
        }
    ]

    //<UxYoutube autoplay={ true } type="live" channel="UCI45pR3yMi0uGE47ewT43Ow" />
    return (
        <>
            <Row>
                <Column medium="6">

                </Column>
                <Column medium="6">

                </Column>
            </Row>
            {

            /*
            <Row>
                <Column medium="6">
                    <div className="box">
                        <UxStructure name="Tribe" onChange={ onChange } edit={ false } description="Trop bien" country="fr" icon="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg">
                            <UxTeam name="Amaze" game="overwatch" players={ players } />
                            <UxTeam name="Btrayed" game="overwatch" players={ players } />
                            <UxTeam name="Cnine" game="overwatch" players={ players } />
                        </UxStructure>
                    </div>
                </Column>
                <Column medium="6">
                    <div className="box">
                        <header>Matchs / résultats</header>
                        <UxMatch
                            team1="Atlanta reign"
                            team2="Dallas Fuel"
                            result={ [3, 3] }
                            icon1="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg"
                            icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                        />
                        <UxMatch
                            team1="Atlanta reign"
                            team2="Dallas Fuel"
                            result={ [5, 4] }
                            icon1="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg"
                            icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                        />
                        <UxMatch
                            team1="Atlanta reign"
                            team2="Dallas Fuel"
                            result={ [3, 4] }
                            icon1="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg"
                            icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                        />
                        <UxMatch
                            team1="Atlanta reign"
                            team2="Dallas Fuel"
                            result={ [3, 4] }
                            icon1="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg"
                            icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                        />
                        <header>Matchs / résultats</header>
                        <UxMatch
                            size="big"
                            team1="Atlanta reign"
                            team2="Dallas Fuel"
                            result={ [3, 4] }
                            icon1="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg"
                            icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                        />
                    </div>
                </Column>
            </Row>
            */
            }
        </>
    )
}