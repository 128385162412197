import React  from 'react'
import PropTypes from 'prop-types'
import './Uxtoast.scss'

function uniqid() {
    return (new Date().getTime() + Math.floor((Math.random()*10000)+1)).toString(16);
}

class Uxtoast extends React.Component {

    static add(text, timer, theme) {
        return Uxtoast.__instance.addToast(text, timer, theme)
    }

    static remove(id) {
        Uxtoast.__instance.removeToast(id)
    }

    constructor(props) {
        super(props)
        this.removeToast = this.removeToast.bind(this)

        Uxtoast.__instance = this

        this.state = {
            list: []
        }
    }

    addToast(text, timer = 3000, theme = 'normal') {

        let id = uniqid()

        this.setState({
            list: [...this.state.list, { text: text, timer: timer, theme: theme, id: id }]
        })

        return id
    }

    removeToast(id) {

        setTimeout(() => {
            this.setState({
                list: this.state.list.filter( item => item.id !== id)
            })
        }, 250)
    }

    render() {
        return (
            <div className="ux-toast-container">
                {
                    this.state.list.map((toast, i) => {
                        let attrs = { ...toast, remove: this.removeToast }
                        return (
                            <Uxtoaster key={ toast.id } { ...attrs } />
                        )
                    })
                }
            </div>
        )
    }
}

class Uxtoaster extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            status: "open"
        }

        if(props.timer) {
            this.timer = setTimeout(() => {
                props.remove(this.props.id)
            }, props.timer)
        }
    }

    close() {
        this.setState({
            status: "close"
        })

        this.props.remove(this.props.id)
    }

    componentWillUnmount() {
        if(this.timer) {
            clearTimeout(this.timer)
        }
    }

    render() {
        let cn = 'ux-toast-toaster ux-toast-toaster-' + this.state.status
        if(this.props.theme) {
            cn += ' ux-toast-' + this.props.theme
        }
        return (
            <div className={ cn }>
                <div className="ux-toast-message">{ this.props.text }</div>
                <div onClick={ (e) => this.close() } className="ux-toast-close"></div>
            </div>
        )
    }
}

Uxtoaster.propTypes = {
    theme: PropTypes.string,
    timer: PropTypes.number,
    text: PropTypes.string.isRequired,
    id: PropTypes.string,
    status: PropTypes.string
}

export default Uxtoast

