import Storage from "../library/Storage"
import { mergeObject, replaceObject } from "../library/Core"
import { updateAccessToken } from '../socket/socket'

const localStorage = new Storage('vyrall')
const datasStore = localStorage.getData()

let defaultDatas = {
    lastUpdateImage: null,
    myself: false,
    coins: 0,
    experience: 0,
    player: {
        subcribe: true,
    },
    ux: {
        admiter: false,
        sidepanelChat: false
    },
    setting: {
        coins: true,
        theme:  datasStore && datasStore.theme? datasStore.theme : 'theme1',
        avatar: datasStore && datasStore.avatar? datasStore.avatar : "",
        banner:  datasStore && datasStore.banner? datasStore.banner : "",
        gameList: {
            "overwatch": { name: "Overwatch", enable: true },
            "apex": { name: "Apex", enable: true },
            "leagueoflegends": { name: "League Of Legends", enable: true }
        },
        ladderList: {

        },
        chat: {
            status: ""
        }
    },
    dates: {
        created: null
    },
    games: [],
    online: true,
    token: null
}

export const datasInitialState = {
    ...defaultDatas,
    token: datasStore && datasStore.token ? datasStore.token : null
}

export const datasActions = {

    setOnline: (datas, state) => {
        let tmp = { ...state, online: datas.payload }
        return tmp
    },
    setMyself: (datas, state) => {
        let tmp = mergeObject(state, datas.payload, { myself: true })

        localStorage.setData('theme', datas.payload.setting.theme)
        localStorage.setData('avatar', datas.payload.setting.avatar)

        return tmp
    },
    setToken: (datas, state) => {
        let tmp = mergeObject(state, { token: datas.payload })

        localStorage.setData({
            token: datas.payload
        })
        updateAccessToken(datas.payload)

        return tmp
    },
    revokeToken: (datas, state) => {
        let tmp = { ...state, ...defaultDatas, nickname: state.nickname }

        localStorage.setData({
            token: null
        })

        updateAccessToken(null)

        return tmp
    },
    setTheme: (datas, state) => {
        let tmp = {
            ...state
        }
        tmp.setting.theme = datas.payload

        localStorage.setData('theme', datas.payload)

        return tmp
    },
    setAvatar: (datas, state) => {
        let tmp = {
            ...state
        }
        tmp.setting.avatar = datas.payload
        tmp.lastUpdateImage = new Date().getTime()

        localStorage.setData('avatar', datas.payload)

        return tmp
    },
    setBanner: (datas, state) => {
        let tmp = {
            ...state
        }
        tmp.setting.banner = datas.payload
        tmp.lastUpdateImage = new Date().getTime()

        localStorage.setData('banner', datas.payload)

        return tmp
    },
    setCoin: (datas, state) => {
        let tmp = { ...state, coins: datas.payload }
        return tmp
    },
    setStatus: (datas, state) => {
        let tmp = {
            ...state
        }
        tmp.setting.chat.status = datas.payload

        return tmp
    },
    setSubscribe: (datas, state) => {
        let tmp = {
            ...state
        }
        tmp.player.subcribe = datas.payload

        return tmp
    },
    setAdmiterStatus: (datas, state) => {
        let tmp = {
            ...state
        }
        tmp.ux.admiter = datas.payload

        return tmp
    },
    setLadderResults: (datas, state) => {
        let tmp = {
            ...state
        }
        tmp.ladder.results = datas.payload

        return tmp
    },
    setFavGame: (datas, state) => {
        let tmp = {
            ...state
        }

        let count = Object.keys(tmp.setting.gameList).length
        if(count < 5) {
            if(tmp.setting.gameList[datas.payload.gameId]) {
                tmp.setting.gameList[datas.payload.gameId].enable = datas.payload.value
            }
            else {
                tmp.setting.gameList[datas.payload.gameId] = {
                    name: datas.payload.name,
                    enable: datas.payload.value
                }
            }
        }

        return tmp
    }
}