import React, { useState, useCallback } from 'react'

import Toast from '../../components/ux/Uxtoast'
import { isEmail } from "../../library/Core"
import { doLogin, forgetPassword } from '../../socket/actions'
import { useStore } from "../../store/useStore"

export default function Login({


}) {

    const { state, dispatch } = useStore()

    const [login, setLogin] = useState(state.nickname ? state.nickname : "")
    const [password, setPassword] = useState("")
    const [loginCount, setLoginCount] = useState(0)

    const gologin = useCallback(() => {
        if(loginCount > 3) {
            forgetPassword()
            .then(datas => {

            })
        }
        else {
            doLogin(login, password)
            .then(datas => {
                dispatch({
                    type: "setToken",
                    payload: datas.token
                })
            }, error => {
                setLoginCount(loginCount + 1)
            })
        }
    }, [login, loginCount, password])

    const loginChange = useCallback((e) => {
        setLogin(e.target.value)
    }, [])

    const passwordChange = useCallback((e) => {
        setPassword(e.target.value)
    }, [])

    return(
        <>
            <input type="text" value={ login } placeholder="Identifiant / email" onChange={ loginChange } onInput={ loginChange } />
            <input disabled={ loginCount > 3 } type="password" placeholder="Mot de passe" onChange={ passwordChange } onInput={ passwordChange } />
            <button className={ loginCount > 3 ? "warning" : "" } onClick={ gologin }>
                {
                    loginCount > 3 ?
                        "Mot de passe oublié ?" :
                        "Identification" + (loginCount > 0 ? ' (' + loginCount + '/3)' : '')
                }
            </button>
        </>
    )
}