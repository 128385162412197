import React, { useState, useCallback } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import './bannerChosen.scss'

export default function BannerChosen(props) {

    const [src, setSrc] = useState(props.src || null)
    const [file, setFile] = useState(null)

    const cropper = React.createRef(null)

    const size = {
        height: props.height || 500,
        width: props.width || 1980
    }

    const type = props.type ? props.type : 'jpeg'

    const validate = useCallback(() => {
        const base64 = cropper.current.cropper.getCroppedCanvas({
            width: size.width,
            height: size.height
        }).toDataURL('image/' + type)
        setSrc(base64)

        if(typeof props.onChange === 'function') {
            props.onChange(base64)
        }
        setFile(null)
    }, [size, props.onChange])

    const toBase64 = useCallback(e => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            setFile(reader.result)
        }
    }, [])

    return (
        <div className={ "banner-chosen" + ( props.className ? ' ' + props.className : '' ) } >
            {
                !file ?
                    <div className="banner-chosen-button">
                        <img src={ src } alt="" />
                        <input type="file" className="chooser" onChange={ toBase64 } />
                    </div>
                :
                    <div className="banner-chosen-cropper" >
                        <Cropper
                            ref={ cropper }
                            src={ file }
                            style={
                                {
                                    height: size.height,
                                    width: "100%"
                                }
                            }
                            // Cropper.js options
                            aspectRatio={ size.width / size.height }
                            guides={ false }
                            viewMode={ 1 }
                            responsive={ true }
                            modal={ true }
                            background={false}
                            center={true}
                        />
                    </div>
            }
            <button onClick={ validate } disabled={ !file } >Valider</button>
        </div>
    )
}