import React, { useState, useEffect }  from 'react'
import './Uxmodal.scss'

export default function Uxmodal(props) {

    const [open, setOpen] = useState(props.isOpen || false)

    let wrapperClass = ' ux-modal-closable'
    let contentClass = ''
    if(props.closable && props.closable === false) {
        wrapperClass = ''
    }

    if(!open) {
        wrapperClass += ' ux-modal-close'
    }

    if(props.full) {
        contentClass += ' ux-modal-full'
    }

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])

    const close = (payload = null, callback = null) => {
        setOpen(false)

        if(typeof props.onClose === 'function') {
            props.onClose(payload)
        }

        if(typeof callback === 'function') {
            callback()
        }
    }

    const internalClose = (e) => {
        if(e.target === e.currentTarget && props.closable !== false) {
            close()
        }
    }

    return (
        <div className={ "ux-modal-wrapper" + wrapperClass } onClick={ internalClose } >
            <div className="ux-modal">
                { props.header ? <div className="ux-modal-header">{ props.header }</div>: '' }
                <div className={ "ux-modal-content" + contentClass }>
                    { props.render({
                        close: close,
                        datas: props.datas
                    }) }
                </div>
            </div>
        </div>
    )
}