import conf from '../conf/setting.json'
import unified from 'unified'
import parse from 'remark-parse'
import remark2react from 'remark-react'
import ping from '../library/markdown/ping'

export class Event {

    constructor(elem) {
        this.elem = elem
    }

    add(event, fn, useCapture = false) {
        this.elem.addEventListener(event, fn, useCapture)
        return this
    }

    remove(event, fn, useCapture = false) {
        this.elem.removeEventListener(event, fn, useCapture)
        return this
    }
}

export const isEmail = (email) => {
    return email.match(/([\da-z\-._])+@([\da-z\-.])+\.[a-z]{2,}/gi) !== null
}

export const numberFormat = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export const dbDateToField = (date) => {
    return date.replace(/(\d{4})-(\d{2})-(\d{2}).*/, '$1-$2-$3')
}

export const walkPath = (obj, path, separator = '.') => {
    let fixit = path
        .replace(/\[([^\]+])\]/g, '.$1.')
        .replace(/\.$/g, '')
        .replace(/\.\./g, '.')

    let properties = fixit.split(separator)
    return properties.reduce((prev, curr) => {
        return prev && prev[curr]
    }, obj)
}

export const mergeObject = (...objects) => {
    const isObject = obj => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach(key => {
            const pVal = prev[key]
            const oVal = obj[key]

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal)
            }
            else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeObject(pVal, oVal)
            }
            else {
                prev[key] = oVal
            }
        });

        return prev
    }, {})
}

export const replaceObject = (...objects) => {
    const isObject = obj => obj && typeof obj === 'object'
    let _base = objects.shift()

    const recursive = (base, current) => {
        Object.keys(current).forEach(k => {

            // not exists
            if(!base[k]) {
                base[k] = current[k]
            }
            else if(Array.isArray(base[k]) && Array.isArray(current[k])) {
                base[k] = current[k]
            }
            else if(isObject(base[k]) && isObject(current[k])) {
                base[k] = recursive(base[k], current[k])
            }
            else {
                base[k] = current[k]
            }
        })

        return base
    }

    objects.forEach(n => {
        _base = recursive(_base, n)
    })

    return _base
}

export const getUrl = () => {

    let path = 'prod'

    if(window.__SUBSCRIPTION__ && window.__SUBSCRIPTION__.env) {
        path = window.__SUBSCRIPTION__.env
    }
    if(process.env.NODE_ENV === "development") {
        path = 'dev'
    }

    return conf.url[path]
}

export const hashUnsecured = s => {
    let hash = s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)
    return hash > 0 ? hash : hash * -1
}

export const experienceToLevel = (xp) => {
    const level = parseInt(xp / 100) || 0
    const perc = xp % 100 || 0

    return {
        level: level,
        percentage: perc
    }
}

export const shortUuid = (length = 6) => {
    return (Math.random().toString(36) + Math.random().toString(36).substr(2)).substr(2, length)
}

export const toggleValueInArray = (value, [...reference]) => {
    const index = reference.indexOf(value)

    if(index !== -1) {
        reference.splice(index, 1)
    }
    else {
        reference.push(value)
    }

    return reference
}

export const inArray = (value , [...reference]) => {
    return reference.indexOf(value) !== -1
}

export const is = (value, type) => {
    switch(type) {
        case 'array':
            return value instanceof Array
        case 'null':
            return value === null
        case 'object':
            return value.constructor === Object
        case 'undefined':
            return value === undefined
        case 'function':
            return typeof value === type
        case 'empty':
            return value === null || value === '' || value === undefined || (value instanceof Array && value.length === 0) || (value.constructor === Object && Object.keys(value).length === 0)
        default:
            return -1
    }
}

export const isFunction = (fn) => is(fn, 'function')

export const oneOfMany = (datas, value) => {
    return datas.indexOf(value) !== -1
}

export const notNull = (data) => {
    return !is(data, 'null')
}

export const notEmpty = (value) => {
    return !is(value, 'empty')
}

// fn.displayName = 'toto.titi' <- changer le nom de la function genre toto.titi
// https://using-remark.gatsbyjs.org/custom-components/

export const markdown = (text, on = {}) => {

    const urls = getUrl()

    const result = unified()
    .use(parse)
    .use(ping, {
        pingEntity: (label) => true,
        entityURL: (label, type = '@') => {
            switch(type) {
                case '%':
                    return urls.team + label.replace(/#/, '-')
                case '$':
                    return urls.structure + label.replace(/#/, '-')
                default:
                    return urls.player + label.replace(/#/, '-')
            }
        },
        transform: (label) => label.replace(/(\w+)#\d{4}/, "$1"),
        entityRegex: /[\s'"(,:<]?(@|%|\$)(\w+#\d{4})/,
        mapping: {
            type: 1,
            label: 2
        }
    })
    .use(remark2react, {
        sanitize: require('./markdown/sanitize.json')
    })
    .processSync(text)

    const keys = Object.keys(on)

    if(keys.length > 0) {
        keys.forEach(what => {
            if(result[what]) {
                on[what](result[what])
            }
        })
    }

    return result.result
}

export const toDate = (date, locale = 'fr-FR') => {
    const opts = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const _date = Date.parse(date)
    return new Date(_date).toLocaleDateString(locale, opts)
}

export const isSameTarget = (e) => e.target === e.currentTarget

export const localeDate = (date, options = {}, locale = undefined) => {
    const _date = new Date(date)
    const _options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    options = { ..._options, options }
    return _date.toLocaleDateString(locale, options)
}

export const parseNameDiscriminator = (fullname) => {
    const res = /^([^\-]+)-?(\d{4})+/.exec(fullname)

    return {
        name: res[1],
        discriminator: res[2]
    }
}