import React, { useState, useCallback, useEffect } from 'react'

import { useStore } from "../../store/useStore"
import { Box, BoxContent } from '../../components/ux/box'
import { Row, Column } from '../../components/ux/grid'
import { getUrl } from '../../library/Core'

export default function GameDetailPage({
    gameId: __gameId,
    name: __name,
    description: __description,
}) {

    const urls = getUrl();

    return (
        <Row>
            <Column xlarge="3">
                <Box>
                    <img src={ urls.gamecard + __gameId + ".jpg" } alt={ __name } />
                    <BoxContent>
                        <p>Overwatch est un jeu vidéo de tir en vue subjective, en équipes, en ligne, développé et publié par Blizzard Entertainment.</p>
                        <p>Le jeu est annoncé le 7 novembre 2014 à la BlizzCon, et est commercialisé le 24 mai 2016 sur Windows, PlayStation 4 et Xbox One et
                        le 15 octobre 2019 sur Nintendo Switch.</p>
                        <p>Le jeu met l'accent sur la coopération entre différentes classes représentées par différents personnages
                        ayant chacun leurs capacités et particularités.</p>
                        <p>Le jeu s'inspire notamment des jeux de tir en vue subjective en équipe de la décennie précédente
                        mettant eux aussi l'accent sur la coopération entre plusieurs classes de personnage, notamment Team Fortress 2.</p>
                        <p>source wikipedia</p>
                    </BoxContent>
                </Box>
            </Column>
        </Row>
    )

}