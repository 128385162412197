import React, { useState, useCallback } from 'react'
import sha256 from 'crypto-js/sha256'

import Toast from '../../components/ux/Uxtoast'
import { isEmail } from "../../library/Core"
import sendQuery from '../../socket/socket'
import { useStore } from "../../store/useStore"

export default function Registration({


}) {

    const { state, dispatch } = useStore()

    const [login, setLogin] = useState(state.nickname ? state.nickname : "")
    const [password, setPassword] = useState("")
    const [strength, setStrength] = useState("")
    const [repassword, setRePassword] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState({ username: false, email: false })

    const doregister = useCallback(() => {

        if(login.length < 4) {
            Toast.add('Votre identifiant est trop court (3 caractères minimum)', 5000, 'error')
        }
        else if(!isEmail(email)) {
            Toast.add('Votre email n\'est pas valide', 5000, 'error')
        }
        else if(strength.length === 0) {
            Toast.add('Votre mot de passe est vide', 5000, 'error')
        }
        else if(strength.length < 6) {
            Toast.add('Votre mot de passe est trop court', 5000, 'error')
        }
        else if(!strength.digit) {
            Toast.add('Votre mot de passe n\'a pas de chiffre', 5000, 'error')
        }
        else if(!strength.upper) {
            Toast.add('Votre mot de passe n\'a pas de majuscule', 5000, 'error')
        }
        else if(!strength.nonWords) {
            Toast.add('Votre mot de passe n\'a pas de caractère spécial', 5000, 'error')
        }
        else if(password !== repassword) {
            Toast.add('Votre mot de passe est différent', 5000, 'error')
        }
        else {
            sendQuery([
                {
                    ressource: "user.register",
                    entry: "user",
                    query: {
                        login: login,
                        email: email,
                        password: sha256(password).toString()
                    }
                }
            ], datas => {
                setDataLogin(datas.user)
            }, err => {
                let tmp = {
                    email: false,
                    username: false
                }
                err.values.forEach(k => {
                    tmp[k] = true
                })

                setError(tmp)
            })
        }
    }, [email, login, password, repassword, strength])

    const setDataLogin = (datas) => {
        setPassword("")
        setStrength(passwordStrengthCalculation(""))
        //setLoginCount(0)
    }

    const passwordStrengthCalculation = useCallback((pwd) => {
        return {
            length: pwd.length,
            upper: /[A-Z]/.test(pwd),
            digit: /\d/.test(pwd),
            nonWords: /\W/.test(pwd)
        }
    }, [])

    const loginChange = useCallback((e) => {
        setLogin(e.target.value)
    }, [])

    const passwordChange = useCallback((e) => {
        setPassword(e.target.value)
        setStrength(passwordStrengthCalculation(e.target.value))
    }, [])

    const repasswordChange = useCallback((e) => {
        setRePassword(e.target.value)
    }, [])

    const emailChange = useCallback((e) => {
        setEmail(e.target.value)
    }, [])



    return (
        <>
            <input type="text" placeholder="Identifiant" onChange={ loginChange } onInput={ loginChange } className={ error.username ? 'field-error' : '' } />
            <input type="email" placeholder="Email" onChange={ emailChange } onInput={ emailChange } className={ error.email ? 'field-error' : '' } />
            <input type="password" placeholder="Mot de passe" onChange={ passwordChange } onInput={ passwordChange } />
            <div className="nav-register-opener-password">
                <span className={ strength.length >= 6 ? "success" : ""}>{ password.length > 6 ? 6 : password.length }/6 caractères</span>
                <span className={ strength.upper ? "success" : ""}>{ strength.upper ? 1 : 0 }/1 majuscule</span>
                <span className={ strength.digit ? "success" : ""}>{ strength.digit ? 1 : 0 }/1 chiffre</span>
                <span className={ strength.nonWords ? "success" : ""}>{ strength.nonWords ? 1 : 0 }/1 caractère spécial</span>
            </div>
            <input type="password" placeholder="Re Mot de passe" onChange={ repasswordChange } onInput={ repasswordChange } />
            <button onClick={ doregister }>Inscription</button>
        </>
    )
}

