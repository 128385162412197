export const ROLES = null

export const HEROS = {
    bangalore: { name: "bangalore" },
    bloodhound: { name: "bloodhound" },
    caustic: { name: "caustic" },
    crypto: { name: "crypto" },
    gibraltar: { name: "gibraltar" },
    lifeline: { name: "lifeline" },
    loba: { name: "loba" },
    mirage: { name: "mirage" },
    octane: { name: "octane" },
    pathfinder: { name: "pathfinder" },
    revenant: { name: "revenant" },
    wattson: { name: "wattson" },
    wraith: { name: "wraith" },
}