import React, { useEffect } from 'react'
import './socialPage.scss'

import { Row, Column } from '../../components/ux/grid'
import Input from '../../components/fields/Input'
import useForm from '../../components/fields/form'
import { useStore } from "../../store/useStore"
import sendQuery from "../../socket/socket"
import { updateUser, fetchUser } from "../../socket/actions"
import Toast from "../../components/ux/Uxtoast"

export default function AdmiterSocialPage(props) {

    const { state } = useStore()
    let schema = {
        socialtwitter: { value: '', error: '' },
        socialfacebook: { value: '', error: '' },
        socialinstagram: { value: '', error: '' },
        socialdiscord: { value: '', error: '' },
        videoyoutube: { value: '', error: '' },
        videovimeo: { value: '', error: '' },
        videodailymotion: { value: '', error: '' },
        streamingyoutube: { value: '', error: '' },
        streamingtwitch: { value: '', error: '' },
        streamingmixer: { value: '', error: '' },
        streamingfacebook: { value: '', error: '' }
    }

    const { state:formState, binding, modified, updateInitial, onSubmit } = useForm(
        schema,
        (datas) => {
            updateUser(state.id, {
                "social.twitter": datas.socialtwitter,
                "social.facebook": datas.socialfacebook,
                "social.instagram": datas.socialinstagram,
                "social.discord": datas.socialdiscord,
                "video.youtube": datas.videoyoutube,
                "video.vimeo": datas.videovimeo,
                "video.dailymotion": datas.videodailymotion,
                "streaming.youtube": datas.streamingyoutube,
                "streaming.twitch": datas.streamingtwitch,
                "streaming.mixer": datas.streamingmixer,
                "streaming.facebook": datas.streamingfacebook
            })
            .then(data => {
                Toast.add('Informations mise à jour', 3000, 'success')
            })
        }
    )

    useEffect(() => {
        fetchUser(state.id, {
            "id": "id",
            "social.twitter": "socialtwitter",
            "social.facebook": "socialfacebook",
            "social.instagram": "socialinstagram",
            "social.discord": "socialdiscord",
            "video.youtube": "videoyoutube",
            "video.vimeo": "videovimeo",
            "video.dailymotion": "videodailymotion",
            "streaming.youtube": "streamingyoutube",
            "streaming.twitch": "streamingtwitch",
            "streaming.mixer": "streamingmixer",
            "streaming.facebook": "streamingfacebook"
        })
        .then(datas => {
            updateInitial(datas)
        })
    }, [])


    return (
        <div className="admiter-social-page">
            <Row className="-margin">
                <div className="admiter-submitter">
                    { modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null }
                </div>
                <Column>
                    <h2>Réseaux sociaux</h2>
                    <hr className="space10" />
                </Column>
                <Column>
                    <h3>Vos réseaux</h3>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 300.00006 244.18703">
                            <g transform="translate(-539.17946,-568.85777)">
                                <path style={ {fill: "#1da1f2", stroke:"none" } }
                                d="m 633.89823,812.04479 c 112.46038,0 173.95627,-93.16765 173.95627,-173.95625 0,-2.64628 -0.0539,-5.28062 -0.1726,-7.90305 11.93799,-8.63016 22.31446,-19.39999 30.49762,-31.65984 -10.95459,4.86937 -22.74358,8.14741 -35.11071,9.62551 12.62341,-7.56929 22.31446,-19.54304 26.88583,-33.81739 -11.81284,7.00307 -24.89517,12.09297 -38.82383,14.84055 -11.15723,-11.88436 -27.04079,-19.31655 -44.62892,-19.31655 -33.76374,0 -61.14426,27.38052 -61.14426,61.13233 0,4.79784 0.5364,9.46458 1.58538,13.94057 -50.81546,-2.55686 -95.87353,-26.88582 -126.02546,-63.87991 -5.25082,9.03545 -8.27852,19.53111 -8.27852,30.73006 0,21.21186 10.79366,39.93837 27.20766,50.89296 -10.03077,-0.30992 -19.45363,-3.06348 -27.69044,-7.64676 -0.009,0.25652 -0.009,0.50661 -0.009,0.78077 0,29.60957 21.07478,54.3319 49.0513,59.93435 -5.13757,1.40062 -10.54335,2.15158 -16.12196,2.15158 -3.93364,0 -7.76596,-0.38716 -11.49099,-1.1026 7.78383,24.2932 30.35457,41.97073 57.11525,42.46543 -20.92578,16.40207 -47.28712,26.17062 -75.93712,26.17062 -4.92898,0 -9.79834,-0.28036 -14.58427,-0.84634 27.05868,17.34379 59.18936,27.46396 93.72193,27.46396" />
                            </g>
                        </svg>
                        Twitter
                        <Input type="text" prefix="https://twitter.com/" className="social-field" name="socialtwitter" value={ formState.socialtwitter.value } { ...binding } isModified={ formState.socialtwitter.isModified }/>
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 36 36">
                            <rect fill="#3B5998" width="36" height="36"/>
                            <path fill="#FFFFFF" d="M30.895,16.535l-0.553,5.23h-4.181v15.176h-6.28V21.766H16.75v-5.23h3.131v-3.149
                                c0-4.254,1.768-6.796,6.796-6.796h4.181v5.23h-2.615c-1.952,0-2.081,0.736-2.081,2.1v2.615H30.895z"/>
                        </svg>
                        Facebook
                        <Input type="text" prefix="https://www.facebook.com/" className="social-field" name="socialfacebook" value={ formState.socialfacebook.value } { ...binding } isModified={ formState.socialfacebook.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 128 128">
                            <defs>
                                <linearGradient id="b">
                                    <stop offset="0" stopColor="#3771c8"/>
                                    <stop stopColor="#3771c8" offset=".128"/>
                                    <stop offset="1" stopColor="#60f" stopOpacity="0"/>
                                </linearGradient>
                                <linearGradient id="a">
                                    <stop offset="0" stopColor="#fd5"/>
                                    <stop offset=".1" stopColor="#fd5"/>
                                    <stop offset=".5" stopColor="#ff543e"/>
                                    <stop offset="1" stopColor="#c837ab"/>
                                </linearGradient>
                                <radialGradient id="c" cx="158.429" cy="578.088" r="65" href="#a" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)" fx="158.429" fy="578.088"/>
                                <radialGradient id="d" cx="147.694" cy="473.455" r="65" href="#b" gradientUnits="userSpaceOnUse" gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)" fx="147.694" fy="473.455"/>
                            </defs>
                            <path fill="url(#c)" d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z" transform="translate(1.004 1)"/>
                            <path fill="url(#d)" d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z" transform="translate(1.004 1)"/>
                            <path fill="#fff" d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"/>
                        </svg>
                        Instagram
                        <Input type="text" prefix="https://www.instagram.com/" className="social-field" name="socialinstagram" value={ formState.socialinstagram.value } { ...binding } isModified={ formState.socialinstagram.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 245 240">
                            <path fill="#7289DA" d="M104.4,103.9c-5.7,0-10.2,5-10.2,11.1s4.6,11.1,10.2,11.1c5.7,0,10.2-5,10.2-11.1
                                C114.7,108.9,110.1,103.9,104.4,103.9z M140.9,103.9c-5.7,0-10.2,5-10.2,11.1s4.6,11.1,10.2,11.1c5.7,0,10.2-5,10.2-11.1
                                S146.6,103.9,140.9,103.9z"/>
                            <path fill="#7289DA" d="M189.5,20h-134C44.2,20,35,29.2,35,40.6v135.2c0,11.4,9.2,20.6,20.5,20.6h113.4l-5.3-18.5l12.8,11.9l12.1,11.2
                                l21.5,19V40.6C210,29.2,200.8,20,189.5,20z M150.9,150.6c0,0-3.6-4.3-6.6-8.1c13.1-3.7,18.1-11.9,18.1-11.9c-4.1,2.7-8,4.6-11.5,5.9
                                c-5,2.1-9.8,3.5-14.5,4.3c-9.6,1.8-18.4,1.3-25.9-0.1c-5.7-1.1-10.6-2.7-14.7-4.3c-2.3-0.9-4.8-2-7.3-3.4c-0.3-0.2-0.6-0.3-0.9-0.5
                                c-0.2-0.1-0.3-0.2-0.4-0.3c-1.8-1-2.8-1.7-2.8-1.7s4.8,8,17.5,11.8c-3,3.8-6.7,8.3-6.7,8.3c-22.1-0.7-30.5-15.2-30.5-15.2
                                c0-32.2,14.4-58.3,14.4-58.3c14.4-10.8,28.1-10.5,28.1-10.5l1,1.2c-18,5.2-26.3,13.1-26.3,13.1s2.2-1.2,5.9-2.9
                                c10.7-4.7,19.2-6,22.7-6.3c0.6-0.1,1.1-0.2,1.7-0.2c6.1-0.8,13-1,20.2-0.2c9.5,1.1,19.7,3.9,30.1,9.6c0,0-7.9-7.5-24.9-12.7l1.4-1.6
                                c0,0,13.7-0.3,28.1,10.5c0,0,14.4,26.1,14.4,58.3C181.5,135.4,173,149.9,150.9,150.6z"/>
                        </svg>
                        Discord
                        <Input type="text" prefix="https://discord.gg/" className="social-field" name="socialdiscord" value={ formState.socialdiscord.value } { ...binding } isModified={ formState.socialdiscord.isModified } />
                    </label>
                </Column>
            </Row>
            <h3>Vos chaînes</h3>
            <Row className="-margin">
                <Column>
                    <label>
                        <svg viewBox="0 0 71.412065 50">
                            <g transform="scale(0.58823529,0.58823529)">
                                <path  d="M 118.9,13.3 C 117.5,8.1 113.4,4 108.2,2.6 98.7,0 60.7,0 60.7,0 60.7,0 22.7,0 13.2,2.5 8.1,3.9 3.9,8.1 2.5,13.3 0,22.8 0,42.5 0,42.5 0,42.5 0,62.3 2.5,71.7 3.9,76.9 8,81 13.2,82.4 22.8,85 60.7,85 60.7,85 c 0,0 38,0 47.5,-2.5 5.2,-1.4 9.3,-5.5 10.7,-10.7 2.5,-9.5 2.5,-29.2 2.5,-29.2 0,0 0.1,-19.8 -2.5,-29.3 z" fill="#ff0000" />
                                <polygon points="80.2,42.5 48.6,24.3 48.6,60.7 "fill="#ffffff" />
                            </g>
                        </svg>
                        Youtube
                        <Input type="text" prefix="https:/www.youtube.com/channel/" className="social-field" name="videoyoutube" value={ formState.videoyoutube.value } { ...binding } isModified={ formState.videoyoutube.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 48 48">
                            <path d="M45 14.3c-.2 4.098-3 9.598-8.602 16.598-5.699 7.403-10.5 11-14.5 11-2.398 0-4.5-2.199-6.199-6.699-1.097-4.097-2.3-8.199-3.398-12.3-1.301-4.5-2.602-6.7-4-6.7C8 16.2 6.898 16.7 5 18l-2-2.3c2.102-1.802 4-3.098 6-4.9 2.8-2.402 4.8-3.6 6.2-3.698 3.3-.301 5.3 1.898 6 6.597.8 5.102 1.5 7.801 1.8 9 .898 4.2 2 6.403 3.102 6.403.898 0 2.199-1.403 4-4.102 1.796-2.8 2.699-4.8 2.796-6.3.301-2.4-.699-3.598-2.796-3.598-1 0-2.102.5-3.204.898C29 9.3 33 5.8 38.801 6c4.398.102 6.398 2.898 6.199 8.3z" fill="#29B6F6" />
                        </svg>
                        Viméo
                        <Input type="text" prefix="https://vimeo.com/" className="social-field" name="videovimeo" value={ formState.videovimeo.value } { ...binding } isModified={ formState.videovimeo.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 128 128">
                            <path d="M75.086,60.339    c-9.352,0-16.585,7.607-16.585,16.585c0,9.352,7.232,16.46,17.333,16.46c8.479,0,15.587-6.983,15.587-16.211    C91.421,67.571,84.313,60.339,75.086,60.339z M110.375,110.841H91.296v-7.482c-5.861,5.736-11.846,7.856-19.827,7.856    c-8.105,0-15.088-2.619-20.949-7.856c-7.731-6.858-11.722-15.837-11.722-26.311c0-9.602,3.741-18.206,10.724-24.94    c6.235-6.11,13.842-9.228,22.321-9.228c8.105,0,14.34,2.743,18.829,8.479V22.181l19.702-4.082V110.841z M0,128h128V0H0V128z" fill="#0066DC" />
                        </svg>
                        Dailymotion
                        <Input type="text" prefix="https://www.dailymotion.com/" className="social-field" name="videodailymotion" value={ formState.videodailymotion.value } { ...binding } isModified={ formState.videodailymotion.isModified } />
                    </label>
                </Column>
            </Row>
            <h3>Vos services de streaming</h3>
            <Row className="-margin">
                <Column>
                    <label>
                        <svg viewBox="0 0 71.412065 50">
                            <g transform="scale(0.58823529,0.58823529)">
                                <path  d="M 118.9,13.3 C 117.5,8.1 113.4,4 108.2,2.6 98.7,0 60.7,0 60.7,0 60.7,0 22.7,0 13.2,2.5 8.1,3.9 3.9,8.1 2.5,13.3 0,22.8 0,42.5 0,42.5 0,42.5 0,62.3 2.5,71.7 3.9,76.9 8,81 13.2,82.4 22.8,85 60.7,85 60.7,85 c 0,0 38,0 47.5,-2.5 5.2,-1.4 9.3,-5.5 10.7,-10.7 2.5,-9.5 2.5,-29.2 2.5,-29.2 0,0 0.1,-19.8 -2.5,-29.3 z" fill="#ff0000" />
                                <polygon points="80.2,42.5 48.6,24.3 48.6,60.7 "fill="#ffffff" />
                            </g>
                        </svg>
                        Youtube
                        <Input type="text" prefix="https://www.youtube.com/embed/live_stream/?channel=" className="social-field" name="streamingyoutube" value={ formState.streamingyoutube.value } { ...binding } isModified={ formState.streamingyoutube.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 448 512">
                            <path fill="#613fa0" d="M40.1 32L10 108.9v314.3h107V480h60.2l56.8-56.8h87l117-117V32H40.1zm357.8 254.1L331 353H224l-56.8 56.8V353H76.9V72.1h321v214zM331 149v116.9h-40.1V149H331zm-107 0v116.9h-40.1V149H224z"/>
                        </svg>
                        Twitch
                        <Input type="text" prefix="https://www.twitch.tv/" className="social-field" name="streamingtwitch" value={ formState.streamingtwitch.value } { ...binding } isModified={ formState.streamingtwitch.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 512 512">
                            <path fill="#03214F" d="M116.03,77.68c-15.76-21.29-46.72-24.61-66.91-6.36c-17.42,16.04-18.8,43.13-4.7,62.21l90.96,121.92
                                L43.87,378.48c-14.1,19.08-12.99,46.17,4.7,62.21c20.18,18.25,51.15,14.93,66.91-6.36l127.73-171.69c3.04-4.15,3.04-9.95,0-14.1
                                L116.03,77.68z"/>
                            <path fill="#1FBAED" d="M396.37,77.68c15.76-21.29,46.72-24.61,66.91-6.36c17.42,16.04,18.8,43.13,4.7,62.21l-90.96,121.92
                                l91.51,123.03c14.1,19.08,12.99,46.17-4.7,62.21c-20.18,18.25-51.15,14.93-66.91-6.36L269.47,262.36c-3.04-4.15-3.04-9.95,0-14.1
                                L396.37,77.68z"/>
                        </svg>
                        Mixer
                        <Input type="text" prefix="https://mixer.com/" className="social-field" name="streamingmixer" value={ formState.streamingmixer.value } { ...binding } isModified={ formState.streamingmixer.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        <svg viewBox="0 0 36 36">
                            <rect fill="#3B5998" width="36" height="36"/>
                            <path fill="#FFFFFF" d="M30.895,16.535l-0.553,5.23h-4.181v15.176h-6.28V21.766H16.75v-5.23h3.131v-3.149
                                c0-4.254,1.768-6.796,6.796-6.796h4.181v5.23h-2.615c-1.952,0-2.081,0.736-2.081,2.1v2.615H30.895z"/>
                        </svg>
                        Facebook
                        <Input type="text" prefix="https://www.facebook.com/facebook/videos/" className="social-field"name="streamingfacebook" value={ formState.streamingfacebook.value } { ...binding } isModified={ formState.streamingfacebook.isModified } />
                    </label>
                </Column>
            </Row>
        </div>
    )
}