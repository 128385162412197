import React, { useEffect } from 'react'
import { CirclePicker } from 'react-color';

import { getUrl } from '../../../library/Core'

import { Row, Column } from '../../../components/ux/grid'
import { Uxtablerow, Uxtableheader } from '../../../components/ux/Uxtable'
import Input from '../../../components/fields/Input'
import useForm from '../../../components/fields/form'
import Invitecode from '../../../components/ux/invitecode'
import Memberlist from '../../../components/wrapper/memberlist'
import { useStore } from "../../../store/useStore"
import Toast from "../../../components/ux/Uxtoast"

export default function AdmiterSubTeamPage(props) {

    const urls = getUrl()

    return (
        <Column>
            <Row className="full">
                <Column large="6">
                    <button className="small">Ajouter une équipe</button>
                </Column>
                <Column large="6">
                    <button className="small">Rejoindre une équipe</button>
                </Column>
            </Row>
            <hr className="space10" />
            <h3>Gestion des équipes</h3>
            <Uxtableheader>
                <div className="table-structure-game">
                    Jeu
                </div>
                <div className="table-structure-logo">
                    Logo
                </div>
                <div className="table-structure-name">
                    Nom
                </div>
                <div className="table-structure-ladder">
                    Ladder
                </div>
                <div className="table-structure-player">
                    Joueurs
                </div>
                <div className="table-structure-actions">

                </div>
            </Uxtableheader>
            <Uxtablerow>
                <div className="table-structure-game">
                    <img src={ urls.icon + "overwatch.png" } alt="Overwatch" />
                </div>
                <div className="table-structure-logo">
                    <img src="/assets/teams/tribe.png" alt="Tribe Amaze" />
                </div>
                <div className="table-structure-name">
                    Amaze
                </div>
                <div className="table-structure-ladder">
                    6vs6
                </div>
                <div className="table-structure-player">
                    8
                </div>
                <div className="table-structure-actions">

                </div>
            </Uxtablerow>
            <Uxtablerow>
                <div className="table-structure-game">
                    <img src={ urls.icon + "overwatch.png" } alt="Overwatch" />
                </div>
                <div className="table-structure-logo">
                    <img src="/assets/teams/tribe.png" alt="Tribe Btrayed" />
                </div>
                <div className="table-structure-name">
                    Btrayed
                </div>
                <div className="table-structure-ladder">
                    6vs6
                </div>
                <div className="table-structure-player">
                    9
                </div>
                <div className="table-structure-actions">

                </div>
            </Uxtablerow>
            <Uxtablerow>
                <div className="table-structure-game">
                    <img src={ urls.icon + "valorant.png" } alt="Valorant" />
                </div>
                <div className="table-structure-logo">
                    <img src="/assets/teams/tribe.png" alt="Tribe Btrayed" />
                </div>
                <div className="table-structure-name">
                    Headshot
                </div>
                <div className="table-structure-ladder">
                    lucio surf
                </div>
                <div className="table-structure-player">
                    8
                </div>
                <div className="table-structure-actions">

                </div>
            </Uxtablerow>
        </Column>
    )

}