import React from 'react'
import { NavLink } from 'react-router-dom'
import './teamPage.scss'

import { Row, Column } from '../components/ux/grid'
import { useStore } from "../store/useStore"
import { Box, BoxHeader } from "../components/ux/box"
import Profile from '../components/ux/player/profile'

import { Uxtablerow, Uxtableheader } from '../components/ux/Uxtable'
import UxMatch from '../components/ux/match/UxMatch'

import Icon from '../project/icon'

export default function TeamPage(props) {

    const { state, dispatch } = useStore()
    const teamId = props.match.params.teamId

    const style = {
        backgroundImage: "url(/assets/hero/tribe.jpg)"
    }

    const backgroundColor = {
        background: "#112C42",
        color: "#fff"
    }

    const xpbar = { ...backgroundColor, width: "78%" }

    return (
        <>
            <div className="team-page">
                <Row>
                    <Column>
                        <div className="team-page-hero" style={ style }>
                            <h1 style={ backgroundColor }><img src="/assets/teams/tribe.png" alt="Tribe Amaze"/> Amaze</h1>
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column large="3">
                        <Box className="team-informations">
                            <BoxHeader>Informations</BoxHeader>
                            <Uxtablerow>
                                <div className="table-label">
                                    Niveau 34
                                </div>
                                <div className="table-information">
                                    <div className="team-page-hero-experience"><div style={ xpbar }></div></div>
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-label">
                                    Création
                                </div>
                                <div className="table-information">
                                    12/11/2019
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-label">
                                    Joueurs
                                </div>
                                <div className="table-information">
                                    7
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-label">
                                    Ladder
                                </div>
                                <div className="table-information">
                                    6vs6
                                </div>
                            </Uxtablerow>
                        </Box>
                    </Column>
                    <Column large="6">
                        <Row>
                            <Column large="6">
                                <Box className="team-result">
                                    <BoxHeader>Derniers résultats</BoxHeader>
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        result={ [3, 3] }
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        icon1="/assets/teams/tribe.png"
                                        result={ [5, 4] }
                                        team2="Atlanta reign"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        result={ [3, 3] }
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        result={ [3, 3] }
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        result={ [3, 3] }
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                </Box>
                            </Column>
                            <Column large="6">
                                <Box className="team-result">
                                    <BoxHeader>Matchs à venir</BoxHeader>
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        icon1="/assets/teams/tribe.png"
                                        team2="Atlanta reign"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/60/609VVM89F1BY1542673347592.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                    <UxMatch
                                        team1="Amaze"
                                        team2="Dallas Fuel"
                                        icon1="/assets/teams/tribe.png"
                                        icon2="https://bnetcmsus-a.akamaihd.net/cms/page_media/LLMV1UTBVHN11544055825034.svg"
                                    />
                                </Box>
                            </Column>
                        </Row>
                    </Column>
                    <Column large="3">
                        <Box className="team-roster">
                            <BoxHeader>Liste des joueurs actifs</BoxHeader>
                            <Uxtableheader>
                                <div className="table-role">
                                    Rôle
                                </div>
                                <div className="table-player">
                                    Joueur
                                </div>
                                <div className="table-date">
                                    Depuis
                                </div>
                            </Uxtableheader>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="tank" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/pipoune" }>Pipoune</NavLink>
                                </div>
                                <div className="table-date">
                                    01/12/2019
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="tank" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/khraine-0001" }>Khraine</NavLink>
                                </div>
                                <div className="table-date">
                                    01/12/2019
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="damage" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/rykosd-0002" }>Rykos</NavLink>
                                </div>
                                <div className="table-date">
                                    01/12/2019
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="damage" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/ghostraid" }>Ghostraid</NavLink>
                                </div>
                                <div className="table-date">
                                    01/12/2019
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="damage" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/darcksiide" }>DarckSiide</NavLink>
                                </div>
                                <div className="table-date">
                                    01/12/2019
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="support" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/richnews" }>Richnews</NavLink>
                                </div>
                                <div className="table-date">
                                    01/12/2019
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="support" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/wilco" }>Wilco</NavLink>
                                </div>
                                <div className="table-date">
                                    01/12/2019
                                </div>
                            </Uxtablerow>
                        </Box>

                        <Box className="team-roster-inactive">
                            <BoxHeader>Liste des joueurs inactifs</BoxHeader>
                            <Uxtableheader>
                                <div className="table-role">
                                    Rôle
                                </div>
                                <div className="table-player">
                                    Joueur
                                </div>
                                <div className="table-date">
                                    Depuis
                                </div>
                            </Uxtableheader>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="tank" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/toto" }>Toto</NavLink>
                                </div>
                                <div className="table-date">
                                    01/02/2020
                                </div>
                            </Uxtablerow>
                            <Uxtablerow>
                                <div className="table-role">
                                    <Icon gameId="overwatch" icon="support" />
                                </div>
                                <div className="table-player">
                                    <NavLink to={ "/player/kaielia" }>Kaielia</NavLink>
                                </div>
                                <div className="table-date">
                                    27/04/2020
                                </div>
                            </Uxtablerow>
                        </Box>
                    </Column>
                </Row>
            </div>
        </>
    )
}