import React from 'react'

import { Row, Column } from '../../components/ux/grid'
import { useStore } from "../../store/useStore"

export default function AdmiterDevPage(props) {


    return (
        <div className="admiter-dev-page">
            <Row className="-margin">
                <div className="admiter-submitter">
                    { /*modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null*/ }
                </div>
                <Column>
                    <h2>Dévéloppeur</h2>
                    <hr className="space10" />
                </Column>
            </Row>
        </div>
    )
}