export const ROLES = {
    tank: "tank",
    dps: "dps",
    support: "support"
}

export const HEROS = {
    ana: { role: ["support"], name: "ana" },
    mercy: { role: ["support"], name: "ange" },
    ashe: { role: ["dps"], name: "ashe" },
    baptiste: { role: ["support"], name: "baptiste" },
    bastion: { role: ["dps"], name: "bastion" },
    bouldozer: { role: ["tank"], name: "bouldozer" },
    brigitte: { role: ["support"], name: "brigitte" },
    hanzo: { role: ["dps"], name: "hanzo" },
    junkrat: { role: ["dps"], name: "chacal" },
    roadhog: { role: ["tank"], name: "chopper" },
    dva: { role: ["tank"], name: "dva" },
    doomfist: { role: ["dps"], name: "doomfist" },
    echo: { role: ["dps"], name: "echo" },
    widowmaker: { role: ["dps"], name: "fatale" },
    reaper: { role: ["dps"], name: "faucheur" },
    genji: { role: ["dps"], name: "genji" },
    lucio: { role: ["support"], name: "lucio" },
    mccree: { role: ["dps"], name: "mccree" },
    mei: { role: ["dps"], name: "mei" },
    moira: { role: ["support"], name: "moira" },
    orisa: { role: ["tank"], name: "orisa" },
    phara: { role: ["dps"], name: "phara" },
    reinhardt: { role: ["tank"], name: "reinhardt" },
    sigma: { role: ["tank"], name: "sigma" },
    soldier76: { role: ["dps"], name: "soldat: 76" },
    sombra: { role: ["dps"], name: "sombra" },
    symmetra: { role: ["dps"], name: "symmetra" },
    torbjorn: { role: ["dps"], name: "torbjorn" },
    tracer: { role: ["dps"], name: "tracer" },
    winston: { role: ["tank"], name: "winston" },
    zarya: { role: ["tank"], name: "zarya" },
    zenyatta: { role: ["support"], name: "zenyatta" },
}