import React, { useEffect } from 'react'
import { useSocket } from "./socket"
import { useStore } from "../store/useStore"

export default function SocketPush(props) {

    const { state, dispatch } = useStore()

    let socket = useSocket()

    useEffect(() => {

        let to = []
        socket.on('connect', () => {
            dispatch({
                type: "setOnline",
                payload: true
            })

            if(to) {
                to.forEach(t => clearTimeout(t))
                to = []
            }
        })

        socket.on('reconnect', (reason) => {
            console.log('reconnect')
        })

        socket.on('reconnecting', (reason) => {
            console.log('reconnecting')
        })

        socket.on('disconnect', (reason) => {
            console.log('disconnect', reason)
        })

        socket.on('reconnect_attempt', (reason) => {
            console.log('reconnect_attempt', reason)

            if(reason === 2 ) {
                dispatch({
                    type: "setOnline",
                    payload: false
                })
            }
        })

    }, [])

    useEffect(() => {

        socket.on('connect_error', (error) => {
            console.log('connect_error', error)
        })

        socket.on('connect_timeout', (timeout) => {
            console.log('connect_timeout', timeout)
        })

        socket.on('error', (error) => {
            console.log('error', error)
        })

        

        socket.on('reconnect_error', (reason) => {
            console.log('reconnect_error', reason)
        })

        socket.on('reconnect_failed', (reason) => {
            console.log('reconnect_failed', reason)
        })

        /*
        socket.on('ping', () => {
            console.log('ping')
        })

        socket.on('pong', () => {
            console.log('pong')
        })
        */

        socket.on('results', datas => {
            dispatch({
                type: "setLadderResults",
                payload: datas.results
            })
        })

    }, [])

    return (<></>)
}