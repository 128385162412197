import React, { useState, useCallback } from 'react'
import { CirclePicker } from 'react-color';

import { Row, Column } from '../../../components/ux/grid'
import Input from '../../../components/fields/Input'
import useForm from '../../../components/fields/form'
import Invitecode from '../../../components/ux/invitecode'
import Memberlist from '../../../components/wrapper/memberlist'
import BannerChosen from '../../../components/ux/player/bannerChosen'
import { useStore } from "../../../store/useStore"
import sendQuery from "../../../socket/socket"
import Toast from "../../../components/ux/Uxtoast"
import { getUrl } from '../../../library/Core'

export default function AdmiterSubStructurePage({
    section: __section
}) {

    const { state } = useStore()
    const [banner, setBanner] = useState(null)

    let schema = {
        structurecolor: { value: '', error: '' },
        structurename: { value: '', error: '' },
        structurecreation: { value: '', error: '' },
        structuredescription: { value: '', error: '' },
        structuretrigram: { value: '', error: '', validator: { regExp: /^([\w\d]{3})$/g, message:"Format invalide (3 lettres)" } },
    }

    const { state:formState, binding, modified, updateInitial, onSubmit } = useForm(
        schema,
        (datas) => {

        }
    )

    const urls = getUrl()

    let _banner = './assets/default-banner.jpg'
    if(state.setting.banner) {
        _banner = urls.banner + state.setting.banner + '.jpg'
    }

    const changeBanner = useCallback(value => {
        setBanner(value)

        /*
        if(value !== state.setting.banner) {
            setModified(true)
        }
        */
    }, [])

    const members = [
        { id: "toto", username: "Khraine", role: "owner" },
        { id: "titi", username: "RykosD", role: "manager" },
        { id: "tutu", username: "Corptisier", role: "manager" },
        { id: "tata", username: "Maduco", role: "coach" },
        { id: "lala", username: "Infoguy", role: "designer" },
        { id: "lalaz", username: "Editor", role: "videoeditor" },
        { id: "kiki", username: "LoLDude", role: "aide" }
    ]

    const inviteUrl = urls.base + 'invite/'

    return (
        <Column>
        {   __section === 'manage' &&
            <Row>
                <Column>
                    <BannerChosen src={ _banner } width={ 1500 } height={ 500 } onChange={ changeBanner } />
                </Column>
                <Column large="6">
                    <h3>Structure</h3>
                    <label>
                        Code d'invitation à la structure
                        <Invitecode prefix="s" refresh={ (key) => { console.log(key) } } url={ inviteUrl } />
                    </label>
                    <label>
                        Choix de la couleur de structure
                        <CirclePicker width="100%" color={ formState.structurecolor.value } onChangeComplete={ color => { binding.onChange({target: {name: 'structurecolor', value: color.hex }})} } />
                    </label>
                    <label>
                        Nom
                        <Input name="structurename" value={ formState.structurename.value } type="text" { ...binding } isModified={ formState.structurename.isModified } />
                    </label>
                    <label>
                        Date de création
                        <Input name="structurecreation" value={ formState.structurecreation.value } type="date" { ...binding } isModified={ formState.structurecreation.isModified } />
                    </label>
                    <label>
                        Description
                        <Input name="structuredescription" value={ formState.structuredescription.value } type="textarea"  { ...binding } isModified={ formState.structuredescription.isModified } />
                    </label>
                </Column>
                <Column large="6">
                    <h3>Membres</h3>
                    <Memberlist members={ members } />
                </Column>
                <Column large="6">
                    <h3>Portefeuille</h3>
                </Column>
            </Row>
        }
        {   __section === 'creation' &&
            <Row>
                <Column>
                    <h3>Création d'une structure d'accompagnement</h3>
                </Column>
                <Column>
                    <BannerChosen src={ _banner } width={ 1500 } height={ 500 } type="jpeg" onChange={ changeBanner } />
                </Column>
                <Column large="6">
                    <label>
                        Code d'invitation à la structure
                        <Invitecode prefix="s" url={ inviteUrl } />
                    </label>
                    <label>
                        Choix de la couleur de structure
                        <CirclePicker width="100%" />
                    </label>
                </Column>
                <Column large="6">
                    <label>
                        Nom
                        <Input name="structurename" value={ formState.structurename.value } type="text" { ...binding } isModified={ formState.structurename.isModified } />
                    </label>
                    <label>
                        Date de création
                        <Input name="structurecreation" value={ formState.structurecreation.value } type="date" { ...binding } isModified={ formState.structurecreation.isModified } />
                    </label>
                    <label>
                        Description
                        <Input name="structuredescription" value={ formState.structuredescription.value } type="textarea"  { ...binding } isModified={ formState.structuredescription.isModified } />
                    </label>
                </Column>
            </Row>
        }
        </Column>
    )

}