import React, { useState, Fragment, useCallback } from 'react'
import './sentence.scss'
import { toggleValueInArray, inArray, isFunction, notEmpty } from '../../library/Core'
import { useEffect } from 'react'

export function SentenceFilterValue(props) {
    return (
        <li className={ props.multiple && inArray(props.value, props.getter[props.field]) ? 'selected' : '' } onClick={ e => { props.onChange(props.children, props.value) } }>{ props.children }</li>
    )
}

export function SentenceFilterOptional(props) {

    const [active, setActive] = useState(true)

    const toggle = useCallback(() => {
        setActive(!active)
        props.onChange(props.field, active)
    }, [active])

    return (
        <div className={ "sentence-filter-optionnal-item" + (active ? '' : ' active') } onClick={ toggle }>{ props.children }</div>
    )
}

export function SentenceFilter(props) {

    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(props.default)
    const [label, setLabel] = useState(null)

    useEffect(() => {
        let _label = isFunction(props.label) ? props.label(props.getter) : props.label

        if(!_label || _label === undefined) {
            _label = 'error %filter%'
        }

        _label = _label.split('%filter%').map( (e, i) => {
            if(!e) {
                return <span key={ i }>{ value }</span>
            }

            return <Fragment key={ i }>{ e }</Fragment>
        })

        setLabel(_label)
    }, [props.getter])

    const toggleOpen = useCallback(() => {
        setOpen(!open)
    }, [open])

    let id = null
    const mouseEnter = () => {
        if(id) clearTimeout(id)
    }

    const mouseLeave = () => {
        id = setTimeout(() => {
            setOpen(false)
        }, 2000)
    }

    const onChange = useCallback((l, v) => {
        let tmp = null
        if(l instanceof Array && isFunction(props.delegate)) {
            setValue(l.join(', ') || props.default)
        }
        else if(props.rules.multiple) {
            v = toggleValueInArray(v, props.getter[props.field])
            l = toggleValueInArray(l, props.rules.label)
            setValue(l.join(', ') || props.default)
        }
        else {
            toggleOpen()
            setValue(l || props.default)
        }

        props.onChange(l, v)
    }, [props.getter])

    let children = null
    if(isFunction(props.delegate)) {
        children = props.delegate((values, labels) => {
            setOpen(false)
            onChange(labels, values)
        }, open, props.getter)
    }
    else {
        const _children = React.Children.map(props.children, child => {
            const tmp = {
                onChange: onChange,
                getter: props.getter,
                field: props.field,
                multiple: props.rules.multiple
            }

            return React.cloneElement(child, tmp)
        }).filter(c => {
            return !c.props.condition || c.props.condition(props.getter)
        })

        children = (
            <div className="sentence-values">
                <ul className={ props.rules.multiple ? 'multiple' : '' }>
                    { _children }
                </ul>
            </div>
        )
    }

    return (
        <div className={ "sentence-filter" + (open ? ' open' : '') } onMouseEnter={ mouseEnter } onMouseLeave={ mouseLeave }>
            <div className="sentence-label" onClick={ toggleOpen }>{ label }</div>
            { children }
        </div>
    )
}

export const notThis = (ref, value) => {
    return ref !== value
}

export const isThis = (ref, value) => {
    return ref === value
}

export function Sentence(props) {

    let tmp = { }
    let _optional = {}
    let _values = {}

    const optionalVisible = useCallback((field, value) => {
        let tmp = { ...rules }
        tmp[field].optional.visible = value
        setRules(tmp)
    }, [])

    Object.keys(props.rules).forEach(r => {
        const rule = props.rules[r]
        tmp[r] = {
            ...props.rules[r],
            condition: isFunction(rule.condition) ? rule.condition : () => true,
            multiple: rule.multiple || false,
            label: rule.multiple ? [] : null,
        }

        _values[r] = rule.multiple ? [] : null

        if(rule.optional && rule.optional.label) {
            tmp[r].optional.visible = false
            _optional[r] = <SentenceFilterOptional key={ r } field={ r } onChange={ (field, value) => { optionalVisible(field, value) } }>{ rule.optional.label }</SentenceFilterOptional>
        }
    })

    const [rules, setRules] = useState(tmp)
    const [optional] = useState(_optional)
    const [values, setValues] = useState(_values)

    const onChangeValidator = useCallback((values) => {
        if(isFunction(props.onChange)) {

            let tmp = {}
            Object.keys(props.rules).forEach(r => {
                const rule = props.rules[r]

                if((!rule.condition || rule.condition(values)) && notEmpty(values[r]) ) {
                    tmp[r] = values[r]
                }
            })

            props.onChange(tmp)
        }
    }, [])

    const children = React.Children.map(props.children, child => {

        let tmp = {
            onChange: (label, value) => {
                const field = child.props.field
                let _values = { ...values }
                let _rules = { ...rules }

                _values[field] = value
                _rules[field].label = label

                setRules(_rules)
                setValues(_values)
                onChangeValidator(_values)
            },
            rules: rules[child.props.field],
            getter: values,
            key: child.props.field
        }

        return React.cloneElement(child, tmp)
    })

    return (
        <div className="sentence">
            <div className="sentence-filter-optional">
                {
                    Object.keys(optional)
                    .filter(o => rules[o].condition(values))
                    .map(o => optional[o] )
                }
            </div>
            <div className="sentence-filters">
                {
                    children.filter(c => rules[c.props.field].condition(values) && ( !rules[c.props.field].optional || rules[c.props.field].optional.visible)  )
                }
            </div>
        </div>
    )
}