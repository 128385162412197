import React, { useState, useCallback } from 'react'

import "./favorite.scss"

import Checkbox from '../../components/ux/checkbox'

export default function Favorite({
    checked: __checked,
    onChange: __onChange
}) {

    const [checked, setChecked] = useState(__checked || false)

    const toggle = useCallback((value) => {
        setChecked(value)
        __onChange(value)
    }, [__onChange])


    return (
        <div className="ux-favorite">
            <Checkbox checked={ checked } onChange={ toggle }>
                <svg viewBox="0 0 540 540">
                    <path style={ { "transform": "translate(15px, 15px)" } } d="M255,489.6l-35.7-35.7C86.7,336.6,0,257.55,0,160.65C0,81.6,61.2,20.4,140.25,20.4c43.35,0,86.7,20.4,114.75,53.55
                        C283.05,40.8,326.4,20.4,369.75,20.4C448.8,20.4,510,81.6,510,160.65c0,96.9-86.7,175.95-219.3,293.25L255,489.6z"/>
                </svg>
            </Checkbox>
        </div>
    )
}