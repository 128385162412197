import React  from 'react'
import './Input.scss'

class Input extends React.Component {

  constructor(props) {
    super(props)

    let type = 'text'
    if(props.type === 'iban') {
      type = 'text'
    }
    else {
      type = props.type
    }

    let placeholder = props.placeholder || null
    if(props.type === 'date') {
      placeholder = '__/__/____'
    }

    let checked = props.checked || false
    if(this.props.form) {
      checked = this.props.value === this.props.form[this.props.name].value
    }

    this.state = {
      type: type,
      placeholder: placeholder,
      required: props.required || null,
      prefix: props.prefix || null,
      suffix: props.suffix || null,
      step: props.step || 1,
      focused: false,
      checked: checked
    }

    this.handleChange = this.handleChange.bind(this)
    this.focused = this.focused.bind(this)
    this.toggleChecked = this.toggleChecked.bind(this)
  }

  handleChange(event) {
    if(typeof this.props.onInput === 'function') {
      this.props.onInput(event)
    }
  }

  focused(event) {
    this.setState({ focused: event })
  }

  toggleChecked(e) {
    let checked = !this.state.checked

    if(this.props.form && this.props.value !== this.props.form[this.props.name].value) {
        checked = true
    }

    this.setState({ checked: checked })

    if(typeof this.props.onInput === 'function') {
      this.props.onInput({
        target: {
          name: this.props.name,
          value: checked ? this.props.value : null
        }
      })
    }
  }

  render() {

    const opts = {}
    if(this.props['text-align']) {
      opts['text-align'] = this.props['text-align']
    }

    let cn = ''
    if(this.props['className']) {
      cn += ' ' + this.props['className']
    }

    if(this.props.isModified || (this.props.form && this.props.form[this.props.name].isModified)) {
      cn += ' input-modified'
    }

    if(this.props.haveError) {
      cn += ' input-error'
    }

    if(this.props.noPadding) {
      cn += ' input-no-padding'
    }

    if(this.props.name) {
      opts.name = this.props.name
    }

    let style = {}
    let styleInput = {}
    if(this.props.size) {
      style.width = (this.props.size + 3.5) + "ch"
      styleInput.fontFamily = "monospace"
      styleInput.maxWidth = (this.props.size + .25) + "ch"
    }

    let checked = false
    if(this.state.type === 'checkbox') {
      checked = this.state.checked

      if(this.props.form) {
        checked = this.props.value === this.props.form[this.props.name].value
      }

    }

    return (
      <>
        {
          this.state.type === 'checkbox' ?
            <div className={"input-checkbox" + (checked ? ' checked' : '') + cn } onClick={ this.toggleChecked }>
            </div>
          : ''
        }
        {
          this.state.type !== 'checkbox' ?
            <div className={ "input" + (this.state.focused ? ' input-focused' : '') + cn } style={ style }>
              { this.state.prefix && this.state.type !== 'textarea' ? <div className="input-prefix">{ this.state.prefix }</div> : '' }
              {
                this.state.type === 'textarea' ?
                  <textarea
                      className="input-field"
                      value={this.props.value || '' }
                      type={ this.state.type }
                      placeholder={ this.state.placeholder }
                      step={ this.state.step }
                      onInput={ this.handleChange }
                      onChange={ this.handleChange }
                      onFocus={ () => this.focused(true) }
                      onBlur={ () => this.focused(false) }
                      { ...opts }
                    />
                : ''
              }
              {
                this.state.type !== 'textarea' && this.state.type !== 'checkbox' ?
                <input
                  style={ styleInput }
                  className="input-field"
                  value={this.props.value || '' }
                  type={ this.state.type }
                  placeholder={ this.state.placeholder }
                  step={ this.state.step }
                  onInput={ this.handleChange }
                  onChange={ this.handleChange }
                  onFocus={ () => this.focused(true) }
                  onBlur={ () => this.focused(false) }
                  { ...opts }
                />
                : ''
              }

              {this.state.suffix && this.state.type !== 'textarea' ? <div className="input-suffix">{ this.state.suffix }</div> : '' }
            </div>
            : ''
        }
      </>
    )
  }
}

export default Input