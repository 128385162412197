import React, { useState, useEffect, memo }  from 'react'
import './gamecard.scss'
import { filterType } from '../../../constant'
import { oneOfMany, getUrl } from '../../../library/Core'


const Toto = memo(() => {
    return (<div>render 1</div>)
})


const BigGameCard = memo((props) => {
    const { urls: __urls, datas: __datas} = props

    return (
        <>
            <div className="gamecard-image">
                <img src={ __urls.gamecard + __datas.gameId + ".jpg" } alt={ __datas.name } />
            </div>
            <div className="gamecard-content">
                <div className="gamecard-title-wrapper">
                    <div className="gamecard-icon">
                        <img src={ __urls.icon + (__datas.icon ? __datas.gameId  : "default") + ".png" } alt={ __datas.name } />
                    </div>
                    <div className="gamecard-title">
                        <span className="gamecard-name">{ __datas.name }</span>
                        <span className="gamecard-type">{ filterType[__datas.type] }</span>
                    </div>
                </div>
                <div className="gamecard-bar">
                    <div className="gamecard-information">
                        {
                            __datas.ladder &&
                            <svg viewBox="0 0 298.334 298.334" title="Ladder">
                                <path d="M183.167,61.394h-68c-5.522,0-10,4.478-10,10V226.94c0,5.522,4.478,10,10,10h68c5.522,0,10-4.478,10-10V71.394
                                    C193.167,65.871,188.689,61.394,183.167,61.394z"/>
                                <path d="M75.667,108.514H10c-5.522,0-10,4.478-10,10V226.94c0,5.522,4.478,10,10,10h65.667c5.522,0,10-4.478,10-10V118.514
                                    C85.667,112.991,81.189,108.514,75.667,108.514z"/>
                                <path d="M288.334,135.607h-66.667c-5.522,0-10,4.478-10,10v81.333c0,5.522,4.478,10,10,10h66.667c5.522,0,10-4.478,10-10v-81.333
                                    C298.334,140.085,293.856,135.607,288.334,135.607z"/>
                            </svg>
                        }
                    </div>
                    <div className="gamecard-platform">
                        {
                            oneOfMany(__datas.platform, 'XBOX') &&
                                <svg viewBox="0 0 512 512" className="xbox">
                                    <path d="M342.656,15.42c-53.952-20.512-119.392-20.512-173.312,0c-6.144,2.336-10.272,8.256-10.304,14.848
                                        c-0.032,6.592,4,12.576,10.144,15.008c26.976,10.592,53.12,24.64,77.664,41.728c2.752,1.92,5.952,2.88,9.152,2.88
                                        s6.4-0.96,9.152-2.88c24.576-17.12,50.72-31.168,77.664-41.76c6.144-2.4,10.176-8.352,10.144-14.976
                                        C352.928,23.644,348.832,17.756,342.656,15.42z"/>
                                    <path d="M62.343,422.783c-0.002,0.009-0.005,0.019-0.007,0.029c0.004-0.007,0.009-0.015,0.013-0.022
                                        C62.347,422.788,62.345,422.785,62.343,422.783z"/>
                                    <path d="M214.112,103.292C186.816,81.596,157.856,61.884,128,44.668c-5.344-3.072-11.968-2.784-17.088,0.672
                                        C41.472,93.212,0,171.932,0,255.964c0,61.395,22.071,120.583,62.343,166.819c17.965-76.045,96.192-241.45,152.793-295.395
                                        c3.36-3.2,5.152-7.68,4.96-12.288C219.904,110.492,217.728,106.172,214.112,103.292z"/>
                                    <path d="M401.056,45.34c-5.088-3.488-11.648-3.776-17.056-0.672c-29.856,17.216-58.848,36.928-86.112,58.624
                                        c-3.616,2.88-5.824,7.168-6.016,11.808c-0.224,4.64,1.6,9.12,4.928,12.288c56.576,53.92,135.04,219.168,152.992,295.232
                                        c-39.04-65.984-125.984-221.28-182.912-274.368c-6.112-5.728-15.68-5.728-21.824,0C188.07,201.366,101.395,356.761,62.349,422.79
                                        c0.006,0.007,0.013,0.015,0.019,0.022c48.16,56.64,118.72,89.152,193.632,89.152s145.472-32.512,193.824-89.344
                                        C489.92,376.604,512,317.404,512,255.964C512,171.932,470.528,93.212,401.056,45.34z"/>
                                </svg>
                        }
                        {
                            oneOfMany(__datas.platform, 'PS') &&
                                <svg  viewBox="0 0 512 210" className="ps4">
                                    <g>
                                        <path d="M443.985,280.831c0-1.639-1.328-2.968-2.967-2.969l-14.185-0.009v-53.262c0-6.982-3.867-9.472-7.486-9.472
                                            c-4.839,0,0.306-2.32-103.92,61.689c-7.71,4.728-6.111,11.55,3.406,11.55l88.849,0.012v5.559c0,1.641,1.33,2.969,2.971,2.969
                                            c17.979,0,16.181,1.49,16.181-8.535C445.542,288.363,443.985,289.775,443.985,280.831z M407.7,277.836h-73.508l73.508-45.259
                                            V277.836z"/>
                                        <path d="M164.474,215.118H71.515c-1.639,0-2.969,1.33-2.969,2.969v4.565c0,1.64,1.33,2.969,2.969,2.969h87.29
                                            c6.416,0,10.097,4.35,10.097,11.936s-3.68,11.938-10.097,11.938h-70.17c-11.452,0-20.089,9.581-20.089,22.287l0.006,22.146
                                            c0,1.64,1.33,2.968,2.969,2.968H84.68c1.639,0,2.969-1.329,2.969-2.969v-22.146c0-7.045,3.844-11.78,9.564-11.78h67.262
                                            c14.509,0,23.522-8.592,23.522-22.424C187.996,223.724,178.983,215.118,164.474,215.118z"/>
                                        <path d="M319.254,215.972c-0.557-0.557-1.313-0.87-2.102-0.87l-50.739,0.01c-14.509,0-23.522,8.601-23.522,22.445v36.897
                                            c0,7.58-3.68,11.929-10.097,11.929h-47.616c-1.639,0-2.969,1.33-2.969,2.969v4.575c0,1.641,1.33,2.969,2.969,2.969h53.276
                                            c14.521,0,23.538-8.596,23.538-22.433v-36.918c0-7.587,3.678-11.938,10.089-11.938h45.073c1.639,0,2.969-1.33,2.969-2.969
                                            C320.123,217.919,320.356,217.075,319.254,215.972z"/>
                                        <path d="M492.498,155.046H273.501c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h218.998c2.482,0,4.5,2.019,4.5,4.5
                                            v67.063c0,4.142,3.357,7.5,7.5,7.5c4.142,0,7.5-3.358,7.499-7.5v-67.063C511.999,163.795,503.25,155.046,492.498,155.046z"/>
                                        <path d="M504.5,269.113c-4.143,0-7.5,3.358-7.5,7.5v60.839c0,2.482-2.018,4.5-4.5,4.5H19.501c-2.481,0-4.5-2.018-4.5-4.5V174.548
                                            c0-2.481,2.019-4.5,4.5-4.5h218.997c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5H19.501
                                            C8.749,155.046,0,163.795,0,174.548v162.905c0,10.754,8.749,19.501,19.501,19.501h472.998c10.753,0,19.501-8.749,19.501-19.501
                                            v-60.839C512,272.471,508.643,269.113,504.5,269.113z"/>
                                    </g>
                                </svg>
                        }
                        {
                            oneOfMany(__datas.platform, 'PC') &&
                                <svg viewBox="0 0 478 340" className="pc">
                                    <g>
                                        <path d="M460.88,106.006h-91.92c-9.499,0-17.2,7.701-17.2,17.2v18h126.32v-18C478.08,113.707,470.379,106.006,460.88,106.006z"/>
                                        <path d="M462.08,195.206H351.76v148.64c0,9.499,7.701,17.2,17.2,17.2c0,0,0,0,0,0h91.92c9.499,0,17.2-7.701,17.2-17.2v-148.64
                                            H462.08z M415.04,263.766c6.186,0,11.2,5.014,11.2,11.2c0,6.186-5.014,11.2-11.2,11.2s-11.2-5.014-11.2-11.2
                                            C403.84,268.781,408.854,263.766,415.04,263.766z M442.8,317.206h-55.6c-4.418,0-8-3.582-8-8s3.582-8,8-8h0.08h55.52
                                            c4.418,0,8,3.582,8,8S447.218,317.206,442.8,317.206z"/>
                                        <polygon points="470.08,157.206 462.08,157.206 367.76,157.206 359.76,157.206 351.76,157.206 351.76,179.206 359.76,179.206
                                            367.76,179.206 462.08,179.206 470.08,179.206 478.08,179.206 478.08,157.206 		"/>
                                        <path d="M319.04,88.646H18.24c-9.663-0.361-17.807,7.14-18.24,16.8v185.92c0.433,9.66,8.577,17.161,18.24,16.8h300.8
                                            c9.663,0.361,17.807-7.14,18.24-16.8v-185.92C336.847,95.787,328.703,88.285,319.04,88.646z"/>
                                        <path d="M239.04,373.446h-24.96v-48.88c0-4.418-3.582-8-8-8H130.8c-4.418,0-8,3.582-8,8v48.88H98.24c-4.418,0-8,3.582-8,8
                                            s3.582,8,8,8h140.8c4.418,0,8-3.582,8-8S243.458,373.446,239.04,373.446z"/>
                                    </g>
                                </svg>
                        }
                        {
                            oneOfMany(__datas.platform, 'APPLE') &&
                                <svg viewBox="0 0 512.005 512.005" className="apple">
                                    <path d="M461.852,355.712c-1.692-2.616-4.443-4.362-7.531-4.779c-40.621-5.306-69.25-42.537-63.944-83.158
                                        c3.748-28.694,23.84-52.588,51.465-61.205c5.61-1.798,8.701-7.803,6.903-13.413c-0.28-0.873-0.671-1.707-1.164-2.481
                                        c-18.641-33.642-51.786-56.779-89.792-62.677c-19.152,0.914-38.026,4.957-55.872,11.968c-12.817,5.158-26.351,8.317-40.128,9.365
                                        c-13.777-1.048-27.311-4.207-40.128-9.365c-17.846-7.011-36.72-11.054-55.872-11.968c-39.829,0-117.333,56.469-117.333,160
                                        c0,98.389,71.765,224,128,224c21.457,0.192,42.656-4.691,61.867-14.251c7.235-3.99,15.232-6.404,23.467-7.083
                                        c8.234,0.679,16.232,3.093,23.467,7.083c19.211,9.56,40.41,14.443,61.867,14.251c44.587,0,94.912-76.544,115.989-147.477
                                        C463.995,361.543,463.535,358.325,461.852,355.712z"/>
                                    <path d="M251.121,128c64.772-0.071,117.263-52.561,117.333-117.333C368.454,4.776,363.679,0,357.788,0
                                        c-64.772,0.071-117.263,52.561-117.333,117.333C240.454,123.224,245.23,128,251.121,128z"/>
                                </svg>
                        }
                        {
                            oneOfMany(__datas.platform, 'MOBILE') &&
                                <svg viewBox="0 0 512 512" className="mobile">
                                    <path d="M384,0H128c-17.632,0-32,14.368-32,32v448c0,17.664,14.368,32,32,32h256c17.664,0,32-14.336,32-32V32
                                        C416,14.368,401.664,0,384,0z M256,480c-17.664,0-32-14.336-32-32s14.336-32,32-32s32,14.336,32,32S273.664,480,256,480z M384,384
                                        H128V64h256V384z"/>
                                </svg>
                        }
                    </div>
                </div>
            </div>
        </>
    )
})

const TinyGameCard = memo((props) => {

    const { datas: __datas, urls: __urls } = props

    return (
        <>
            <div className="gamecard-image">
                <img src={ __urls.gamecard + __datas.gameId + ".jpg" } alt={ __datas.name } />
                <div className="gamecard-title">
                    <span className="gamecard-name">{ __datas.name }</span>
                </div>
            </div>
        </>
    )
})

export default memo(function Gamecard(props) {

    const {
        datas: __datas,
        type: __type,
        selected: __selected
    } = props

    const TypeGameCard = __type || 'big'
    const urls = getUrl()

    const Map = {
        "big": BigGameCard,
        "tiny": TinyGameCard
    }

    let className = ''
    if(__selected === true) {
        className = ' gamecard-selected'
    }
    else if(__selected === false) {
        className = ' gamecard-not-selected'
    }

    const Component = Map[TypeGameCard]
    return (
        <div className={"gamecard gamecard-" + __type + className }>
            <div className="gamecard-wrapper">
                <Component datas={ __datas } urls={ urls } />
            </div>
        </div>
    )
})