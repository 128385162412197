import React, { memo }  from 'react'
import './box.scss'

const Box = memo(props => {

    let className = ''
    if(props.className) {
        className = ' ' + props.className
    }

    return (
        <div className={"box" + className }>
            { props.children }
        </div>
    )
})

const BoxHeader = memo(props => {
    return (
        <div className="box-header">
            { props.children }
        </div>
    )
})

const BoxContent = memo(props => {
    return (
        <div className="box-content">
            { props.children }
        </div>
    )
})

export {Box, BoxHeader, BoxContent}