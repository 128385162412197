import React, { useState, useCallback, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { isFunction, getUrl } from '../library/Core'
import './gamePage.scss'

import { useStore } from "../store/useStore"

import { Row, Column } from '../components/ux/grid'
import UxMatch from '../components/ux/match/UxMatch'
import Favorite from '../project/ux/favorite'
import { fetchGame } from '../socket/actions'
import { Uxtablerow, Uxtableheader } from '../components/ux/Uxtable'

import GameLadderPage from './game/game.ladderPage';
import GameDetailPage from './game/game.detailPage';

export default function GamePage({

}) {

    const { gameId, section } = useParams()
    const { state, dispatch } = useStore()

    const isFav = state.setting.gameList[gameId] && state.setting.gameList[gameId].enable ? state.setting.gameList[gameId].enable : false

    const [game, setGame] = useState({})
    const [ladders, setLadders] = useState([])

    useEffect(() => {
        fetchGame(gameId)
        .then(datas => {
            setGame(datas.game)
            setLadders(datas.results)
        })
        .catch(err => { console.log(err) })
    }, [gameId])

    const updateFav = useCallback((value) => {
        dispatch({
            type: "setFavGame",
            payload: {
                gameId: gameId,
                value: value,
                name: game.name
            }
        })
    }, [game, gameId])

    const [ladderOpen, setLadderOpen] = useState([false,false,false])

    const onOpen = (id, value) => {
        let tmp = [false,false,false]
        tmp[id] = value
        setLadderOpen(tmp)
    }

    return (
        <>
            <div className="overflow-mover">
                <div className={ "game-hero game-hero-" + gameId + " hero-animation" }>
                    <div className="achild child1" />
                    <div className="achild child2" />
                    <div className="achild child3" />
                    <div className="achild child4" />
                    <Row>
                        <Column>
                            <div className="game-hero-title">
                                <h1>{ game.name }</h1>
                                {
                                    game.icon ?
                                        <div className="game-hero-title-actions">
                                            <Favorite checked={ isFav } onChange={ updateFav } />
                                        </div>
                                    : ''
                                }
                            </div>
                        </Column>
                    </Row>
                </div>
                <div className="game-nav">
                    <Row>
                        <Column>
                            <ul>
                                <li>
                                    <NavLink exact to={ "/game/" + gameId } activeClassName="selected">
                                        Informations
                                    </NavLink>
                                </li>
                                {
                                    game.ladder &&
                                    <>
                                    <li>
                                        <NavLink to={ "/game/" + gameId + '/ladder' } activeClassName="selected">
                                            Ladder
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={ "/game/" + gameId + '/matchs' } activeClassName="selected">
                                            Matchs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={ "/game/" + gameId + '/structures' } activeClassName="selected">
                                            Structures
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={ "/game/" + gameId + '/teams' } activeClassName="selected">
                                            Equipes
                                        </NavLink>
                                    </li>
                                    </>
                                }
                            </ul>
                        </Column>
                    </Row>
                </div>
                <div className="background-stellar">
                {
                    !section && <GameDetailPage gameId={ gameId } name={ game.name } />
                }
                {
                    section === 'ladder' && <GameLadderPage gameId={ gameId } name={ game.name } />
                }
                {
                    section === 'matchs' &&
                    <>
                        <h1>Matchs à venir</h1>
                    </>
                }
                {
                    section === 'teams' &&
                    <Row>
                        <Column>
                            <h2>Liste des équipes { game.name }</h2>
                            <div className="box ladder-list">
                                <Uxtableheader>
                                    <div className="table-team">
                                        Equipes
                                    </div>
                                    <div className="table-actions">

                                    </div>
                                </Uxtableheader>
                                <Uxtablerow>
                                    <div className="table-team">
                                        <img src="/assets/teams/tribe.png" alt="Tribe Amaze"/>
                                        <NavLink to={ "/team/tribe-amaze" }>
                                            Tribe Amaze
                                        </NavLink>
                                    </div>
                                    <div className="table-actions">
                                    </div>
                                </Uxtablerow>
                                <Uxtablerow>
                                    <div className="table-team">
                                        <img src="/assets/teams/tribe.png" alt="Tribe Amaze"/>
                                        <NavLink to={ "/team/tribe-amaze" }>
                                            Tribe Amaze
                                        </NavLink>
                                    </div>
                                    <div className="table-actions">
                                    </div>
                                </Uxtablerow>
                                <Uxtablerow>
                                    <div className="table-team">
                                        <img src="/assets/teams/tribe.png" alt="Tribe Amaze"/>
                                        <NavLink to={ "/team/tribe-amaze" }>
                                            Tribe Amaze
                                        </NavLink>
                                    </div>
                                    <div className="table-actions">
                                    </div>
                                </Uxtablerow>
                                <Uxtablerow>
                                    <div className="table-team">
                                        <img src="/assets/teams/tribe.png" alt="Tribe Amaze"/>
                                        <NavLink to={ "/team/tribe-amaze" }>
                                            Tribe Amaze
                                        </NavLink>
                                    </div>
                                    <div className="table-actions">
                                    </div>
                                </Uxtablerow>
                                <Uxtablerow>
                                    <div className="table-team">
                                        <img src="/assets/teams/tribe.png" alt="Tribe Amaze"/>
                                        <NavLink to={ "/team/tribe-amaze" }>
                                            Tribe Amaze
                                        </NavLink>
                                    </div>
                                    <div className="table-actions">
                                    </div>
                                </Uxtablerow>
                            </div>
                        </Column>
                    </Row>
                }
                </div>
            </div>
        </>
    )
}