import React, { useState } from 'react'
import { hashUnsecured } from '../../library/Core'
import './admiter.scss'

import { Row, Column } from './grid'


export const AdmiterItem = (props) => {

    const changePage = () => {
        props.changePage(props.id)
    }

    return (
        <div className={"admiter-menu-item" + (props.current === props.id ? ' selected': '') } onClick={ changePage }>{ props.children }</div>
    )
}


export default function Admiter(props) {

    const { state, dispatch } = props.store()
    const [current, setCurrent] = useState(null)

    const close = () => {
        dispatch({
            "type": "setAdmiterStatus",
            "payload": false
        })
    }

    const changePage = (id) => {
        setCurrent(id)
    }

    let components = {}
    const children = React.Children.map(
        props.children,
        child => {
            const id = 'at-' + hashUnsecured(child.props.children)
            components[id] = child.props.component

            return React.cloneElement(child, {
                changePage: changePage,
                id: id,
                current: current
            })
        }
    )

    const TagName = components[current] ? components[current] : ''

    return (
        <div className={"admiter" + ( state.ux.admiter ? ' open' : ' close' ) }>
            <header>
                <div onClick={ close }></div>
                Panneau de contrôle
            </header>
            <div className="admiter-content-wrapper">
                <div className="admiter-menu">
                    {
                        children.filter(child => {
                            return !child.props.filter || child.props.filter(state)
                        })
                    }
                </div>
                <div className="admiter-content">
                    { TagName ? <TagName /> : '' }
                </div>
            </div>
        </div>

    )
}