import React, { useState } from 'react'
import './memberlist.scss'

import { Uxtablerow, Uxtableheader } from '../ux/Uxtable'
import Input from '../fields/Input'

function Manager(props) {
    return(
        <svg viewBox="0 -10 511.99143 511" className={ props.selected ? 'selected' : null }>
            <title>Manager</title>
            <path d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657"/>
        </svg>
    )
}

function Owner(props) {
    return(
        <svg viewBox="0 0 512 512" className={ props.selected ? 'selected' : null }>
            <title>Créateur</title>
            <path d="M255.999,255.822c-18.822,0-34.132,15.311-34.132,34.132s15.311,34.132,34.132,34.132s34.132-15.311,34.132-34.132
                S274.82,255.822,255.999,255.822z"/>
            <path d="M489,264.359l22.715-124.945c2.414-13.317-10.929-24.093-23.51-18.744c-24.188,10.356-50.743,14.245-76.865,11.256
                c-10.464-1.331-19.917,7.546-18.943,18.466c1.777,19.992-14.031,37.166-33.999,37.166c-18.822,0-34.132-15.311-34.132-34.132
                c0-10,4.367-19.466,11.977-25.966c8.208-7.015,7.918-19.809-0.6-26.443c-9.855-7.666-18.777-16.594-26.511-26.526l-39.665-51.004
                c-6.467-8.311-20.466-8.311-26.933,0l-39.665,50.998c-7.733,9.938-16.655,18.866-26.511,26.532
                c-8.518,6.634-8.807,19.428-0.6,26.443c7.61,6.5,11.977,15.966,11.977,25.966c0,18.822-15.311,34.132-34.132,34.132
                c-19.978,0-35.775-17.182-33.999-37.166c0.977-10.966-8.521-19.777-18.943-18.466c-26.121,2.989-52.676-0.9-76.865-11.256
                c-12.604-5.359-25.92,5.457-23.51,18.744L23,264.36C9.166,273.534,0.006,289.212,0.006,307.02
                c0,24.728,17.618,45.409,40.961,50.164l9.489,35.167h411.092l9.484-35.167c23.342-4.757,40.96-25.438,40.96-50.164
                C511.992,289.212,502.832,273.534,489,264.359z M51.205,324.087c-9.41,0-17.066-7.656-17.066-17.066s7.656-17.066,17.066-17.066
                c9.41,0,17.066,7.656,17.066,17.066S60.615,324.087,51.205,324.087z M255.999,358.219c-37.643,0-68.265-30.621-68.265-68.265
                s30.621-68.265,68.265-68.265c37.643,0,68.265,30.621,68.265,68.265S293.642,358.219,255.999,358.219z M460.793,324.087
                c-9.41,0-17.066-7.656-17.066-17.066s7.656-17.066,17.066-17.066c9.41,0,17.066,7.656,17.066,17.066
                S470.203,324.087,460.793,324.087z"/>
            <path d="M443.727,426.484H68.271c-18.851,0-34.132,15.281-34.132,34.132s15.281,34.132,34.132,34.132h375.456
                c18.851,0,34.132-15.281,34.132-34.132S462.578,426.484,443.727,426.484z"/>
        </svg>
    )
}

function Coach(props) {
    return(
        <svg viewBox="0 0 512 512" className={ props.selected ? 'selected' : null }>
            <title>Coach</title>
            <path d="M352.636,81.017l-58.345-8.481l-26.089-52.881c-2.52-5.127-7.734-8.364-13.447-8.364s-10.928,3.237-13.447,8.364
                l-26.104,52.881l-58.345,8.481c-12.264,1.778-17.194,16.924-8.32,25.576l42.231,41.147l-9.976,58.125
                c-2.106,12.25,10.838,21.619,21.768,15.806l52.192-27.437l52.178,27.437c10.908,5.744,23.884-3.495,21.768-15.806l-9.976-58.125
                l42.231-41.147C369.829,97.942,364.9,82.795,352.636,81.017z"/>
            <path d="M493.729,209.059l-30.586-4.453l-13.696-27.729c-5.039-10.254-21.855-10.254-26.895,0l-13.696,27.729l-30.586,4.453
                c-12.264,1.778-17.194,16.924-8.32,25.576l22.148,21.592l-5.229,30.483c-2.109,12.275,10.906,21.584,21.768,15.806L436,288.131
                l27.363,14.385c10.908,5.744,23.884-3.495,21.768-15.806l-5.229-30.483l22.148-21.592
                C510.923,225.984,505.994,210.837,493.729,209.059z"/>
            <path d="M133.73,179.059l-30.586-4.453l-13.696-27.729c-5.039-10.254-21.855-10.254-26.895,0l-13.696,27.729l-30.586,4.453
                c-12.265,1.778-17.194,16.925-8.321,25.576l22.148,21.592l-5.229,30.483c-2.115,12.305,10.939,21.583,21.768,15.806L76,258.131
                l27.363,14.385c10.908,5.744,23.884-3.495,21.768-15.806l-5.229-30.483l22.148-21.592
                C150.923,195.983,145.994,180.837,133.73,179.059z"/>
            <path d="M15,350.709c-8.291,0-15,6.709-15,15v120c0,8.291,6.709,15,15,15h106v-150H15z"/>
            <path d="M497,380.709H391v120h106c8.291,0,15-6.709,15-15v-90C512,387.418,505.291,380.709,497,380.709z"/>
            <path d="M346,290.709H166c-8.291,0-15,6.709-15,15c0,51.021,0,85.087,0,195c42.589,0,160.259,0,210,0c0-41.685,0-152.506,0-195
                C361,297.418,354.291,290.709,346,290.709z M286,440.709h-60c-8.291,0-15-6.709-15-15c0-8.291,6.709-15,15-15h15v-30h-15
                c-8.291,0-15-6.709-15-15c0-8.291,6.709-15,15-15h30c8.291,0,15,6.709,15,15v45h15c8.291,0,15,6.709,15,15
                C301,434,294.291,440.709,286,440.709z"/>
        </svg>
    )
}

function Designer(props) {
    return(
        <svg viewBox="0 0 511.997 511.997" className={ props.selected ? 'selected' : null }>
            <title>Graphiste</title>
            <path d="m335.274 258.137c-2.3 18.79-10.75 36.7-24.67 50.62-16.92 16.93-40.57 24.9-70.27 23.7-20.83-.83-30.45 4.62-32.03 5.61-6.38 5.59-14.48 8.65-23.03 8.65-9.35 0-18.14-3.64-24.75-10.25-11.41-11.41-13.25-29.01-5.46-42.42l5.59-8.86c1.9-4.63 4.47-13.77 3.87-28.54-1.19-29.71 6.78-53.35 23.7-70.27 13.86-13.85 31.58-22.43 50.74-24.72 2.71-13.03 8.58-25.22 17.05-35.46h-200.98c-22.08 0-40.05 17.96-40.05 40.04v198.59h417.8v-197.19l-50.77 62.22c-11.94 14.59-28.52 24.52-46.74 28.28z"/>
            <path d="m14.984 407.627c0 22.07 17.97 40.03 40.05 40.03h113.819v34.34h-25.228c-8.284 0-15 6.716-15 15s6.716 15 15 15h160.513c8.284 0 15-6.716 15-15s-6.716-15-15-15h-25.228v-34.34h113.823c22.08 0 40.05-17.96 40.05-40.03v-12.8h-417.8v12.8z"/>
            <path d="m182.1 307.834c-.003.002-.006.005-.008.008-.002.001-.003.002-.004.004l-.004.004c-.002.001-.003.003-.005.005-.001.003-.002.003-.005.005l-.001.001c-.001.002-.003.003-.005.005 0 0 0 0-.001.001s-.003.003-.004.005c0 0 0 0-.001.001-.002.001-.003.003-.005.005 0 0 0 0-.001.001l-.005.005c-1.896 1.957-1.877 5.08.056 7.014.977.976 2.256 1.464 3.536 1.464 1.268 0 2.536-.479 3.509-1.438.306-.29 15.453-14.293 52.767-12.793 20.993.846 37.089-4.183 47.846-14.939 22.043-22.043 22.043-57.909 0-79.952-10.678-10.678-24.875-16.559-39.976-16.559s-29.298 5.88-39.976 16.559c-10.756 10.756-15.783 26.854-14.939 47.846 1.508 37.517-12.653 52.623-12.767 52.741-.004.003-.005.005-.007.007z"/>
            <path d="m333.974 227.297c9.78-2.93 18.59-8.73 25.16-16.76l19.166-23.488-68.276-68.276-23.39 19.104c-8.05 6.56-13.87 15.36-16.81 25.14 15.46 3.63 29.64 11.5 41.15 23.01 11.78 11.78 19.45 26.13 23 41.27z"/>
            <path d="m488.004 9.007c-11.17-11.17-28.83-12.06-41.07-2.06l-113.558 92.753 63.987 63.987 92.711-113.619c9.99-12.231 9.1-29.891-2.07-41.061z"/>
        </svg>
    )
}

function Videoeditor(props) {
    return(
        <svg viewBox="0 0 512 512" className={ props.selected ? 'selected' : null }>
            <title>Monteur vidéo</title>
            <path d="m435.21 422.054c-13.37-38.82-57.88-56.72-94.15-38.75-19.01 23.79-52.13 65.25-52.93 66.25 19.98 24.6 34.38 42.06 34.7 42.28 17.79 17.94 44.91 25.21 70.32 16.46 35.37-12.18 54.24-50.86 42.06-86.24zm-51.83 57.87c-19.72 6.8-41.3-3.73-48.1-23.46-6.82-19.78 3.69-41.29 23.46-48.1 19.73-6.79 41.31 3.73 48.11 23.46 6.81 19.78-3.69 41.29-23.47 48.1z"/>
            <path d="m497 .004h-482c-8.28 0-15 6.72-15 15v270c0 8.28 6.71 15 15 15l156.75.01c5.2 7.82 13.48 19.12 23.55 32.38l31.52-62.37v-.01h-36.15v-30.01h51.32l62.59-123.85c15.11-29.85 56.44-33.42 76.37-6.43 41.41 56.1 47.72 129.16 19.59 190.3 105.23.02 98.38.47 102.2-1.13 5.74-2.39 9.26-7.92 9.26-13.86v-270.03c0-8.28-6.72-15-15-15zm-416.67 270h-50.33v-30h50.33zm0-210h-50.33v-30h50.33zm80.34 210.01h-50.34v-30.01h50.34zm0-210.01h-50.34v-30h50.34zm80.33 0h-50.33v-30h50.33zm80.33 0h-50.33v-30h50.33zm80.34 0h-50.34v-30h50.34zm80.33 210.03h-50.33v-30.03h50.33zm0-210.03h-50.33v-30h50.33z"/>
            <path d="m356.82 127.544c-6.63-8.97-20.42-7.82-25.46 2.15l-129.54 256.3c-37.12-22.29-85.12-4.46-99.07 36.07-12.18 35.37 6.69 74.06 42.06 86.23 25.13 8.66 52.32 1.69 70.32-16.46 2.63-1.87-6.27 8.8 137.16-170.64 45.51-55.98 47.37-135.6 4.53-193.65zm-154.14 328.93c-6.8 19.73-28.38 30.25-48.11 23.46-19.77-6.81-30.27-28.33-23.46-48.1 6.8-19.73 28.37-30.26 48.1-23.47 19.78 6.81 30.28 28.33 23.47 48.11z"/>
        </svg>
    )
}

function Warning(props) {
    return(
        <svg viewBox="0 0 512 512" className={ props.selected ? 'selected' : null }>
            <title>Sans rôle</title>
            <path d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95
			c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241
			c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z
			M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30
			V317.241z"/>
        </svg>
    )
}

function Other(props) {
    return(
        <svg viewBox="0 0 510 510" className={ props.selected ? 'selected' : null }>
            <title>{ props.name }</title>
            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,76.5
            c43.35,0,76.5,33.15,76.5,76.5s-33.15,76.5-76.5,76.5c-43.35,0-76.5-33.15-76.5-76.5S211.65,76.5,255,76.5z M255,438.6
            c-63.75,0-119.85-33.149-153-81.6c0-51,102-79.05,153-79.05S408,306,408,357C374.85,405.45,318.75,438.6,255,438.6z"/>
        </svg>
    )
}

function isOther(member) {
    return  member.role !== 'manager' && 
            member.role !== 'owner' && 
            member.role !== 'coach' && 
            member.role !== 'designer' && 
            member.role !== 'videoeditor' && 
            member.role !== null
}

export default function Memberlist(props) {

    const [members, setMembers] = useState(props.members)
    const [setting, setSetting] = useState(null)
    const [current, setCurrent] = useState(null)
    const [other, setOther] = useState(null)

    const roleChanger = (i) => {
        setSetting(setting === null ? 'role' : null)
        setCurrent(i)

        if(isOther(members[i])) {
            setOther(members[i].role)
        }
    }

    const roleChange = (role) => {
        const tmp = [ ...members ]

        if(role === 'owner') {
            tmp.map(m => {
                if(m.role === 'owner') {
                    m.role = null
                }

                return m
            })
        }

        tmp[current].role = role || other

        setMembers(tmp)
        close()
    }

    const close = () => {
        setSetting(null)
        setCurrent(null)
        setOther(null)
    }

    const toggleOther = () => {
        const tmp = [ ...members ]
        if(!isOther(tmp[current])) {
            tmp[current].role = ""
            setMembers(tmp)
        }
    }

    return (
        <div className="ux-member-list">

            <div className={"ux-member-list-wrapper" + (setting !== null ? ' ux-member-list-setting-show' : '')}>
                <div className="ux-member-list-details">
                    <Uxtableheader>
                        <div className="ux-member-list-table-name">
                            Nom
                        </div>
                        <div className="ux-member-list-table-role">
                            Rôles
                        </div>
                        <div className="ux-member-list-table-actions">

                        </div>
                    </Uxtableheader>
                    <div className="ux-member-list-wrapper-scroller">
                    {
                        members.map( (m, i) => {
                            return <Uxtablerow key={ m.id }>
                                <div className="ux-member-list-table-name">
                                    { m.username }
                                </div>
                                <div className="ux-member-list-table-role" onClick={ (e) => roleChanger(i) }>
                                    {
                                        m.role === 'manager' ? <Manager /> : ''
                                    }
                                    {
                                        m.role === 'owner' ? <Owner /> : ''
                                    }
                                    {
                                        m.role === 'coach' ? <Coach /> : ''
                                    }
                                    {
                                        m.role === 'designer' ? <Designer /> : ''
                                    }
                                    {
                                        m.role === 'videoeditor' ? <Videoeditor /> : ''
                                    }
                                    {
                                        m.role === null ? <Warning /> : ''
                                    }
                                    {
                                        isOther(m) ? <Other name={ m.role } /> : ''
                                    }
                                </div>
                                <div className="ux-member-list-table-actions">

                                </div>
                            </Uxtablerow>
                        })
                    }
                    </div>
                </div>
                <div className={ "ux-member-list-setting" }>

                    {
                        setting === 'role' ?
                        <div className="ux-member-list-setting-role">
                            <div className="ux-member-list-setting-closer" onClick={ close }></div>Changer le rôle de { members[current].username }
                            <ul>
                                <li onClick={ () => roleChange('owner') } className={ members[current].role === 'owner' ? 'selected' : ''}>
                                    <Owner selected={ members[current].role === 'owner' } /> Créateur (unique)
                                </li>
                                <li onClick={ () => roleChange('manager') } className={ members[current].role === 'manager' ? 'selected' : ''}>
                                    <Manager selected={ members[current].role === 'manager' } /> Manager
                                </li>
                                <li onClick={ () => roleChange('coach') } className={ members[current].role === 'coach' ? 'selected' : ''}>
                                    <Coach selected={ members[current].role === 'coach' } /> Coach
                                </li>
                                <li onClick={ () => roleChange('designer') } className={ members[current].role === 'designer' ? 'selected' : ''}>
                                    <Designer selected={ members[current].role === 'designer' } /> Designer
                                </li>
                                <li onClick={ () => roleChange('videoeditor') } className={ members[current].role === 'videoeditor' ? 'selected' : ''}>
                                    <Videoeditor selected={ members[current].role === 'videoeditor' } /> Monteur
                                </li>
                                <li onClick={ toggleOther }>
                                    <div className="ux-member-list-setting-role-other">
                                    <Other selected={ isOther(members[current]) } />
                                    {
                                        isOther(members[current]) ?
                                        <>
                                            <Input type="text" value={ other } onChange={ e => setOther(e.target.value) } onInput={ e => setOther(e.target.value) } />
                                            <button onClick={ () => roleChange(null) }>Valider</button>
                                        </>
                                        : 'Autre'
                                    }
                                    </div>
                                </li>
                            </ul>
                        </div>
                        : ''
                    }
                </div>
            </div>
        </div>
    )
}