import React, { useEffect } from 'react'

import { Row, Column } from '../../components/ux/grid'
import Input from '../../components/fields/Input'
import useForm from '../../components/fields/form'
import { useStore } from "../../store/useStore"
import { updateAccount, fetchAccount } from "../../socket/actions"
import Toast from "../../components/ux/Uxtoast"

export default function AdmiterAccountPage(props) {

    const { state } = useStore()
    let schema = {
        firstname: { },
        lastname: { },
        birthdate: { },
        phone: { },
        address: { },
        zipcode: { },
        city: { },
        country: { }
    }

    const { state:formState, binding, modified, updateInitial, onSubmit } = useForm(
        schema,
        datas => {
            updateAccount(state.id, datas)
            .then(datas => {
                Toast.add('Informations mise à jour', 3000, 'success')
            })
            .catch(err => {
                console.log(err)
            })
        },
        error => {
            console.log(error)
        }
    )

    useEffect(() => {

        fetchAccount(state.id)
        .then(datas => {
            if(datas.detail) {
                datas.detail.birthdate = datas.detail.birthdate.replace(/(.+)T(.+)/g, '$1')
                updateInitial(datas.detail)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div className="admiter-account-page">
            <Row className="-margin">
                <div className="admiter-submitter">
                    { modified ? <button className="orange" onClick={ onSubmit }>Valider</button> : null }
                </div>
                <Column>
                    <h2>Mon compte</h2>
                    <p className="spacer30 size08">Votre nom, date de naissance ou tout autres détails, ne seront visibles par d'autres utilisateurs</p>
                </Column>
                <Column>
                    <h3>Nom</h3>
                </Column>
                <Column medium="6">
                    <label>
                        Prénom
                        <Input name="firstname" value={ formState.firstname.value } type="text" { ...binding } isModified={ formState.firstname.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        Nom
                        <Input name="lastname" value={ formState.lastname.value } type="text" { ...binding } isModified={ formState.lastname.isModified } />
                    </label>
                </Column>
                <Column>
                    <hr className="space20" />
                </Column>
                <Column>
                    <h3>Informations personnelles</h3>
                </Column>
                <Column medium="6">
                    <label>
                        Date de naissance
                        <Input name="birthdate" value={ formState.birthdate.value } type="date" { ...binding } isModified={ formState.birthdate.isModified } />
                    </label>
                </Column>
                <Column medium="6">
                    <label>
                        Téléphone
                        <Input name="phone" value={ formState.phone.value } type="text" { ...binding } isModified={ formState.phone.isModified } />
                    </label>
                </Column>
                <Column>
                    <hr className="space20" />
                </Column>
                <Column>
                    <h3>Localisation</h3>
                </Column>
                <Column>
                    <label>
                        Adresse
                        <Input name="address" value={ formState.address.value } type="text" { ...binding } isModified={ formState.address.isModified } />
                    </label>
                </Column>
                <Column medium="2">
                    <label>
                        Code postal
                        <Input name="zipcode" value={ formState.zipcode.value } type="text" { ...binding } isModified={ formState.zipcode.isModified } />
                    </label>
                </Column>
                <Column medium="5">
                    <label>
                        Ville
                        <Input name="city" value={ formState.city.value } type="text" { ...binding } isModified={ formState.city.isModified } />
                    </label>
                </Column>
                <Column medium="5">
                    <label>
                        Pays
                        <Input name="country" value={ formState.country.value } type="text" { ...binding } isModified={ formState.country.isModified } />
                    </label>
                </Column>
            </Row>
        </div>
    )
}