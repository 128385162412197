import React, { useEffect, useState } from 'react'
import './characterChooser.scss'
import { oneOfMany, toggleValueInArray, getUrl } from '../library/Core'

function Hero(props) {

    const urls = getUrl()

    return (
        <div className={"hero" + (props.selected ? ' selected' : '')} onClick={ () => props.onClick(props.id) }>
            <img src={ `${ urls.character }${ props.gameId }/${ props.id }.png` } alt={ props.name } />
            <span className="hero-name">{ props.name }</span>
            {
                props.role ?
                <span className="hero-roles">
                    {
                        props.role.map(r => <img key={ r } src={ `${ urls.role }${ props.gameId }/${ r }.png` } alt="" /> )
                    }
                </span>
                : ''
            }
        </div>
    )

}

function haveRole(filters, character) {

    let present = false
    for(let x = 0; x < filters.length; x++) {
        if(oneOfMany(character.role, filters[x])) {
            present = true
            break
        }
    }

    return present
}

function toggler(ref, value) {
    let tmp = [ ...ref ]
    return toggleValueInArray(value, tmp)
}

export default function HeroList(props) {

    const [filter, setFilter] = useState([])
    const [heroes, setHeroes] = useState([...props.values] || [])
    const [name, setName] = useState('')

    // reset if heroes change
    useEffect(() => {
        setHeroes([])
    }, [props.heroes])

    const toggle = (value) => {
        setFilter(toggler(filter, value))
    }

    const choose = (id) => {
        let tmp = toggler(heroes, id)
        setHeroes(tmp)
        if(typeof props.onChange === 'function') {
            props.onChange(tmp)
        }
    }

    const filterName = (e) => {
        const value = e.target.value
        setName(value)
    }

    return (
        <div className="hero-list">
            {
                props.roles ?
                <div className="hero-filters noselect">
                    {
                        Object.keys(props.roles).map( r => {
                            return (
                                <div key={ r } className={"hero-filter" + ( oneOfMany(filter, r) ? ' selected': '' ) } onClick={ () => toggle(r) }>
                                    { props.icons ? <img src={ `/assets/${ props.gameId }/roles/${ r }.png`} alt="" /> : '' }
                                    { props.roles[r] }
                                </div>
                            )
                        })
                    }
                </div>
                : ''
            }
            <div className="hero-list-info">
                <div className="hero-list-info-input">
                    <input value={ name } type="text" onChange={ filterName } onInput={ filterName } placeholder="Nom du héro" />
                </div>
                <div className="hero-list-info-list">
                    { heroes.filter(h => !!props.heroes[h]).map(h => <span key={ h } className="hero-list-info-list-stamp" onClick={ () => choose(h) }>{ props.heroes[h].name}</span> ) }
                    {
                        heroes.length === 0 ? <span className="hero-list-info-list-default">Aucun héro</span> : ''
                    }
                </div>
            </div>
            <div className="heros">
            {
                Object.keys(props.heroes).filter(k => {
                    let _role = filter.length === 0 || (props.roles && haveRole(filter, props.heroes[k]))
                    let _name = !name || props.heroes[k].name.match(new RegExp(name, 'gi')) !== null
                    return _role && _name
                }).map(h => (
                    <Hero key={ h } { ...props.heroes[h] } gameId={ props.gameId } id={ h } selected={ oneOfMany(heroes, h) } onClick={ choose } />
                ))
            }
            </div>
        </div>
    )
}