import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

export default function InvitePage({
    match: __match
}) {

    const { id } = useParams()
    const type = id.substr(0, 1)
    const code = id.substr(1)

    return (
        <Redirect to="/" />
    )
}