export const gameIdsToName = {
    overwatch: "Overwatch",
    apex: "Apex",
    leagueoflegends: "League Of Legends"
}

export const filterType = {
    br: "Battle royal",
    fps: "Fps",
    moba: "Moba",
    card: "Carte",
    other: "Autre"
}