import React, { useState, useCallback, useEffect }  from 'react'

const toJson = (state) => {

    let tmp = {}

    Object.keys(state).forEach(k => {
        tmp[k] = state[k].value
    })

    return tmp
}

export default function useForm(stateSchema, _submit, _error) {

    let _initial = {}
    Object.keys(stateSchema).forEach(k => {
        stateSchema[k].isModified = false

        if(!stateSchema[k].hasOwnProperty('value')) {
            stateSchema[k].value = ""
        }

        if(!stateSchema[k].hasOwnProperty('error')) {
            stateSchema[k].error = []
        }

        if(!stateSchema[k].hasOwnProperty('validator')) {
            stateSchema[k].validator = null
        }

        _initial[k] = stateSchema[k].value
    })

    const [state, setState] = useState(stateSchema)
    const [initial, setInitial] = useState(_initial)
    const [disable, setDisable] = useState(true)
    const [modified, setModified] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        setDisable(true)
    }, [])

    useEffect(() => {
        if (isDirty) {
            setDisable(validateState())
            setModified(validateModification)
        }
    }, [state, isDirty])

    const validateModification = useCallback(() => {

        const _modified = Object.keys(state).some(key => {
            return state[key].isModified;
        })

        return _modified

    }, [state])

    const validateState = useCallback(() => {

        const hasErrorInState = Object.keys(state).some(key => {
            const isInputFieldRequired = state.validator && state.validator[key] && state.validator[key].required ? true : false
            const stateValue = state[key].value // state value
            const stateError = state[key].error.length > 0 // state error

            return (isInputFieldRequired && !stateValue) || stateError;
        })

        return hasErrorInState

    }, [state])

    const errorSegment = useCallback((validator, value) => {
        let error = ''

        if (validator.hasOwnProperty('required')) {
            if (!value) {
                error = validator.message || 'Ce champ est requis'
            }
        }
        else if (validator.hasOwnProperty('regExp') && value) {
            if (value.match(validator.regExp) === null) {
                error = validator.message
            }
        }
        else if (validator.hasOwnProperty('test') && value) {
            if (validator.test(value)) {
                error = validator.message
            }
        }
        else if (validator.hasOwnProperty('min') && value) {
            if (value.length < validator.min) {
                error = validator.message || 'Ce champ est trop court'
            }
        }

        return error
    }, [])

    const onChange = useCallback( event => {
        setIsDirty(true)

        const name = event.target.name
        const value = event.target.value

        const validator = state[name].validator || null
        let error = [];

        if(validator) {
            if( validator instanceof Array) {
                error = validator.map(v => errorSegment(v, value)).filter(e => !!e)
            }
            else {
                error.push(errorSegment(validator, value))
            }
        }

        let isModified = initial[name] !== value && value !== null

        setState( prevState => ( { ...prevState, [name]: { value, error, isModified, validator } }) )
    }, [])

    const onSubmit = useCallback(
        event => {
            if(event) event.preventDefault();

            // Make sure that validateState returns false
            // Before calling the submit callback function

            if (!validateState() && typeof _submit === 'function') {
                setModified(false)

                let tmp = { ...state }
                let tmp2 = { ...initial }
                Object.keys(tmp).map(k => {
                    tmp[k].isModified = false
                    tmp2[k] = tmp[k].value
                })

                setState(tmp)
                setInitial(tmp2)
                _submit(toJson(state))
            }
            else if(typeof _error === 'function'){
                let tmp = { ...state }

                let tmp2 = Object.keys(tmp)
                .map(k => {

                    let error = []
                    if(tmp[k].validator) {
                        if( tmp[k].validator instanceof Array) {
                            error = tmp[k].validator.map(v => errorSegment(v, tmp[k].value)).filter(e => !!e)
                        }
                        else {
                            error.push(errorSegment(tmp[k].validator, tmp[k].value))
                        }
                    }

                    return error
                })

                _error([].concat.apply([], tmp2))
            }
        },
        [state]
    )

    const updateInitial = useCallback(
        values => {

            let newSchema = { ...state }
            let _initial = { ...initial }

            Object.keys(values).forEach(k => {
                if(_initial[k] !== undefined) {
                    _initial[k] = values[k]
                    newSchema[k].value = values[k]
                }
            })

            setState(newSchema)
            setInitial(initial)
        },
        [state]
    )

    const updateSchema = useCallback((s) => {
        setState(s)
    }, [])

    const binding = {
        onInput: onChange,
        onChange: onChange
    }

    return {
        state,
        updateInitial,
        updateSchema,
        onChange,
        onSubmit,
        disable,
        modified,
        binding
    }

}