import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import './mercatoPage.scss'
import { getUrl, localeDate, markdown } from '../library/Core'

import { Row, Column } from '../components/ux/grid'
import MercatoFilters from '../project/mercatofilters'
import { Uxtablerow, Uxtableheader } from '../components/ux/Uxtable'

import { fetchAdvertisements } from "../socket/actions"
import { useStore } from "../store/useStore"

export default function MercatoPage(props) {

    const urls = getUrl()
    const [advertisementList, setAdvertisementList] = useState([])
    const [advertisement, setAdvertisement] = useState('')

    useEffect(() => {
        fetchAdvertisements({ limit: 50 })
        .then(advertisements => {
            setAdvertisementList(advertisements)
        })
        .catch(err => { console.log(err) })
    }, [])

    return (
        <>
            <div className="mercato-hero">
                <Row>
                    <Column>
                        <div className="mercato-hero-title">
                            <h1>Mercato</h1>
                            <p>Mise en relation de joueurs</p>
                        </div>
                    </Column>
                </Row>
            </div>
            <div className="mercato-filters">
                <Row>
                    <Column>
                        <MercatoFilters />
                    </Column>
                </Row>
            </div>
            <div className="mercato-listing">
                <Row>
                    <Column large="6">
                        <div className="box">
                            <Uxtableheader>
                                <div className="table-logo">

                                </div>
                                <div className="table-who">
                                    Qui
                                </div>
                                <div className="table-rating">
                                    Date
                                </div>
                                <div className="table-view">
                                </div>
                            </Uxtableheader>
                            {
                                advertisementList.map( a => {
                                    return <Uxtablerow key={ a.id }>
                                        <div className="table-logo">
                                        {
                                            a.type === 'user' &&
                                            <img src={ urls.avatar + '28x28/' + a.icon +'.png' } alt={ a.name } />
                                        }
                                        </div>
                                        <div className="table-who">
                                        {
                                            a.type === 'user' &&
                                            <NavLink to={ "/player/" +  a.permalink } title={ '@' + a.permalink} >
                                                { a.name }
                                            </NavLink>
                                        }
                                        </div>
                                        <div className="table-rating">
                                            { localeDate(a.date) }
                                        </div>
                                        <div className="table-view">
                                            <svg viewBox="0 0 469.333 469.333"  onClick={ e => { setAdvertisement(a) }}>
                                                <path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/>
                                                <path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160
                                                    c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333
                                                    c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667
                                                    S293.547,341.333,234.667,341.333z"/>
                                            </svg>
                                        </div>
                                    </Uxtablerow>
                                })
                            }
                        </div>
                    </Column>
                    <Column large="6">
                        <div className="box advertisement-content">
                            { markdown(advertisement.content) }
                        </div>
                    </Column>
                </Row>
            </div>
        </>
    )
}