import React, { useState, useCallback } from 'react'

export default function Checkbox({
    checked: __checked,
    onChange: __onChange,
    children: __children
}) {

    const [checked, setChecked] = useState(__checked || false)

    const toggle = useCallback(() => {
        let value = !checked
        setChecked(value)

        if(typeof __onChange === 'function') {
            __onChange(value)
        }
    }, [__onChange, checked])

    return (
        <div className={"x-checkbox" + (checked ? ' x-checkbox-checked' : '') } onClick={ toggle }>
            { __children }
        </div>
    )
}