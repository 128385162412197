import React from 'react'

export default function Vyralllogo() {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1141 1140">
            <path d="M823,154H935C787.018,443.276,764.4,489.3,577,854,261.632,235.356,242.294,196.813,221,155H332c34.267,66.785,34,66.415,72,141h28c90.971,177.488,145.7,283.845,146,284,65.291-128.729,57.572-114.263,145-285h28C797.843,204.611,754.724,288.208,823,154ZM107,302H247c219.49,429.308,60.325,118.466,288,563-54.049,104.105-4.584,9.229-71,137-54.854-108.843-33.573-65.044-118-232h34c-40.5-81.328-81.917-165.339-181-362H161Zm799,0h140c-37.6,73.412-3.47,7.188-54,106H954C823.9,666.809,820.943,672.779,772,770h35c-99.256,194.252-22.21,42.981-118,231-68.7-136.542-3.4-6.148-70-138C720.566,666.016,722.237,662.339,906,302Z"/>
            <rect width="190" height="100" x="-40" y="155" transform="skewX(27)">
                <animate attributeName="width"
                    from="0"
                    to="190"
                    begin="1000ms"
                    dur="350ms"
                    repeatCount="1"
                />
                <animate attributeName="x"
                    from="150"
                    to="-40"
                    begin="1000ms"
                    dur="350ms"
                    repeatCount="1"
                />
                <animate
                    attributeName="opacity"
                    values="0;1"
                    dur="125ms"
                    repeatCount="1"
                    begin="1000ms"
                    fill="freeze"
                    calcMode="linear"
                />
            </rect>
            <rect width="190" height="100" x="1000" y="154" transform="skewX(-27)">
                <animate attributeName="width"
                    from="0"
                    to="190"
                    begin="1000ms"
                    dur="350ms"
                    repeatCount="1"
                />
                <animate
                    attributeName="opacity"
                    values="0;1"
                    dur="125ms"
                    repeatCount="1"
                    begin="1000ms"
                    fill="freeze"
                    calcMode="linear"
                />
            </rect>
            <rect width="29" height="142" x="252" y="155" transform="skewX(27)">
                <animateTransform
                    attributeName="transform"
                    additive="sum"
                    type="translate"
                    values="0 0; 10 -8"
                    begin="1250ms"
                    dur="125ms"
                    repeatCount="1"
                    fill="freeze"
                />
            </rect>
            <rect width="28" height="142" x="874" y="154" transform="skewX(-27)">
                <animateTransform
                    attributeName="transform"
                    additive="sum"
                    type="translate"
                    values="0 0; -10 -8"
                    begin="1250ms"
                    dur="125ms"
                    repeatCount="1"
                    fill="freeze"
                />
            </rect>
            <path d="M964,422h37c-97.8,191.056-17.333,33.077-170,334-33.467-.2-2.6-0.267-35,0C939.158,472.141,910.763,529.67,964,422Z">
                <animateTransform attributeName="transform"
                    type="translate"
                    from="80 0"
                    to="0 0"
                    begin="1000ms"
                    dur="250ms"
                    fill="freeze"
                    repeatCount="1"
                />
                <animate
                    attributeName="opacity"
                    values="0;1"
                    dur="250ms"
                    repeatCount="1"
                    begin="1000ms"
                    fill="freeze"
                    calcMode="linear"
                />
            </path>
            <path d="M152,422h38c113.968,227.056,11.987,20.782,167,334H322Z">
                <animateTransform attributeName="transform"
                    type="translate"
                    from="-80 0"
                    to="0 0"
                    begin="1000ms"
                    dur="250ms"
                    repeatCount="1"
                    fill="freeze"
                />
                <animate
                    attributeName="opacity"
                    values="0;1"
                    dur="250ms"
                    repeatCount="1"
                    begin="1000ms"
                    fill="freeze"
                    calcMode="linear"
                />
            </path>
            <path d="M490,299H666c-50.4,99.067-18.933,36.7-88,173C544.2,403.455,515.068,347.51,490,299Z">
                <animateTransform attributeName="transform"
                    type="translate"
                    from="0 110"
                    to="0 0"
                    begin="1125ms"
                    dur="250ms"
                    fill="freeze"
                    repeatCount="1"
                />
                <animate
                    attributeName="opacity"
                    values="0;1"
                    dur="250ms"
                    repeatCount="1"
                    begin="1125ms"
                    fill="freeze"
                    calcMode="linear"/>
            </path>
        </svg>
    )
}
