import React from 'react'
import './profile.scss'
import { experienceToLevel } from '../../../library/Core'

const RADIUS = 100;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
function percentage(value) {
    let progress = value / 100
    let dash = CIRCUMFERENCE * (1 - progress)
    return dash
}

export default function Profile(props) {

    const { level, percentage:perc } = experienceToLevel(props.experience)

    return (
        <svg viewBox="0 0 250 250" className="profile">
            <defs>
                <clipPath id="myMask">
                    <circle cy="125" cx="125" r="94" />
                </clipPath>
            </defs>
            <g className="profile-avatar">
                <image href={ props.avatar } x="31" y="31" clipPath="url(#myMask)" />
            </g>
            {
                typeof props.experience !== "undefined" ?
                <>
                <g className="profile-progress-wrapper">
                    <circle cy="125" cx="125" r="100" className="profile-circle-background" />
                    <circle cy="125" cx="125" r="100" className="profile-circle-progress" strokeDasharray={ CIRCUMFERENCE } strokeDashoffset={ percentage(perc) } />
                </g>
                <g className="profile-level">
                    <path d="M 125,5 L 209.85282,40.147189 L 245,125 L 209.85281,209.85282 L 125,245 L 40.147184,209.85281 L 5,125 L 40.147189,40.147184 L 125,5 z" />
                    <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">{ level }</text>
                </g>
                </>
                : ""
            }
        </svg>
    )
}