export default class Storage {

    constructor(key) {
        this._key = key || '__default__'
    }

    setData(key, value) {

        let obj = {}
        if(typeof key === 'object') {
            obj = key
        }
        else {
            obj[key] = value
        }

        let current = this.getData()
        let data = JSON.stringify({ ...current, ...obj })

        window.localStorage.setItem(this._key, data)
    }

    getData() {
        return JSON.parse(window.localStorage.getItem(this._key))
    }

}