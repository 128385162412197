import React  from 'react'
import './grid.scss'

export class Column extends React.Component {

    render() {

        let classes = ""

        if(this.props.small) {
            classes += " column-small-" + this.props.small
        }

        if(this.props.medium) {
            classes += " column-medium-" + this.props.medium
        }

        if(this.props.large) {
            classes += " column-large-" + this.props.large
        }

        if(this.props.xlarge) {
            classes += " column-xlarge-" + this.props.xlarge
        }

        if(this.props.xxlarge) {
            classes += " column-xxlarge-" + this.props.xxlarge
        }

        if(!this.props.small && !this.props.medium && !this.props.small && !this.props.large && !this.props.xlarge && !this.props.xxlarge) {
            classes = " column-12"
        }

        if(this.props.smalloffset) {
            classes += " column-small-offset-" + this.props.smalloffset
        }

        if(this.props.mediumoffset) {
            classes += " column-medium-offset-" + this.props.mediumoffset
        }

        if(this.props.largeoffset) {
            classes += " column-large-offset-" + this.props.largeoffset
        }

        if(this.props.xlargeoffset) {
            classes += " column-xlarge-offset-" + this.props.xlargeoffset
        }

        if(this.props.xxlargeoffset) {
            classes += " column-xxlarge-offset-" + this.props.xxlargeoffset
        }

        if(this.props.className) {
            classes += " " + this.props.className
        }

        return (
            <div className={ classes.substr(1) }>
                { this.props.children }
            </div>
        )
    }
}

export class Row extends React.Component {

    render() {
        return (
            <div className={"row" + (this.props.className ? ' ' + this.props.className : '') }>
                { this.props.children }
            </div>
        )
    }
}