export const ROLES = {
    sentinel: "sentinelle",
    controller: "controleur",
    duelist: "duelliste",
    initer: "initiateur",
}

export const HEROS = {
    breach: { role: ["initer"], name: "breach" },
    brimstone: { role: ["controller"], name: "brimstone" },
    cypher: { role: ["sentinel"], name: "cypher" },
    jett: { role: ["duelist"], name: "jett" },
    omen: { role: ["controller"], name: "omen" },
    phoenix: { role: ["duelist"], name: "phoenix" },
    razen: { role: ["duelist"], name: "razen" },
    reyna: { role: ["duelist"], name: "reyna" },
    sage: { role: ["sentinel"], name: "sage" },
    sova: { role: ["initer"], name: "sova" },
    viper: { role: ["controller"], name: "viper" }
}